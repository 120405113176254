import Modal from "../../Modal";
import CompensationCard from "./CompensationCard";

const CompensationModal = (props) => {
    return (
        <>
            <Modal setShowModal={props.setShowModal}>
                <CompensationCard price={props.price} setPrice={props.setPrice} setShowModal={props.setShowModal} currency={props.currency}/>
            </Modal>
        </>
    );
}
export default CompensationModal;