import { useLayoutEffect, useRef, useState } from "react";
import ImageCarousel from "../ImageCarousel";
import TWButton from "../TWButton";

const PostContainer = (props) => {
    const [captionOverflow, setCaptionOverflow] = useState(false);
    const [fullCaption, setFullCaption] = useState(false);

    const captionRef = useRef(null);

    useLayoutEffect(() => {
        if (captionRef.current && !fullCaption) {
            setCaptionOverflow(captionRef.current.offsetHeight < captionRef.current.scrollHeight);
        }
    });

    return (
        <>
            <div className="flex h-full flex-col rounded-xl shadow-lg p-4 bg-white">
                <div className="inline-flex items-center">
                     {/* Profile Pic and Name
                    <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 -mr-3  ml-auto dark:hover:bg-gray-600 dark:hover:text-white " data-modal-hide="defaultModal" onClick={() => props.setShowModal(false)} >
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button> */}
                </div>
                <div className="rounded-full w-full">
                    <ImageCarousel carouselData={props.data.post_images} />
                </div>
                <div className="flex flex-row justify-start gap-3 mb-2 md:gap-17">
                    <span className="md:flex-none flex flex-row justify-end items-center font-semibold text-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 mr-2" viewBox="0 0 20 20" fill="#7ea7d7">
                            <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                        </svg>
                        {props.data.comment_count}
                    </span>
                    <span className="md:flex-none flex flex-row justify-end items-center font-semibold text-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 mr-2" viewBox="0 0 20 20" fill="#dc8e7d">
                            <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                        </svg>
                        {props.data.like_count}
                    </span>

                </div>

                <span ref={captionRef} className={`text-gray-700 pt-2 ${!fullCaption ? "line-clamp-2" : ""}`}>
                    {props.data.post_caption}
                </span>
                {captionOverflow &&
                    <div className="flex flex-row justify-end">
                        <TWButton secondary onClick={() => setFullCaption(!fullCaption)}>{fullCaption ? "Hide" : "Read more..."}</TWButton>
                    </div>
                }
            </div>
        </>
    );
}
export default PostContainer;