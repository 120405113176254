import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import { useParams } from "react-router-dom";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import TWButton from "../TWButton";
import TWSearch from "../TWSearch";
import ExploreCategories from "./ExploreCategories";
import CollapsibleSidebar from "../CollapsibleSidebar";
import ProfileCard from "./ProfileCard";
import ProfileModal from "./ProfileModal";
import Cookies from "js-cookie"
import CartModal from "./Cart";
import Spinner from "../Spinner";
import TWTextField from "../TWTextField";
import { Ping } from "../Pings";
import TopBar from "../TopBar";
import { useMediaQuery } from "react-responsive";
import whiteLogoGif from "../../assets/images/whiteLogo.svg";
import TWDropDown from "../TWDropDown"

const Explore = (props) => {
    // state that controls which profile is shown in the profile modal
    const { query } = useParams();
    const [hasExploreData, setHasExploreData] = useState(false);
    const [loading, setLoading] = useState(true);

    // modal states
    const [createModal, setCreateModal] = useState(false);
    // state that contains the list of all influencers to be shown on the page
    const [resultList, setResultList] = useState([]);
    // state that contains the list of influencers AFTER filtering
    const [displayList, setDisplayList] = useState([]);
    const [radius, setRadius] = useState(0);
    const [minFollowers, setMinFollowers] = useState(0);
    const [categoryList, setCategoryList] = useState([]); // what categories are currently checked
    const [cartInfluList, setCartInfluList] = useState([]);
    const [campaignList, setCampaignList] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [modalUid, setModalUid] = useState(null);
    const [isPremium, setIsPremium] = useState(null);
    const [paid, setPaid] = useState(null);
    const [savedLists, setSavedLists] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showCart, setShowCart] = useState(false);

    const csrf_token = Cookies.get("csrftoken");

    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
    const [tagList, setTagList] = useState([]);
    const initialButtonState = {};
    const [isButtonClicked, setIsButtonClicked] = useState(initialButtonState);

    const handleTagChange = (e, index) => {
        const list = [...tagList];
        list[index] = e;
        setTagList(list);
    };

    const handleTagRemove = (index) => {
        const list = [...tagList];
        list.splice(index, 1);
        setTagList(list);
        const updateIsButtonClicked = { ...isButtonClicked };
        updateIsButtonClicked[tagList[index]] = false;
        setIsButtonClicked(updateIsButtonClicked);
    };

    const handleTagAdd = (tag) => {
        if (tag.trim().startsWith('#')) {
            if (!tagList.includes(tag)) {
                setTagList([...tagList, tag]);
                const updateIsButtonClicked = { ...isButtonClicked };
                updateIsButtonClicked[tag] = true;
                setIsButtonClicked(updateIsButtonClicked);
            }
        } else {
            // console.error('Invalid tag format. Tags must start with #.');
        }
    };

    const handleTagButton = (tag) => {
        setIsButtonClicked((prevStates) => {
            const isButtonClicked = !prevStates[tag];
            const newButtonStates = { ...prevStates, [tag]: isButtonClicked };
            handleTagAdd(tag);
            if (!isButtonClicked) {
                handleTagRemove(tagList.indexOf(tag));
            }
            return newButtonStates;
        });
    };

    // Change displayList every follower, location, or category change
    useEffect(() => {
        let filtered = [];

        if (categoryList.length === 0) {
            filtered = resultList;
        } else {
            filtered = resultList.filter(elem => categoryList.includes(elem.category));
        }
        filtered = filtered.filter(profile => profile.follower_count >= (minFollowers * 1000));
        setDisplayList(filtered);
    }, [radius, minFollowers, categoryList]);

    const searchInfluencer = (query) => {
        const search = async () => {
            const token = await auth.currentUser.getIdToken();
            const url = `${process.env.REACT_APP_DJANGO_API}/search_influencer/${query}/${hasExploreData}`;
            const requestMetaData = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrf_token,
                    "Authorization": `Bearer ${token}`,
                },
            };
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        const data = result.data;
                        setResultList(data.explore_data);
                        setDisplayList(data.explore_data);
                        setLoading(false);

                        if (!hasExploreData) {
                            setCartInfluList(data.cart_data);
                            setCampaignList(data.campaign_data);
                            setAddressList(data.address_data);
                            setHasExploreData(true);
                        }
                    }
                });
        }
        search();
    }

    const getSavedList = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_saved_lists`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert,
                        footer: "Please try again later.",
                    });
                } else {
                    const data = result.data;
                    setSavedLists(data);
                }
            });
    }

    const getListInfluencers = async (listID) => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_list_influencers/${listID}`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert,
                        footer: "Please try again later.",
                    });
                } else {
                    const data = result.data;
                    setDisplayList(data);
                }
            });
    }

    const handleSavedListsUpdate = (newSavedLists) => {
        setSavedLists(newSavedLists);
    };

    const onChangeLocation = e => {
        setRadius(parseInt(e.target.value, 10));
    }

    const onChangeFollower = e => {
        setMinFollowers(parseFloat(e.target.value));
    }

    const onChangeCategory = e => {
        const checkedCategoryId = e.target.value;
        if (e.target.checked === true) {
            setCategoryList(categoryList.concat(checkedCategoryId));
            getListInfluencers(checkedCategoryId);
        } else {
            setCategoryList(categoryList.filter(elem => elem !== checkedCategoryId));
        }
    }

    const onChangeSavedList = e => {
        const checkedListId = e.target.value;
        const isChecked = e.target.checked;

        if (isChecked) {
            setCategoryList(prevList => [...prevList, checkedListId]);
            getListInfluencers(checkedListId);
        } else {
            setCategoryList(prevList => prevList.filter(id => id !== checkedListId));
        }
    };

    const [dropdown1Open, setDropdown1Open] = useState(false);
    const [dropdown2Open, setDropdown2Open] = useState(false);

    const handleDropdown1Toggle = () => {
        setDropdown1Open(!dropdown1Open);
    };

    const handleDropdown2Toggle = () => {
        setDropdown2Open(!dropdown2Open);
    };

    useEffect(() => {
        getSavedList();
    }, []);

    const categories =
        [
            { value: "Artist", label: "Artist" },
            { value: "Blogger", label: "Blogger" },
            { value: "Celebrity", label: "Celebrity" },
            { value: "Cultural", label: "Cultural" },
            { value: "Fashion", label: "Fashion" },
            { value: "Fitness", label: "Fitness" },
            { value: "Food", label: "Food" },
            { value: "Gamer", label: "Gamer" },
            { value: "Health", label: "Health" },
            { value: "Lifestyle", label: "Lifestyle" },
            { value: "Luxury", label: "Luxury" },
            { value: "Beauty", label: "Beauty" },
            { value: "Parenting", label: "Parenting" },
            { value: "Pets", label: "Pets" },
            { value: "SkinCare", label: "SkinCare" },
            { value: "Sustainability", label: "Sustainability" },
            { value: "Travel", label: "Travel" },
            { value: "Others", label: "Others" },
        ];

    const getExploreData = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_explore_data`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    const data = result.data;
                    setResultList(data.explore_data);
                    setDisplayList(data.explore_data);
                    setCartInfluList(data.cart_data);
                    setCampaignList(data.campaign_data);
                    setAddressList(data.address_data);
                    setIsPremium(data.is_premium);
                    setPaid(data.paid);
                    setHasExploreData(true);
                    setLoading(false);
                }

            });
    }

    const zipfilter = async (data) => {
        setLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/filter_by_zip`;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                setLoading(false);
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    const data = result.data;
                    setResultList(data);
                    setDisplayList(data);
                }
            });
    }

    useEffect(() => {
        if (query) {
            searchInfluencer(query);
        } else {
            getExploreData();
        }
    }, [query]);

    const openCart = () => {
        ReactGA.send({
            hitType: "view_cart",
            value: 0,
            items: cartInfluList.map((cartInflu, index) => {
                return {
                    item_id: cartInflu.influencer_id,
                    item_name: cartInflu.name,
                }
            })
        })
        setShowCart(true);
    }

    return (
        <>
            <div className="flex flex-row mr-2">
                <CollapsibleSidebar>
                    <div className='sm:h-full h-screen sm:overflow-hidden overflow-y-auto'>
                        <div className="flex flex-col gap-3 p-3 h-full">
                            <h2 className="text-lg font-bold text-black mt-3 mb-3 font-poppins">Saved Lists</h2>
                            <div className="flex w-full">
                                <button className="w-full">
                                    <TWDropDown actionBlue whiteDropdownArrow whitePlaceholderText isOpen={dropdown1Open} checkbox onToggle={handleDropdown1Toggle} checkboxOnChange={onChangeSavedList} data={savedLists.map(list => ({ value: list.list_id, label: list.list_title }))}>
                                        Select Saved List
                                    </TWDropDown>
                                </button>
                            </div>

                            <h2 className="text-lg font-bold text-black mt-6 mb-3 font-poppins">Search</h2>
                            <TWSearch url="explore" curr={query}>Search</TWSearch>

                            <p className="text-lg text-mi-action-blue font-extrabold font-poppins mt-6">Location</p>

                            <ul className="block" id="location">
                                <li key="item1">
                                    <h3 className="font-semibold text-gray-600 text-base font-poppins mt-3">Zip code</h3>
                                    <div className="flex flex-row text-sky-100 gap-2 items-center mt-2">
                                        <TWTextField placeholder="Enter a zipcode" onEnter={zipfilter} />
                                    </div>
                                </li>


                                <h3 className="font-semibold text-gray-600 text-base font-poppins mt-5 mb-12">Radius</h3>
                                <li key="item2" className="ml-2">
                                    
                                    <div className=" relative flex flex-row items-center">
                                        <input type="range" min="0" max="100" step="1" value={radius} onChange={onChangeLocation} className="accent-mi-blue hover:cursor-pointer w-11/12" />

                                        <svg xmlns="http://www.w3.org/2000/svg" width="65" height="43" viewBox="0 0 65 43" fill="none" className="absolute" style={{ top: '-40px', left: `calc(${(radius / 100) * 86}% - 25px)` }}>
                                            <g filter="url(#filter0_d_656_10623)">
                                                <path d="M8 16.1515C8 10.545 12.545 6 18.1515 6H46.8485C52.455 6 57 10.545 57 16.1515V17.2406C57 22.8471 52.455 27.3921 46.8485 27.3921H35.5L33 32.5L29.5 27.3921H18.1515C12.545 27.3921 8 22.8471 8 17.2406V16.1515Z" fill="white" />
                                            </g>
                                            <text x="10" y="20" fontSize="11" fontFamily="NotoSans" fill="#6b7280" className="flex items-center"> {radius} Miles </text>
                                            <defs>
                                                <filter id="filter0_d_656_10623" x="0" y="0" width="65" height="42.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="2" />
                                                    <feGaussianBlur stdDeviation="4" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_656_10623" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_656_10623" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div className="flex w-11/12 justify-between">
                                        <span className="text-gray-600 text-sm ml-1 mt-1 mb-2">0 miles</span>
                                        <span className="text-gray-600 text-sm mt-1">100 miles</span>
                                    </div>
                                </li>

                                <h3 className="font-semibold text-gray-600 text-base font-poppins mt-2 mb-12">Follower Count</h3>
                                <li key="item3" className="ml-2">
                                    
                                    <div className="flex-auto relative flex flex-row gap-2 items-center">
                                        <input id="steps-ranges" type="range" min="0" max="5" step=".1" value={minFollowers} onChange={onChangeFollower} className="accent-mi-blue hover:cursor-pointer w-11/12" />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="65" height="43" viewBox="0 0 65 43" fill="none" className="absolute" text-anchor="middle" dominant-baseline="middle" style={{ top: '-40px', left: `calc(${(minFollowers * 20 / 100) * 86}% - 25px)` }}>
                                            <g filter="url(#filter0_d_656_10623)">
                                                <path d="M8 16.1515C8 10.545 12.545 6 18.1515 6H46.8485C52.455 6 57 10.545 57 16.1515V17.2406C57 22.8471 52.455 27.3921 46.8485 27.3921H35.5L33 32.5L29.5 27.3921H18.1515C12.545 27.3921 8 22.8471 8 17.2406V16.1515Z" fill="white" />
                                            </g>
                                            <text x="32.5" y="18.5" fontSize="12" fontFamily="NotoSans" fill="#6b7280"> {minFollowers}k </text>
                                            <defs>
                                                <filter id="filter0_d_656_10623" x="0" y="0" width="65" height="42.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="2" />
                                                    <feGaussianBlur stdDeviation="4" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_656_10623" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_656_10623" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div className="flex w-11/12 justify-between">
                                        <span className="text-gray-600 text-sm ml-1 mt-1 mb-2">0</span>
                                        <span className="text-gray-600 text-sm mt-1 mb-2">5k</span>
                                    </div>

                                </li>
                            </ul>

                            {/* <h2 className="text-xl font-semibold text-mi-blue NotoSans mt-3">Saved Lists</h2>
                        <div className="flex-auto ml-1 w-full">
                            <button className="w-full">
                                <TWDropDown checkbox checkboxOnChange={onChangeSavedList} data={savedLists.map(list => ({ value: list.list_id, label: list.list_title }))}>
                                    Select Saved List
                                </TWDropDown>
                            </button>
                        </div> */}

                            <h2 className="text-lg font-semibold text-mi-blue mt-5 font-poppins">Categories</h2>
                            <div className="flex ml-1 w-full">
                                <button className="w-full mb-24">
                                    <TWDropDown isOpen={dropdown2Open} checkbox onToggle={handleDropdown2Toggle} checkboxOnChange={onChangeCategory} data={categories}>Select Category</TWDropDown>
                                </button>
                            </div>

                            {/* <h2 className="text-xl font-semibold text-mi-blue NotoSans mt-5">Tags</h2>
                        <div className="flex flex-row text-gray-950 gap-2 items-center mt-2">
                            <TWTextField placeholder="Try #fashion or #food" onEnter={handleTagAdd} />
                        </div>
                        <div className="flex flex-wrap gap-1">
                            {tagList.map((singleTag, index) => (
                                <div key={index}>
                                    {(
                                        <div>
                                            <button onClick={() => handleTagRemove(index)} className="inline-flex bg-mi-action-blue justify-center hover:bg-blue-700 text-white font-bold py-2 w-20 h-8 rounded-full text-xs">
                                                <div className="justify-evenly px-1.5 text-[10.29px] font-thin">
                                                    <div>{singleTag} x</div>
                                                </div>
                                            </button>

                                        </div>
                                    )}
                                </div>
                            ))}
                        </div> */}


                            {/* <h2 className="text-sm font-semibold text-black NotoSans mt-5">Popular Tags</h2>
                        <div className="flex flex-wrap gap-2 gap-y-3 mb-36">

                            <button onClick={() => handleTagButton("#artist")} className={`py-2 px-2.5 rounded-full text-xs inline-flex items-center ${isButtonClicked["#artist"] ? 'bg-mi-action-blue hover:bg-mi-blue' : 'bg-gray-300 hover:bg-gray-400'} text-black font-light`}>
                                #artist
                            </button>

                            <button onClick={() => handleTagButton("#blogger")} className={`py-2 px-2.5 rounded-full text-xs inline-flex items-center ${isButtonClicked["#blogger"] ? 'bg-mi-action-blue hover:bg-mi-blue' : 'bg-gray-300 hover:bg-gray-400'} text-black font-light`}>
                                #blogger
                            </button>
                            <button onClick={() => handleTagButton("#celebrity")} className={`py-2 px-2.5 rounded-full text-xs inline-flex items-center ${isButtonClicked["#celebrity"] ? 'bg-mi-action-blue hover:bg-mi-blue' : 'bg-gray-300 hover:bg-gray-400'} text-black font-light`}>
                                #celebrity
                            </button>
                            <button onClick={() => handleTagButton("#cultural")} className={`py-2 px-2.5 rounded-full text-xs inline-flex items-center ${isButtonClicked["#cultural"] ? 'bg-mi-action-blue hover:bg-mi-blue' : 'bg-gray-300 hover:bg-gray-400'} text-black font-light`}>
                                #cultural
                            </button>
                            <button onClick={() => handleTagButton("#fitness")} className={`py-2 px-2.5 rounded-full text-xs inline-flex items-center ${isButtonClicked["#fitness"] ? 'bg-mi-action-blue hover:bg-mi-blue' : 'bg-gray-300 hover:bg-gray-400'} text-black font-light`}>
                                #fitness
                            </button>
                            <button onClick={() => handleTagButton("#food")} className={`py-2 px-2.5 rounded-full text-xs inline-flex items-center ${isButtonClicked["#food"] ? 'bg-mi-action-blue hover:bg-mi-blue' : 'bg-gray-300 hover:bg-gray-400'} text-black font-light`}>
                                #food
                            </button>
                        </div> */}
                        </div>
                    </div>
                </CollapsibleSidebar>

                <div className="flex flex-col w-full">
                    {!isSmallScreen && <TopBar accountType={props.accountType} />}

                    <div className="flex-auto flex flex-col p-5">
                        <div className="flex relative justify-between font-bold">
                            <h1 className="ml-6 font-poppins">Explore</h1>
                            {(cartInfluList.length === 0) ? null :
                                <Ping className="bg-red-500 text-white absolute -right-8 mt-1 mr-8 z-10">{cartInfluList.length}</Ping>}
                            <div className="p-2 Dosis">
                                <TWButton onClick={() => openCart()}>View Queued Proposals</TWButton>
                            </div>
                        </div>

                        <h1 className="font-semibold text-sm text-gray-600 mt-3 mb-5 ml-6">Showing {displayList.length} results:</h1>
                        {/* MAIN CONTENT PROFILE CARDS */}
                        <div className="items-center flex flex-row flex-wrap">
                            {loading
                                ? <div className="w-full h-full flex flex-col justify-center items-center"><Spinner /></div>
                                : displayList.map((profile, index) => {
                                    return <ProfileCard key={index} data={profile} setShowModal={setShowModal} setModalUid={setModalUid} />
                                })}
                        </div>
                        {/* Open Cart Button */}

                    </div>

                    {/* FULL USER PROFILE */}
                    {showModal ? <ProfileModal campaignList={campaignList} setShowModal={setShowModal} uid={modalUid} setCartInfluList={setCartInfluList} savedList={savedLists} handleSavedListsUpdate={handleSavedListsUpdate} /> : <></>}
                    {/* Cart Modal */}
                    {showCart ? <CartModal addressList={addressList} campaignList={campaignList} data={cartInfluList} setCartInfluList={setCartInfluList} setShowModal={setShowCart} isPremium={isPremium} paid={paid} /> : <></>}

                </div>
            </div>
        </>
    );

};
export default Explore;