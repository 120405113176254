//import ProfilePic from "../ProfilePic";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Spinner from "../Spinner";
import TimelineListing from "../campaign/TimelineListing";
//import SettingsIcon from '@mui/icons-material/Settings';

import { useEffect, useState } from "react";
//import people from "../../assets/images/people.png";
import EmptyStatePicture from "../../assets/images/empty_state_image.svg";

const HomeView = () => {

    const csrf_token = Cookies.get("csrftoken");
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState("Current");


    const [currentcontractList, setCurrentContractList] = useState([]);
    const [pendingcontractList, setPendingContractList] = useState([]);


    const getCurrentContracts = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_current_contracts_tracking`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate current list
                    setCurrentContractList(result.data);
                    setLoading(false);
                }
            });
    }

    const getPendingContracts = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_pending_contracts_tracking`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate current list
                    setPendingContractList(result.data);
                    setLoading(false);
                }
            });
    }



    useEffect(() => {
        getCurrentContracts();
        getPendingContracts();
    }, []);


    return (
        <>
            {loading
                ? <div className="flex justify-center items-center h-full"><Spinner /></div>
                :
                <div className="flex flex-col gap-2 w-full px-2 md:px-0">
                    <div className="flex flex-col rounded-xl gap-2">
                        <div>
                            <div className="flex space-x-4 mb-8 md:justify-normal justify-evenly"> {/* Added 'space-x-4' for spacing */}
                                {/* Button elements */}
                                <p
                                    className={(tab === "Current") ? "inline-block rounded-xl py-2 px-2 md:px-4 sm:text-[white] text-mi-blue underline md:no-underline text-center sm:bg-mi-blue cursor-pointer font-bold underline-offset-8 decoration-2" : "sm:bg-white inline-block py-2 px-2 md:px-4 text-mi-black sm:border rounded-xl text-center cursor-pointer font-semibold hover:bg-mi-blue hover:text-white"}
                                    onClick={() => setTab("Current")}
                                >
                                    Current
                                </p>
                                <p
                                    className={(tab === "Pending") ? "inline-block rounded-xl py-2 px-2 md:px-4 sm:text-[white] text-mi-blue underline md:no-underline text-center sm:bg-mi-blue cursor-pointer font-bold underline-offset-8 decoration-2" : "sm:bg-white inline-block py-2 px-2 md:px-4 text-mi-black sm:border rounded-xl text-center cursor-pointer font-semibold hover:bg-mi-blue hover:text-white"}
                                    onClick={() => setTab("Pending")}
                                >
                                    Pending Proposals
                                </p>
                            </div>
                            {tab === "Current"
                                ? (!currentcontractList || currentcontractList.length === 0)
                                    ? <div className="w-11/12 h-full text-center  font-semibold rounded-xl flex flex-col shrink-0 justify-center items-center mx-auto">
                                        <img src={EmptyStatePicture} alt="You have no campaigns right now, you will be notified via email when you receive a proposal" className="w-auto" />
                                        <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">You have no campaigns right now</h1>
                                        <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">You will be notified via email when you receive a proposal.</h1>
                                    </div>
                                    : <TimelineListing data={currentcontractList} />
                                : <></>
                            }
                            {tab === "Pending"
                                ? (!pendingcontractList || pendingcontractList.length === 0)
                                    ? <div className="w-11/12 h-full text-center  font-semibold rounded-xl flex flex-col shrink-0 justify-center items-center mx-auto">
                                        <img src={EmptyStatePicture} alt="You have no proposals right now, you will be notified via email when you receive a proposal" className="w-auto" />
                                        <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">You have no proposals right now</h1>
                                        <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">You will be notified via email when you receive a proposal.</h1>
                                    </div>
                                    : <TimelineListing data={pendingcontractList} />
                                : <></>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default HomeView;