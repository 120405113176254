import ProfilePic from "../ProfilePic";
import InstagramIcon from "@mui/icons-material/Instagram";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import FilterIcon from '@mui/icons-material/Filter';

const InfluencerProfileCard = ({ data }) => {
    return (
        <>
            <div className="flex flex-col divide-y-2 p-4 h-fit w-full bg-white rounded-xl">
                <div className="flex flex-col md:flex-row gap-4 pb-2 items-center">
                    <div className="w-1/4 md:w-[12%] max-w-[5rem]">
                        <ProfilePic border image={data.profile_picture} />
                    </div>
                    <a href={`/explore/${data.uid}`} className="font-semibold text-xl text-mi-green underline">{data.name}</a>
                    <div className="flex-auto flex flex-row justify-end items-center flex-wrap md:flex-nowrap gap-4 max-w-full">
                        <div className="flex flex-row gap-1 w-full justify-center md:justify-end">
                            <InstagramIcon className="text-mi-blue" />
                            <a href={`https://instagram.com/${data.ig_handle}`} className="font-semibold text-mi-green underline font-poppins">@{data.ig_handle}</a>
                        </div>
                        <span className="flex-auto md:flex-none flex flex-row gap-1 justify-end items-center font-semibold text-gray-600 text-lg">
                            <PeopleRoundedIcon className="text-mi-blue" />
                            {data.follower_count}
                        </span>
                        <span className="flex-auto md:flex-none flex flex-row gap-1 justify-start items-center font-semibold text-gray-600 text-lg">
                            <FilterIcon className="text-mi-blue" />
                            {data.posts_count}
                        </span>
                    </div>
                </div>
                <div className="flex flex-col gap-4 pt-2">
                    <div className="font-semibold text-gray-500 font-poppins">Bio: <p className="inline font-normal text-[16.20px] text-gray-600 font-poppins">{data.bio}</p></div>
                    <div className="font-semibold text-gray-500 font-poppins">Engagement Rate: <p className="inline font-normal text-[16.20px] text-gray-600 font-poppins">{data.engagement_rate}%</p></div>
                    <div className="font-semibold text-gray-500 font-poppins">Response Rate: <p className="inline font-normal text-[16.20px] text-gray-600 font-poppins">{data.response_rate}%</p></div>
                    <div className="font-semibold text-gray-500 font-poppins">Zip Code: <p className="inline font-normal text-[16.20px] text-gray-600 font-poppins">{data.zip_code}</p></div>
                </div>

            </div>
        </>
    );
}
export default InfluencerProfileCard;