import { useEffect, useState } from "react";
import CropPicture from "../CropPicture";
import TWButton from "../TWButton";
import Modal from "../Modal";
import { useMediaQuery } from "react-responsive";

const CropPicModal = ({ setSrc, setCropPicModal }) => {
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1050px)' });

    useEffect(() => {
        if (loading && image) {
            // Pass the image back to the parent component (ProfilePicSettings)
            setSrc(image);
            setLoading(false);
            setCropPicModal(false);
        }
    }, [loading, image, setCropPicModal, setSrc]);

    return (
        <Modal {...(isSmallScreen && { minimum: true })} setShowModal={setCropPicModal}>
            <div className="p-7">
                {/* header */}
                <div className="flex flex-row gap-2 items-center w-full pb-3 border-b border-solid border-slate-200">
                    <div className="flex-auto font-semibold text-lg text-gray-500">
                        Set Profile Image
                    </div>
                </div>

                {/* body */}
                <div className="flex flex-col p-4 gap-2 md:grid md:grid-cols-2 md:w-[50rem]">
                    <div className="mb-4 md:col-span-2 md:px-2">
                        <CropPicture setImage={setImage} />
                    </div>
                </div>

                {/* footer */}
                <div className="flex flex-row gap-2 items-center md:justify-end w-full pt-3 border-t border-solid border-slate-200">
                    <div className="flex-auto md:grow-0">
                        <TWButton md loading={loading} onClick={() => setLoading(true)}>
                            Update Profile Picture
                        </TWButton>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CropPicModal;
