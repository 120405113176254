import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Spinner from "../Spinner";

import { useState, useEffect } from "react";
import ContentPagePostContainer from "./ContentPagePostContainers";

const ContentPage = (props) => {
    const csrf_token = Cookies.get("csrftoken");

    const [contentData, setContentData] = useState("");
    const [postTab, setPostTab] = useState("posts");
    const [loading, setLoading] = useState("");

    const getContentData = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_associated_content/${props.data.id}`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate active list
                    setContentData(result.data);
                }
            });
        setLoading(false)
    }
    useEffect(() => {
        setLoading(true)
        getContentData();
    }, []);
    return (
        <>
            <div className="flex-auto flex flex-row md:flex-col gap-2 p-2 w-full md:overflow-auto">
                <div className="flex flex-col rounded-xl gap-2 p-2 w-full">
                    <hr></hr>
                    <div className="flex flex-row justify-end w-full gap-4">
                        <button className={`inline-block md:rounded-xl py-2 px-2 md:px-4 cursor-pointer font-semibold drop-shadow-xl ${postTab === "posts" ? "bg-transparent border-b-2 border-mi-blue text-mi-blue md:bg-mi-blue md:text-white md:border-none" : "text-mi-black md:bg-white hover:bg-mi-blue hover:text-white"}`} onClick={() => setPostTab("posts")}>
                            Posts
                        </button>
                        <button className={`inline-block md:rounded-xl py-2 px-2 md:px-4 cursor-pointer font-semibold drop-shadow-xl ${postTab === "videos" ? "bg-transparent border-b-2 border-mi-blue text-mi-blue md:bg-mi-blue md:text-white md:border-none" : "text-mi-black md:bg-white hover:bg-mi-blue hover:text-white"}`} onClick={() => setPostTab("videos")}>
                            Videos
                        </button>

                    </div>
                    <div className="mt-4">
                        {loading
                            ? <div className="w-full h-full flex flex-col items-center"><Spinner /></div>
                            : (contentData.length === 0)
                                ? <div className="w-full text-center p-4  mt-4 mx-auto font-semibold rounded-xl text-gray-500 Dosis bg-white">No content for the selected campaign yet!</div>
                                : <div className={`grid ${postTab === 'posts' ? "grid-cols-1 sm:grid-cols-3" : "grid-cols-1"} gap-4`}>
                                    {contentData.filter((post) => {
                                        if (postTab === 'posts') {
                                            return ['I', 'R', 'S'].includes(post.post_type[0]);
                                        } else if(postTab === 'videos') {
                                            return post.post_type[0] === 'V';
                                        }
                                        return false;
                                    }).map((post, index) => {
                                        return <ContentPagePostContainer key={index} data={post} postTab={postTab} />;
                                    })}
                                </div>

                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContentPage;