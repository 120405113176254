import { useEffect, useState, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { auth } from "../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import whiteLogoGif from "../assets/images/whiteLogo.svg";
import ProfilePic from "./ProfilePic";
import ContractNotificationListing from "./ContractNotificationListing";
import ChatNotificationListing from "./ChatNotificationListing";
import Menu from "../assets/images/menu.svg";
import chatIcon from "../assets/images/grey_chat_icon.svg";
import bellIcon from "../assets/images/bell_icon.svg";
import notificationClose from "../assets/images/notification_x_button.svg";
import chatBack from "../assets/images/chat_back_icon.svg";
import SidebarContext from "./SidebarContext";

import { Ping } from "./Pings";

const TopBar = (props) => {
    const [open, setOpen] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const { openSidebar } = useContext(SidebarContext);

    const [openChatNotifications, setOpenChatNotifications] = useState(false);
    const [showChatNotifications, setShowChatNotifications] = useState(false);

    const [openBellNotifications, setOpenBellNotifications] = useState(false);
    const [showBellNotifications, setShowBellNotifications] = useState(false);

    const [notifications, setNotifications] = useState([]);
    const [chatNotifications, setChatNotifications] = useState([]);

    const [pfp, setPFP] = useState(null);

    const [openNotifications, setOpenNotifications] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);

    const csrf_token = Cookies.get("csrftoken");

    // Toggle function for chat notifications
    const toggleChatNotifications = () => {
        if (openChatNotifications) {
            setOpenChatNotifications(false);
            setTimeout(() => setShowChatNotifications(false), 300);
        } else {
            setShowChatNotifications(true);
            setOpenChatNotifications(true);
            // Close bell notifications if chat notifications are being opened
            if (openBellNotifications) {
                setOpenBellNotifications(false);
                setTimeout(() => setShowBellNotifications(false), 300);
            }
        }
    };

    // Toggle function for bell notifications
    const toggleBellNotifications = () => {
        if (openBellNotifications) {
            setOpenBellNotifications(false);
            setTimeout(() => setShowBellNotifications(false), 300);
        } else {
            setShowBellNotifications(true);
            setOpenBellNotifications(true);
            // Close chat notifications if bell notifications are being opened
            if (openChatNotifications) {
                setOpenChatNotifications(false);
                setTimeout(() => setShowChatNotifications(false), 300);
            }
        }
    };


    const toggleCollapsed = () => {
        // open case => collapse div and disappear content after animation plays
        if (openNotifications) {
            setOpenNotifications(false);
            setTimeout(() => {
                setShowNotifications(false);
            }, 300);
        } else {
            // collapsed case => open div and show content
            setShowNotifications(true);
            setOpenNotifications(true);
        }
    }

    const setContractNotifications = (data) => {
        setNotifications(data.map((notif, index) => {
            return <ContractNotificationListing key={index} data={notif} setContractNotifications={setContractNotifications} />
        }));
    }

    const refreshChatNotifications = (data) => {
        setChatNotifications(data.map((notif, index) => {
            return <ChatNotificationListing key={index} data={notif} refreshChatNotifications={refreshChatNotifications} />
        }));
    }

    // function to get notifications on page load
    useEffect(() => {
        const getNotifications = async () => {
            const token = await auth.currentUser.getIdToken();
            const url = `${process.env.REACT_APP_DJANGO_API}/get_notifications`;
            const requestMetaData = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrf_token,
                    "Authorization": `Bearer ${token}`,
                },
            };
            fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        setPFP(result.data.profile_picture);

                        setNotifications(result.data.contract_notifications.map((notif, index) => {
                            return <ContractNotificationListing key={index} data={notif} setContractNotifications={setContractNotifications} />;
                        }));

                        setChatNotifications(result.data.chat_notifications.map((notif, index) => {
                            return <ChatNotificationListing key={index} data={notif} refreshChatNotifications={refreshChatNotifications} />;
                        }));

                    }
                });
        }
        getNotifications();
    }, []);

    const location = useLocation();
    const specialSidebarPages = ["/explore", "/contracts", "/chat", "/campaigns"];
    const isSpecialSidebarPage = specialSidebarPages.some(path => location.pathname.startsWith(path)) || (location.pathname === "/home" && (props.accountType === "Business" || props.accountType === "Agency"));
    const isLocationChat = (window.location.pathname.startsWith("/chat"));
    return (
        <>
           
                <nav className={`h-14 flex ${openSidebar ? "" : "ml-36"} items-center gap-4 p-8`}>
                    {!isSpecialSidebarPage && (
                        <a href="/home" className="pr-32">
                            <div className="w-[10rem] flex-shrink-0 text-white">
                                <img src={whiteLogoGif} alt="My Influency" className="object-scale-down pt-1" />
                            </div>
                        </a>
                    )}
                    <ul className="lg:flex lg:flex-row lg:w-full lg:items-center hidden">
                        <div className="flex flex-auto lg:gap-14 ">
                            {(props.accountType === "Business" || props.accountType === "Agency") &&
                                <a href="/explore" className={`text-base font-semibold ${window.location.pathname.startsWith("/explore") ? "text-mi-action-blue border-b-2 border-mi-action-blue" : "text-mi-black hover:text-mi-action-blue"} p-1 px-2 font-poppins`}>
                                    Explore
                                </a>
                            }
                            {(props.accountType === "Influencer") &&
                                <a href="/home" className={`text-base font-semibold ${window.location.pathname.startsWith("/home") ? "text-mi-action-blue border-b-2 border-mi-action-blue" : "text-mi-black hover:border-mi-action-blue"} p-1 px-2 font-poppins`}>
                                    Dashboard
                                </a>
                            }
                            <a href="/contracts" className={`text-base font-semibold ${window.location.pathname.startsWith("/contracts") ? "text-mi-action-blue border-b-2 border-mi-action-blue" : "text-mi-black hover:text-mi-action-blue"} p-1 px-2 font-poppins`}>
                                Contracts
                            </a>
                            <a href="/chat" className={`text-base font-semibold ${window.location.pathname.startsWith("/chat") ? "text-mi-action-blue border-b-2 border-mi-action-blue" : "text-mi-black hover:text-mi-action-blue"} p-1 px-2 font-poppins`}>
                                Messages
                            </a>
                            {(props.accountType === "Business" || props.accountType === "Agency") &&
                                <a href="/campaigns" className={`text-base font-semibold ${window.location.pathname.startsWith("/campaigns") ? "text-mi-action-blue border-b-2 border-mi-action-blue" : "text-mi-black hover:text-mi-action-blue"} p-1 px-2 font-poppins`}>
                                    Campaigns
                                </a>
                            }
                        </div>

                        <div className="w-6 h-6 shrink-0 mr-4 relative">
                            <img src={chatIcon} alt="Chat" className="w-full h-full" onClick={toggleChatNotifications} />
                            {chatNotifications.length > 0 &&
                                <Ping className="bg-red-500 text-white absolute z-10 -top-2 -right-4">{chatNotifications.length}</Ping>
                            }
                            <div className={`${!openChatNotifications ? "h-0" : "h-[40rem]"} absolute top-[120%] right-[-9rem] z-50 md:w-80 duration-300 overflow-y-auto md:scrollbar bg-white rounded-xl shadow-xl divide-y divide-solid`}>
                                <div className="p-5 flex flex-row gap-2">
                                    <div>
                                        <h2 className="font-semibold">Messages</h2>
                                    </div>
                                    <div className="w-full">
                                        <Ping className="bg-red-500 text-white">{chatNotifications.length}</Ping>
                                    </div>
                                    <div className="flex justify-end w-full items-center">
                                        <button onClick={() => setOpenChatNotifications(false)}>
                                            <img src={notificationClose} alt="Close Contract Notifications" className="object-scale-down pt-1" />
                                        </button>
                                    </div>
                                </div>
                                {(showChatNotifications) && chatNotifications}
                            </div>
                        </div>
                        <div className="w-6 h-6 shrink-0 mr-4 relative">
                            <img src={bellIcon} alt="Bell" className="w-full h-full" onClick={toggleBellNotifications} />
                            {notifications.length > 0 &&
                                <Ping className="bg-red-500 text-white absolute z-10 -top-2 -right-4">{notifications.length}</Ping>
                            }
                            <div className={`${!openBellNotifications ? "h-0" : "h-[40rem]"} absolute top-[120%] right-[-7rem] z-50 md:w-80 duration-300 overflow-hidden md:scrollbar bg-white rounded-2xl shadow-xl divide-y divide-solid`}>
                                <div className="p-5 flex flex-row gap-2">
                                    <div>
                                        <h2 className="font-semibold">Notifications</h2>
                                    </div>
                                    <div className="w-full">
                                        <Ping className="bg-red-500 text-white">{notifications.length}</Ping>
                                    </div>
                                    <div className="flex justify-end w-full items-center">
                                        <button onClick={() => setOpenBellNotifications(false)}>
                                            <img src={notificationClose} alt="Close Contract Notifications" className="object-scale-down pt-1" />
                                        </button>
                                    </div>
                                </div>
                                <div className="h-[calc(100%-70px)] scrollbar overflow-y-auto">
                                    {(showBellNotifications) && notifications}
                                </div>
                            </div>
                        </div>

                        <div className="bg-mi-grey h-6 w-0.5 mr-4 ml-2"></div>
                        <div className="relative w-12">

                            <div className="flex flex-row relative">
                                <ProfilePic clickable image={pfp} onClick={() => setOpenProfile(!openProfile)} />
                            </div>

                            <div className={`${openProfile ? "h-auto" : "h-0"} absolute top-[120%] right-0 z-50 md:w-72 duration-300 bg-white rounded-xl shadow-xl divide-y divide-solid`}>
                                {openProfile &&
                                    <>
                                        <a href="/profile" className="block font-semibold text-gray-800 px-4 py-2 w-full hover:bg-gray-200 rounded-t-xl">Profile Settings</a>
                                        <p onClick={() => auth.signOut()} className="block font-semibold text-gray-800 px-4 py-2 w-full hover:bg-gray-200">Logout</p>
                                    </>
                                }
                            </div>
                        </div>
                    </ul>


                    {/* Hamburger Menu */}
                    <div className="relative flex flex-row flex-auto justify-end lg:hidden ">

                        {notifications.length + chatNotifications.length > 0
                            ?
                            <Ping className="bg-red-500 text-white absolute z-10 -top-2 right-8">{notifications.length + chatNotifications.length}</Ping>
                            : <></>
                        }

                        {props.chatToggle && isLocationChat &&
                            <div className="flex w-full">
                                <button className="flex md:hidden justify-start items-center shrink-0" onClick={() => props.setChatToggle(false)}>
                                    {/* <SendRoundedIcon sx={{ fontSize: 30 }} className="text-blue-400" /> */}
                                    <img src={chatBack} alt="Additional chat options" className="w-auto sm:scale-125 fill-current text-blue-500" />
                                </button>
                            </div>
                        }
                        <button onClick={toggleCollapsed} className="flex shrink-0 items-center px-6 py-2">
                            <img src={bellIcon} alt="Bell" className="w-full h-full" onClick={toggleBellNotifications} />
                        </button>
                        <button onClick={() => setOpen(!open)} className="flex items-center shrink-0 py-2">
                            <img src={Menu} alt="Close" className="w-6 h-6" />
                        </button>
                        <div className={`${open ? "h-screen w-full" : "h-0"} fixed left-0 overflow-y-auto overscroll-contain top-0 z-50 duration-300 bg-white rounded-xl shadow-xl divide-solid`}>
                            {open &&
                                <>
                                    <div className="flex flex-col gap-7">
                                        <div className="flex flex-row w-full">
                                            <div>
                                                <img src={whiteLogoGif} alt="My Influency" className="object-scale-down pt-1" />
                                            </div>
                                            <div className="flex justify-end items-center w-full">
                                                <button onClick={() => setOpen(!open)} className="flex items-center px-3 py-2">
                                                    <img src={notificationClose} alt="Close" className="w-6 h-6" />
                                                </button>
                                            </div>
                                        </div>
                                        {(props.accountType === "Business" || props.accountType === "Agency") &&
                                            <>
                                                <a href="/explore" className={`block ml-5 text-2xl font-bold ${window.location.pathname.startsWith("/explore") ? "text-mi-blue" : "text-gray-800 "} px-4 py-2 hover:bg-gray-200`}>Explore</a>
                                                <a href="/campaigns" className={`block ml-5 text-2xl font-bold ${window.location.pathname.startsWith("/campaigns") ? "text-mi-blue" : "text-gray-800 "} px-4 py-2 hover:bg-gray-200`}>Campaigns</a>
                                            </>
                                        }
                                        {(props.accountType === "Influencer") &&
                                            <a href="/home" className={`block ml-5 text-2xl font-bold ${window.location.pathname.startsWith("/home") ? "text-mi-blue" : "text-gray-800 "} px-4 py-2 hover:bg-gray-200`}>Dashboard</a>
                                        }
                                        <a href="/contracts" className={`block ml-5 text-2xl font-bold ${window.location.pathname.startsWith("/contracts") ? "text-mi-blue" : "text-gray-800 "} px-4 py-2 hover:bg-gray-200`}>Contracts</a>
                                        <a href="/chat" className={`block ml-5 text-2xl font-bold ${window.location.pathname.startsWith("/chat") ? "text-mi-blue" : "text-gray-800 "} px-4 py-2 hover:bg-gray-200`}>Chat</a>


                                    </div>

                                    {/* <div className="flex flex-row items-center w-full pl-4 hover:bg-gray-200">
                                    <div className="h-6 w-6 rounded-full flex items-center justify-center text-white font-semibold bg-mi-green">
                                        {notifications.length + chatNotifications.length}
                                    </div>
                                    <div onClick={toggleCollapsed} className="flex-auto font-semibold text-right text-gray-800 px-4 py-2">
                                        Notifications
                                    </div>
                                </div> */}
                                    {/* <div className={`${!openNotifications ? "h-0" : "h-[25rem]"} overflow-y-auto md:scrollbar duration-300 flex flex-col w-full divide-y`}>
                                    {(showNotifications) &&
                                        <>
                                            {notifications}
                                            {chatNotifications}
                                        </>
                                    }
                                </div> */}
                                    <hr class="h-[2px] mt-20 w-11/12 mx-auto my-8 border-0 bg-gray-300 rounded-full"></hr>
                                    <div className="flex flex-col gap-y-3">
                                        <a href="/profile" className="block ml-5 text-base font-bold text-gray-800 px-4 py-2 hover:bg-gray-200">Settings</a>
                                        <p onClick={() => auth.signOut()} className="block ml-5 text-base font-bold text-gray-800 px-4 py-2 hover:bg-gray-200">Log out</p>
                                    </div>
                                </>
                            }
                        </div>
                        <div className={`${openNotifications ? "h-screen w-full" : "h-0"} fixed left-0 top-0 z-50 duration-300 bg-white rounded-xl shadow-xl divide-y divide-solid`}>
                            {showNotifications &&
                                <>
                                    <div className="flex flex-row gap-4 p-7">
                                        <div>
                                            <h2 className="font-semibold">Notifications</h2>
                                        </div>
                                        <div className="w-full">
                                            <Ping className="bg-red-500 text-white">{notifications.length}</Ping>
                                        </div>
                                        <button onClick={toggleCollapsed} className="flex items-center justify-end">
                                            <img src={notificationClose} alt="Close" className="w-[2.5rem] scale-125" />
                                        </button>
                                    </div>


                                    <div className="h-[calc(100%-90px)] scrollbar overflow-y-auto overscroll-contain divide-y divide-solid">
                                        {notifications}
                                        {chatNotifications}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </nav>

          

            {/* WHITE VERSION */}
            {/* <nav className="flex items-center gap-4 bg-white p-4">
                <div className="w-[10rem] flex-shrink-0 mr-10 overflow-y-visible">
                    <img src={logoGif} className="object-scale-down pt-2" />
                </div>

                <div className="lg:flex lg:flex-row lg:w-full lg:flex-auto lg:gap-10 hidden">
                    <a href="#responsive-header" className="text-black hover:text-mi-green">
                        Explore
                    </a>
                    <a href="#responsive-header" className="text-black hover:text-mi-green">
                        Campaigns
                    </a>
                    <a href="#responsive-header" className="text-black hover:text-mi-green">
                        Chat
                    </a>
                    <a href="#responsive-header" className="text-black hover:text-mi-green">
                        Contracts
                    </a>
                </div>

                <div className="flex flex-row flex-auto justify-end lg:hidden">
                    <button className="flex items-center px-3 py-2 border rounded text-mi-green border-mi-green hover:text-mi-blue hover:border-mi-blue">
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                    </button>
                </div>
            </nav> */}
        </>
    );
}
export default TopBar;
