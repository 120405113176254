import Tooltip from '@mui/material/Tooltip';

const Ping = (props) => {
    return (
        <>
            <span className={`${props.className} inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full`}>{props.children}</span>
        </>
    );
}

const InfoPing = (props) => {
    return (
        <>
            <Tooltip title={props.children} placement={props.placement} arrow disableFocusListener followCursor={props.followCursor}>
                <span className={`${props.className} text-white bg-gray-300 text-sm hidden md:inline-flex items-center justify-center px-2 py-1 font-bold leading-none rounded-full`}>?</span>
            </Tooltip>
        </>
    );
}

export {
    Ping,
    InfoPing,
}