import React from 'react';
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import { useState } from "react";
import { auth } from "../../firebase";
import TWButton from "../TWButton";


const LinkToStripe = () => {
    const csrf_token = Cookies.get("csrftoken");
    const [loading, setLoading] = useState(false);


    const linkToStripe = async () => {
        setLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/link_to_stripe`;
        const postBody = {
            redirect_url: window.location.href,
        };
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(postBody)
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    window.open(result["data"]["url"], "_self")
                        .then(() => {
                            Swal.fire({
                                icon: "success",
                                title: "Done!",
                                text: "Successfully linked Stripe!",
                                confirmButtonColor: "#7BBA83",
                            }).then(() => window.location.reload());
                        })
                }
                setLoading(false);
            });
    }

    return (
        <>
            <TWButton sm loading={loading} disabled={loading} onClick={linkToStripe}>Link to Stripe</TWButton>
        </>
    );
}

export default LinkToStripe;
