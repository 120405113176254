import React, { useState, useRef } from 'react';
import VideoHeader from './VideoHeader';
import VideoFooter from './VideoFooter';

const VideoCard = (props) => {
    const [isVideoPlaying, setisVideoPlaying] = useState(false);

    const videoRef = useRef(null);

    const onVideoPress = () => {
        if (isVideoPlaying) {
            videoRef.current.pause()
            setisVideoPlaying(false);
        } else {
            videoRef.current.play()
            setisVideoPlaying(true);
        }
    }
    return (
        <div className='relative border-black snap-start object-fill lg:w-2/3'>
            <VideoHeader post_type={props.post_type} />
            <video
                    className='object-fill w-full h-full'
                    ref={videoRef}
                    onClick={onVideoPress}
                    src={props.url}
                    alt='Reels'
                    loop={true}
                    controls
                />

            {(props.footer === true)
                ? <VideoFooter
                    likes={props.likes}
                    plays={props.plays}
                    comments={props.comments}
                    profilePic={props.profilePic}
                    igHandle={props.igHandle} />
                : <></>}
        </div>
    )
}

export default VideoCard;
