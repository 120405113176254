import { formatDate, isoToDate } from "../../dateUtils";
import HorizontalTimelineStep from "./HorizontalTimelineStep";

const HorizontalTimeline = (props) => {
    return (
        <>
            <div className="grid grid-cols-4 my-4 bg-mi-grey">
                <HorizontalTimelineStep line
                    complete={props.step >= 2}
                    failed={props.step === 1.75}
                    pending={props.step === 1}
                    extendToNext={props.step >= 3 || props.step === 2.75 || props.step === 2}
                    isFirstStep={true}
                />
                <HorizontalTimelineStep line
                    complete={props.step >= 3}
                    failed={props.step === 2.75}
                    pending={props.step === 2}
                    extendToNext={props.step >= 4 || props.step === 3.8 || props.step === 3.9 || props.step === 3.5}
                />
                <HorizontalTimelineStep line
                    complete={props.step >= 4}
                    failed={props.step === 3.8 || props.step === 3.9}
                    pending={props.step === 3}
                    rescheduled={props.step === 3.5}
                    extendToNext={props.step >= 5 || props.step === 4.75 || props.step === 4.9 || props.step === 4.5}
                />
                <HorizontalTimelineStep line
                    complete={props.step >= 5}
                    failed={props.step === 4.75 || props.step === 4.9}
                    pending={props.step === 4}
                    rescheduled={props.step === 4.5}
                    isLastStep={true}
                />
            </div>
            <div className="w-full grid grid-cols-4 my-4">
                {/* Proposal Sent/Accept/Reject */}
                <div>
                    <p className="text-center text-slate-700 md:text-base font-semibold font-poppins">
                        {props.step === 1
                            ? props.data.partner.is_influencer
                                ? "Proposal Sent"
                                : "Proposal Received"
                            : props.step === 1.75
                                ? "Proposal Declined"
                                : props.step >= 2
                                    ? "Proposal Accepted"
                                    : "" // should never reach
                        }
                    </p>
                    {props.step > 1 &&
                        <p className="w-full text-center text-gray-600">
                            {props.data.partner.is_influencer
                                ? "Proposal Sent"
                                : "Proposal Received"
                            }: {isoToDate(props.data.offer_date)}
                        </p>
                    }
                    {props.step === 1 &&
                        <p className="w-full text-center text-gray-600">
                            {props.data.partner.is_influencer
                                ? "Response by"
                                : "Respond by"
                            }: {isoToDate(props.data.accept_expiration_date)}
                        </p>
                    }
                    {props.step === 1.75 &&
                        <p className="w-full text-center text-gray-600">
                            Declined: {isoToDate(props.data.response_date)}
                        </p>
                    }
                    {props.step >= 2 &&
                        <p className="w-full text-center text-gray-600">
                            Accepted: {isoToDate(props.data.response_date)}
                        </p>
                    }
                </div>
                {/* Payment Success/Fail */}
                <div>
                    <p className="text-center text-slate-700 md:text-base font-semibold font-poppins">
                        {props.step === 2
                            ? props.data.partner.is_influencer
                                ? "Payment Required"
                                : "Awaiting Payment"
                            : props.step === 2.75
                                ? "Payment Failed"
                                : props.step >= 3
                                    ? props.data.partner.is_influencer
                                        ? "Payment Successful"
                                        : "Payment Received"
                                    : "Payment Expected"
                        }
                    </p>
                    {(props.step === 2 || props.step === 2.75) &&
                        <p className="w-full text-center text-gray-600">
                            {props.data.partner.is_influencer
                                ? "Payment due"
                                : "Expected by"
                            }: {isoToDate(props.data.pay_expiration_date)}
                        </p>
                    }
                    {props.step >= 2 && props.data?.transaction?.timestamp &&
                        <p className="w-full text-center text-gray-600">
                            {isoToDate(props.data.transaction.timestamp)}
                        </p>
                    }

                </div>
                {/* Pickup Success/Reschedule/Fail */}
                <div>
                    <p className="text-center text-slate-700 md:text-base font-semibold font-poppins">
                        {props.step === 3
                            ? "Awaiting Pickup"
                            : (props.step === 3.5)
                                ? "Pickup Rescheduled"
                                : props.step === 3.8
                                    ? "Visit Overdue"
                                    : props.step === 3.9
                                        ? "Rescheduled Visit Overdue"
                                        : props.step >= 4
                                            ? "Pickup Successful"
                                            : (Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                                                && props.data.amendment_approved[0].new_visit_date !== props.data.visit_date)
                                                ? "Pickup Rescheduled"
                                                : "Visit Scheduled"
                        }
                    </p>
                    {(Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                        && props.data.amendment_approved[0].new_visit_date !== props.data.visit_date)
                        ? <>
                            <strike>
                                <p className="w-full text-center text-gray-600">
                                    {isoToDate(props.data.visit_date)}
                                </p>
                            </strike>
                            <p className="w-full text-center text-amber-500">
                                {isoToDate(props.data.amendment_approved[0].new_visit_date)}
                            </p>
                        </>
                        : <p className="w-full text-center text-gray-600">
                            {props.data.visit_date ? isoToDate(props.data.visit_date) : 'Not Selected'}
                        </p>
                    }
                </div>
                {/* Post Success/Reschedule/Fail */}
                <div>
                    <p className="text-center text-slate-700 md:text-base font-semibold font-poppins">
                        {props.step === 4
                            ? "Awaiting Post"
                            : props.step === 4.5
                                ? "Post Rescheduled"
                                : props.step === 4.75
                                    ? "Post Overdue"
                                    : props.step === 4.9
                                        ? "Rescheduled Post Overdue"
                                        : props.step >= 5
                                            ? "Post Published"
                                            : (Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                                                && props.data.amendment_approved[0].new_submission_date !== props.data.submission_date)
                                                ? "Post Rescheduled"
                                                : "Post Scheduled"
                        }
                    </p>
                    {(Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                        && props.data.amendment_approved[0].new_submission_date !== props.data.submission_date)
                        ? <>
                            <strike>
                                <p className="w-full text-center text-gray-600">
                                    {formatDate(props.data.submission_date)}
                                </p>
                            </strike>
                            <p className="w-full text-center text-amber-500">
                                {formatDate(props.data.amendment_approved[0].new_submission_date)}
                            </p>
                        </>
                        : <p className="w-full text-center text-gray-600">
                            {props.data.submission_date ? isoToDate(props.data.submission_date) : 'Not Selected'}
                        </p>
                    }
                </div>
            </div>
        </>
    );
}
export default HorizontalTimeline;