import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import TWButton from "../TWButton";
import TWDropDown from "../TWDropDown";
import TWTextField from "../TWTextArea";

const BusinessProfileSettings = ({ data, profilePicture }) => {
    const csrf_token = Cookies.get("csrftoken");

    const [firstName, setFirstName] = useState(data.first_name ? data.first_name : "");
    const [lastName, setLastName] = useState(data.last_name ? data.last_name : "");
    const [companyName, setCompanyName] = useState(data.company_name ? data.company_name : "");
    const [bio, setBio] = useState(data.bio ? data.bio : "");
    const [category, setCategory] = useState(data.business_category ? data.business_category : "");
    const [otherCategory, setOtherCategory] = useState(data.business_other ? data.business_other : "");
    const [companyURL, setCompanyURL] = useState(data.business_url ? data.business_url : "");
    const [companyEmail, setCompanyEmail] = useState(data.email ? data.email : "");
    const [phoneNumber, setPhoneNumber] = useState(data.phone_number ? data.phone_number : "");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            const saveChanges = async () => {
                const saveData = {
                    company_name: companyName,
                    first_name: firstName,
                    last_name: lastName,
                    bio: bio,
                    category: category,
                    other_category: otherCategory,
                    business_url: companyURL,
                    email: companyEmail,
                    phone_number: phoneNumber,
                    pfp: profilePicture || null,
                }
                const url = `${process.env.REACT_APP_DJANGO_API}/save_user_info`;
                const token = await auth.currentUser.getIdToken();
                const requestMetaData = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf_token,
                        "Authorization": `Bearer ${token}`,
                    },
                    body: JSON.stringify(saveData),
                };
                try {
                    await fetch(url, requestMetaData)
                        .then(res => res.json())
                        .then((result) => {
                            if (!result || !result.success) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Something went wrong!",
                                    confirmButtonColor: "#7BBA83",
                                    footer: "Please try again later.",
                                });
                            } else {
                                Swal.fire({
                                    icon: "success",
                                    title: "Done!",
                                    text: "Saved profile changes!",
                                    confirmButtonColor: "#7BBA83",
                                }).then(() => window.location.reload());
                                
                                setFirstName(result.data.first_name);
                                setLastName(result.data.last_name);
                                setCompanyName(result.data.company_name);
                                setBio(result.data.bio);
                                setCategory(result.data.business_category);
                                setOtherCategory(result.data.business_other);
                                setCompanyURL(result.data.business_url);
                                setCompanyEmail(result.data.email);
                                setPhoneNumber(result.data.phone_number);

                                setLoading(false);
                            }
                        });
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                }
            }

            saveChanges();
        }
    }, [loading]);

    return (
        <>
            <div className="flex flex-col gap-10 w-full p-2 px-10 md:px-40 mb-5">
                <h3 className="w-full font-bold text-2xl text-gray-600 mt-5">Profile Settings</h3>
                <div>
                    <h4 className="w-full font-semibold text-gray-500">Company Name</h4>
                    <div className="w-full">
                        <input type="text" value={companyName} placeholder="e.g. Bob's Diner" onChange={e => { setCompanyName(e.target.value) }} required className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-10 mb-4">
                    <div className="md:w-1/2">
                        <h4 className="w-full font-semibold text-gray-500">First Name</h4>
                        <div className="w-full">
                            <input type="text" value={firstName} placeholder="Enter your first name here" onChange={e => { setFirstName(e.target.value) }} required className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <h4 className="w-full font-semibold text-gray-500">Last Name</h4>
                        <div className="w-full">
                            <input type="text" value={lastName} placeholder="Enter your last name here" onChange={e => { setLastName(e.target.value) }} required className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                        </div>
                    </div>
                </div>

                <div>
                    <h4 className="w-full font-semibold text-gray-500">Bio</h4>
                    <div className="w-full flex">
                        <TWTextField value={bio} placeholder="Enter a bio" onChange={e => { setBio(e) }} required></TWTextField>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-10 mb-4">
                    <div className="w-full md:w-1/2">
                        <h4 className="w-full font-semibold text-gray-500">Category</h4>
                        <div className="w-full flex">
                            <TWDropDown white
                                onChange={e => { setCategory(e) }}
                                data={
                                    [
                                        { value: 1, label: "Advertising/Marketing" },
                                        { value: 2, label: "Aesthetician" },
                                        { value: 3, label: "Arts & Entertainment" },
                                        { value: 4, label: "Wedding Planning Service" },
                                        { value: 5, label: "Automotive" },
                                        { value: 6, label: "Bar" },
                                        { value: 7, label: "Beauty Salon" },
                                        { value: 8, label: "Brick and Mortar" },
                                        { value: 9, label: "Clothing" },
                                        { value: 10, label: "Community Organization" },
                                        { value: 11, label: "Cosmetic & Personal Care" },
                                        { value: 12, label: "Doctor" },
                                        { value: 13, label: "Dance" },
                                        { value: 14, label: "Design" },
                                        { value: 15, label: "Electric" },
                                        { value: 16, label: "Entertainment" },
                                        { value: 17, label: "Event" },
                                        { value: 18, label: "Fashion Designer" },
                                        { value: 19, label: "Food & Beverage" },
                                        { value: 20, label: "Footwear Store" },
                                        { value: 21, label: "Grocery Store" },
                                        { value: 22, label: "Hardware Store" },
                                        { value: 23, label: "Home Decor" },
                                        { value: 24, label: "Home Improvement" },
                                        { value: 25, label: "Hotel & Lodging" },
                                        { value: 26, label: "Interior Design Studio" },
                                        { value: 27, label: "Jewelry/Watches" },
                                        { value: 28, label: "Karaoke" },
                                        { value: 29, label: "Kennel" },
                                        { value: 30, label: "Kitchen/Cooking" },
                                        { value: 31, label: "Landmark & Historical Place" },
                                        { value: 32, label: "Lawyer & Law Firm" },
                                        { value: 33, label: "Local Service" },
                                        { value: 34, label: "Marketing Agency" },
                                        { value: 35, label: "Music Lessons & Instruction School" },
                                        { value: 36, label: "Nonprofit Organization" },
                                        { value: 37, label: "Non-Governmental Organization (NGO)" },
                                        { value: 38, label: "Optician" },
                                        { value: 39, label: "Optometrist" },
                                        { value: 40, label: "Outdoor & Sporting Goods Company" },
                                        { value: 41, label: "Personal Blog" },
                                        { value: 42, label: "Photographer" },
                                        { value: 43, label: "Real Estate" },
                                        { value: 44, label: "Record Label" },
                                        { value: 45, label: "Restaurant" },
                                        { value: 46, label: "Retail" },
                                        { value: 47, label: "Sports & Recreation" },
                                        { value: 48, label: "Video Creator" },
                                        { value: 49, label: "Web Designer" },
                                        { value: 50, label: "Other" },
                                    ]
                                }>  {category} </TWDropDown>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <h4 className="w-full font-semibold text-gray-500">Other:</h4>
                        <div className="w-full">
                            <input type="text" value={otherCategory} disabled={category !== "50" && category !== "Other"} placeholder="e.g. Dog Walking" onChange={e => { setOtherCategory(e.target.label) }} className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 focus:text-gray-700"></input>
                        </div>
                    </div>
                </div>

                <div>
                    <h4 className="w-full font-semibold text-gray-500">Website:</h4>
                    <div className="w-full">
                        <input type="text" value={companyURL} placeholder="Website URL" onChange={e => { setCompanyURL(e.target.value) }} className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-10 mb-4">
                    <div className="md:w-1/2">
                        <h4 className="w-full font-semibold text-gray-500">Email:</h4>
                        <div className="w-full">
                            <input type="text" value={companyEmail} placeholder="Enter business email" onChange={e => { setCompanyEmail(e.target.value) }} required className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                        </div>
                    </div>

                    <div className="md:w-1/2">
                        <h4 className="w-full font-semibold text-gray-500">Phone Number:</h4>
                        <div className="w-full">
                            <input type="text" value={phoneNumber} placeholder="Enter company phone number" onChange={e => { setPhoneNumber(e.target.value) }} required className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-row flex-wrap-reverse justify-center md:justify-end items-center">
                    <div className="flex-auto flex flex-row justify-start">

                    </div>
                    <div className="w-full px-10 md:px-0 md:w-1/3">
                        <TWButton
                            md
                            onClick={() => setLoading(true)}
                            disabled={loading}
                            loading={loading}
                        >
                            Save Changes
                        </TWButton>
                    </div>
                </div>
            </div>
        </>
    );
}
export default BusinessProfileSettings;
