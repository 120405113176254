import { formatDate } from "../../dateUtils";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HorizontalTimelineStep from "./HorizontalTimelineStep";
import VerticalTimelineStep from "./VerticalTimelineStep";

const Timeline = (props) => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const getStepText = () => {
        let text = '';
        let dateText = '';

        switch (props.step) {
            case 1:
                text = props.data.partner.is_influencer ? "Proposal Sent" : "Proposal Received";
                dateText = `${formatDate(props.data.offer_date)}`;
                break;
            case 1.75:
                text = "Proposal Declined";
                dateText = `${formatDate(props.data.response_date)}`;
                break;
            case 2:
            case 2.75:
                text = props.data.partner.is_influencer ? "Payment Required" : "Awaiting Payment";
                dateText = `${formatDate(props.data.pay_expiration_date)}`;
                break;
            case 3:
                text = "Awaiting Pickup";
                break;
            case 3.5:
                text = "Pickup Rescheduled";
                break;
            case 3.8:
                text = "Visit Overdue";
                break;
            case 3.9:
                text = "Rescheduled Visit Overdue";
                break;
            case 4:
                text = "Awaiting Post";
                break;
            case 4.5:
                text = "Post Rescheduled";
                break;
            case 4.75:
                text = "Post Overdue";
                break;
            case 4.9:
                text = "Rescheduled Post Overdue";
                break;
            case 5:
                text = "Post Published";
                break;
            default:
                text = props.step >= 2 ? "Proposal Accepted" : "Payment Expected";
                dateText = props.step >= 2 ? `${formatDate(props.data.response_date)}` : '';
                break;
        }

        // Handling for amendment approved cases
        if (props.step >= 3 && Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0) {
            const amendment = props.data.amendment_approved[0];
            dateText = (amendment.new_visit_date !== props.data.visit_date) ?
                <><strike>{formatDate(props.data.visit_date)}</strike> {formatDate(amendment.new_visit_date)}</> :
                formatDate(props.data.visit_date);
        }

        return { text, dateText };
    };


    return (
        <>
            <div className={`${isSmallScreen ? 'hidden' : 'block'}`}>
                <div className="grid grid-cols-4 mt-5 bg-mi-grey">
                    <HorizontalTimelineStep line
                        complete={props.step >= 2}
                        failed={props.step === 1.75}
                        pending={props.step === 1}
                        extendToNext={props.step >= 3 || props.step === 2.75 || props.step === 2}
                        isFirstStep={true}
                    />
                    <HorizontalTimelineStep line
                        complete={props.step >= 3}
                        failed={props.step === 2.75}
                        pending={props.step === 2}
                        extendToNext={props.step >= 4 || props.step === 3.8 || props.step === 3.9 || props.step === 3.5}
                    />
                    <HorizontalTimelineStep line
                        complete={props.step >= 4}
                        failed={props.step === 3.8 || props.step === 3.9}
                        pending={props.step === 3}
                        rescheduled={props.step === 3.5}
                        extendToNext={props.step >= 5 || props.step === 4.75 || props.step === 4.9 || props.step === 4.5}
                    />
                    <HorizontalTimelineStep line
                        complete={props.step >= 5}
                        failed={props.step === 4.75 || props.step === 4.9}
                        pending={props.step === 4}
                        rescheduled={props.step === 4.5}
                        isLastStep={true}
                    />
                </div>


                <div className="w-full grid grid-cols-4 mt-4">
                    {/* Proposal Sent/Accept/Reject */}
                    <div>
                        <p className="text-center text-mi-black text-base font-semibold font-poppins">
                            {props.step === 1
                                ? props.data.partner.is_influencer
                                    ? "Proposal Sent"
                                    : "Proposal Received"
                                : props.step === 1.75
                                    ? "Proposal Declined"
                                    : props.step >= 2
                                        ? "Proposal Accepted"
                                        : "" // should never reach
                            }
                        </p>
                        {props.step === 1 &&
                            <p className="w-full text-center text-mi-extra-grey">
                                {props.data.partner.is_influencer
                                    ? "Proposal Sent"
                                    : "Proposal Received"
                                }: {formatDate(props.data.offer_date)}
                            </p>
                        }
                        {props.step === 1.75 &&
                            <p className="w-full text-center text-mi-extra-grey">
                                Declined: {formatDate(props.data.response_date)}
                            </p>
                        }
                        {props.step >= 2 &&
                            <p className="w-full text-center text-mi-extra-grey">
                                Accepted: {formatDate(props.data.response_date)}
                            </p>
                        }
                    </div>
                    {/* Payment Success/Fail */}
                    <div>
                        <p className="text-center text-mi-black text-base font-semibold font-poppins">
                            {props.step === 2
                                ? props.data.partner.is_influencer
                                    ? "Payment Required"
                                    : "Awaiting Payment"
                                : props.step === 2.75
                                    ? "Payment Failed"
                                    : props.step >= 3
                                        ? props.data.partner.is_influencer
                                            ? "Payment Successful"
                                            : "Payment Received"
                                        : "Payment Expected"
                            }
                        </p>
                        {(props.step === 2 || props.step === 2.75) &&
                            <p className="w-full text-center text-mi-extra-grey">
                                {props.data.partner.is_influencer
                                    ? "Payment due"
                                    : "Expected by"
                                }: {formatDate(props.data.pay_expiration_date)}
                            </p>
                        }
                        {
                            props.step >= 2 && props.data.transaction && props.data.transaction.timestamp &&
                            <p className="w-full text-center text-mi-extra-grey">
                                {formatDate(props.data.transaction.timestamp)}
                            </p>
                        }
                    </div>
                    {/* Pickup Success/Reschedule/Fail */}
                    <div>
                        <p className="text-center text-mi-black text-base font-semibold font-poppins">
                            {props.step === 3
                                ? "Awaiting Pickup"
                                : (props.step === 3.5)
                                    ? "Pickup Rescheduled"
                                    : props.step === 3.8
                                        ? "Visit Overdue"
                                        : props.step === 3.9
                                            ? "Rescheduled Visit Overdue"
                                            : props.step >= 4
                                                ? "Pickup Successful"
                                                : (Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                                                    && props.data.amendment_approved[0].new_visit_date !== props.data.visit_date)
                                                    ? "Pickup Rescheduled"
                                                    : "Visit Scheduled"
                            }
                        </p>
                        {props.step < 3
                            ? <></>
                            : (Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                                && props.data.amendment_approved[0].new_visit_date !== props.data.visit_date)
                                ? <>
                                    <strike>
                                        <p className="w-full text-center text-mi-extra-grey">
                                            {formatDate(props.data.visit_date)}
                                        </p>
                                    </strike>
                                    <p className="w-full text-center text-amber-500">
                                        {formatDate(props.data.amendment_approved[0].new_visit_date)}
                                    </p>
                                </>
                                : <p className="w-full text-center text-mi-extra-grey">
                                    {props.data.visit_date ? formatDate(props.data.visit_date) : 'Not Selected'}
                                </p>
                        }
                    </div>
                    {/* Post Success/Reschedule/Fail */}
                    <div>
                        <p className="text-center text-mi-black text-base font-semibold font-poppins">
                            {props.step === 4
                                ? "Awaiting Post"
                                : props.step === 4.5
                                    ? "Post Rescheduled"
                                    : props.step === 4.75
                                        ? "Post Overdue"
                                        : props.step === 4.9
                                            ? "Rescheduled Post Overdue"
                                            : props.step >= 5
                                                ? "Post Published"
                                                : (Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                                                    && props.data.amendment_approved[0].new_submission_date !== props.data.submission_date)
                                                    ? "Post Rescheduled"
                                                    : "Post Scheduled"
                            }
                        </p>
                        {props.step < 3
                            ? <></>
                            : (Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                                && props.data.amendment_approved[0].new_submission_date !== props.data.submission_date)
                                ? <>
                                    <strike>
                                        <p className="w-full text-center text-mi-extra-grey">
                                            {formatDate(props.data.submission_date)}
                                        </p>
                                    </strike>
                                    <p className="w-full text-center text-amber-500">
                                        {formatDate(props.data.amendment_approved[0].new_submission_date)}
                                    </p>
                                </>
                                : <p className="w-full text-center text-mi-extra-grey">
                                    {props.data.submission_date ? formatDate(props.data.submission_date) : 'Not Selected'}
                                </p>
                        }
                    </div>
                </div>
            </div>
            {/* Dropdown for smaller screens */}
            {isSmallScreen && (
                <div className="mt-5">
                    <div className="flex flex-row text-center items-center cursor-pointer gap-3">
                        <p className="text-neutral-500 font-semibold">Current Status</p>
                        <p className="font-bold">{getStepText().text}</p>
                        {getStepText().dateText && <p className="text-neutral-500 font-semibold">{getStepText().dateText}</p>}
                    </div>

                    {dropdownOpen && (
                        <div className="flex flex-row mt-2">
                            <div className="grid grid-rows-4 mt-5 mb-5">
                                <VerticalTimelineStep line
                                    complete={props.step >= 2}
                                    failed={props.step === 1.75}
                                    pending={props.step === 1}
                                    extendToNext={props.step >= 3 || props.step === 2.75 || props.step === 2}
                                    isFirstStep={true}
                                />
                                <VerticalTimelineStep line
                                    complete={props.step >= 3}
                                    failed={props.step === 2.75}
                                    pending={props.step === 2}
                                    extendToNext={props.step >= 4 || props.step === 3.8 || props.step === 3.9 || props.step === 3.5}
                                />
                                <VerticalTimelineStep line
                                    complete={props.step >= 4}
                                    failed={props.step === 3.8 || props.step === 3.9}
                                    pending={props.step === 3}
                                    rescheduled={props.step === 3.5}
                                    extendToNext={props.step >= 5 || props.step === 4.75 || props.step === 4.9 || props.step === 4.5}
                                />
                                <VerticalTimelineStep line
                                    complete={props.step >= 5}
                                    failed={props.step === 4.75 || props.step === 4.9}
                                    pending={props.step === 4}
                                    rescheduled={props.step === 4.5}
                                    isLastStep={true}
                                />
                            </div>


                            <div className="w-full grid grid-rows-4 mt-4 gap-4 ml-8">
                                {/* Proposal Sent/Accept/Reject */}
                                <div>
                                    <p className="text-left text-mi-black text-base font-semibold">
                                        {props.step === 1
                                            ? props.data.partner.is_influencer
                                                ? "Proposal Sent"
                                                : "Proposal Received"
                                            : props.step === 1.75
                                                ? "Proposal Declined"
                                                : props.step >= 2
                                                    ? "Proposal Accepted"
                                                    : "" // should never reach
                                        }
                                    </p>
                                    {props.step === 1 &&
                                        <p className="w-full text-left text-mi-extra-grey">
                                            {props.data.partner.is_influencer
                                                ? "Proposal Sent"
                                                : "Proposal Received"
                                            }: {formatDate(props.data.offer_date)}
                                        </p>
                                    }
                                    {props.step === 1.75 &&
                                        <p className="w-full text-left text-mi-extra-grey">
                                            Declined: {formatDate(props.data.response_date)}
                                        </p>
                                    }
                                    {props.step >= 2 &&
                                        <p className="w-full text-left text-mi-extra-grey">
                                            Accepted: {formatDate(props.data.response_date)}
                                        </p>
                                    }
                                </div>
                                {/* Payment Success/Fail */}
                                <div>
                                    <p className="text-left text-mi-black text-base font-semibold">
                                        {props.step === 2
                                            ? props.data.partner.is_influencer
                                                ? "Payment Required"
                                                : "Awaiting Payment"
                                            : props.step === 2.75
                                                ? "Payment Failed"
                                                : props.step >= 3
                                                    ? props.data.partner.is_influencer
                                                        ? "Payment Successful"
                                                        : "Payment Received"
                                                    : "Payment Expected"
                                        }
                                    </p>
                                    {(props.step === 2 || props.step === 2.75) &&
                                        <p className="w-full text-left text-mi-extra-grey">
                                            {props.data.partner.is_influencer
                                                ? "Payment due"
                                                : "Expected by"
                                            }: {formatDate(props.data.pay_expiration_date)}
                                        </p>
                                    }
                                    {props.step >= 2 &&
                                        <p className="w-full text-left text-mi-extra-grey">
                                            {formatDate(props.data.transaction.timestamp)}
                                        </p>
                                    }
                                </div>
                                {/* Pickup Success/Reschedule/Fail */}
                                <div>
                                    <p className="text-left text-mi-black text-base font-semibold">
                                        {props.step === 3
                                            ? "Awaiting Pickup"
                                            : (props.step === 3.5)
                                                ? "Pickup Rescheduled"
                                                : props.step === 3.8
                                                    ? "Visit Overdue"
                                                    : props.step === 3.9
                                                        ? "Rescheduled Visit Overdue"
                                                        : props.step >= 4
                                                            ? "Pickup Successful"
                                                            : (Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                                                                && props.data.amendment_approved[0].new_visit_date !== props.data.visit_date)
                                                                ? "Pickup Rescheduled"
                                                                : "Visit Scheduled"
                                        }
                                    </p>
                                    {props.step < 3
                                        ? <></>
                                        : (Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                                            && props.data.amendment_approved[0].new_visit_date !== props.data.visit_date)
                                            ? <>
                                                <strike>
                                                    <p className="w-full text-left text-mi-extra-grey">
                                                        {formatDate(props.data.visit_date)}
                                                    </p>
                                                </strike>
                                                <p className="w-full text-left text-amber-500">
                                                    {formatDate(props.data.amendment_approved[0].new_visit_date)}
                                                </p>
                                            </>
                                            : <p className="w-full text-left text-mi-extra-grey">
                                                {props.data.visit_date ? formatDate(props.data.visit_date) : 'Not Selected'}
                                            </p>
                                    }
                                </div>
                                {/* Post Success/Reschedule/Fail */}
                                <div>
                                    <p className="text-left text-mi-black text-base font-semibold">
                                        {props.step === 4
                                            ? "Awaiting Post"
                                            : props.step === 4.5
                                                ? "Post Rescheduled"
                                                : props.step === 4.75
                                                    ? "Post Overdue"
                                                    : props.step === 4.9
                                                        ? "Rescheduled Post Overdue"
                                                        : props.step >= 5
                                                            ? "Post Published"
                                                            : (Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                                                                && props.data.amendment_approved[0].new_submission_date !== props.data.submission_date)
                                                                ? "Post Rescheduled"
                                                                : "Post Scheduled"
                                        }
                                    </p>
                                    {props.step < 3
                                        ? <></>
                                        : (Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0
                                            && props.data.amendment_approved[0].new_submission_date !== props.data.submission_date)
                                            ? <>
                                                <strike>
                                                    <p className="w-full text-left text-mi-extra-grey">
                                                        {formatDate(props.data.submission_date)}
                                                    </p>
                                                </strike>
                                                <p className="w-full text-left text-amber-500">
                                                    {formatDate(props.data.amendment_approved[0].new_submission_date)}
                                                </p>
                                            </>
                                            : <p className="w-full text-left text-mi-extra-grey">
                                                {props.data.submission_date ? formatDate(props.data.submission_date) : 'Not Selected'}
                                            </p>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-row mt-4 justify-center items-center" onClick={toggleDropdown}>
                        <ArrowBackIosNewIcon
                            className={`h-6 w-6 transform ${dropdownOpen ? 'rotate-90' : '-rotate-90'}`}
                            style={{ transition: 'transform 0.3s ease-in-out' }}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
export default Timeline;