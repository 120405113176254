import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import TWButton from "../TWButton";
import TWSelect from "../TWSelect";

const Address = ({ data }) => {
    const isAddressAvailable = (data !== null && data !== undefined && data.length !== 0);

    const [addresses, setAddresses] = useState(isAddressAvailable ? data : []);
    const [curAddress, setCurAddress] = useState(0);
    const [newAddress, setNewAddress] = useState(!isAddressAvailable);

    const [line1, setLine1] = useState(
        isAddressAvailable
            ? data[0].line_1 ? data[0].line_1 : ""
            : "");
    const [line2, setLine2] = useState(
        isAddressAvailable
            ? data[0].line_2 ? data[0].line_2 : ""
            : "");
    const [line3, setLine3] = useState(
        isAddressAvailable
            ? data[0].line_3 ? data[0].line_3 : ""
            : "");
    const [city, setCity] = useState(
        isAddressAvailable
            ? data[0].city ? data[0].city : ""
            : "");
    const [state, setState] = useState(
        isAddressAvailable
            ? data[0].state ? data[0].state : ""
            : "");
    const [zipcode, setZipcode] = useState(
        isAddressAvailable
            ? data[0].zip_code ? data[0].zip_code : ""
            : "");

    const [operation, setOperation] = useState(null);
    const [loading, setLoading] = useState(false);

    const csrf_token = Cookies.get("csrftoken");

    const fiftyStates = [
        { value: "AK", label: "Alaska" },
        { value: "AL", label: "Alabama" },
        { value: "AR", label: "Arkansas" },
        { value: "AZ", label: "Arizona" },
        { value: "CA", label: "California" },
        { value: "CO", label: "Colorado" },
        { value: "CT", label: "Connecticut" },
        { value: "DC", label: "District of Columbia" },
        { value: "DE", label: "Delaware" },
        { value: "FL", label: "Florida" },
        { value: "GA", label: "Georgia" },
        { value: "HI", label: "Hawaii" },
        { value: "IA", label: "Iowa" },
        { value: "ID", label: "Idaho" },
        { value: "IL", label: "Illinois" },
        { value: "IN", label: "Indiana" },
        { value: "KS", label: "Kansas" },
        { value: "KY", label: "Kentucky" },
        { value: "LA", label: "Louisiana" },
        { value: "MA", label: "Massachusetts" },
        { value: "MD", label: "Maryland" },
        { value: "ME", label: "Maine" },
        { value: "MI", label: "Michigan" },
        { value: "MN", label: "Minnesota" },
        { value: "MO", label: "Missouri" },
        { value: "MS", label: "Mississippi" },
        { value: "MT", label: "Montana" },
        { value: "NC", label: "North Carolina" },
        { value: "ND", label: "North Dakota" },
        { value: "NE", label: "Nebraska" },
        { value: "NH", label: "New Hampshire" },
        { value: "NJ", label: "New Jersey" },
        { value: "NM", label: "New Mexico" },
        { value: "NV", label: "Nevada" },
        { value: "NY", label: "New York" },
        { value: "OH", label: "Ohio" },
        { value: "OK", label: "Oklahoma" },
        { value: "OR", label: "Oregon" },
        { value: "PA", label: "Pennsylvania" },
        { value: "RI", label: "Rhode Island" },
        { value: "SC", label: "South Carolina" },
        { value: "SD", label: "South Dakota" },
        { value: "TN", label: "Tennessee" },
        { value: "TX", label: "Texas" },
        { value: "UT", label: "Utah" },
        { value: "VA", label: "Virginia" },
        { value: "VT", label: "Vermont" },
        { value: "WA", label: "Washington" },
        { value: "WI", label: "Wisconsin" },
        { value: "WV", label: "West Virginia" },
        { value: "WY", label: "Wyoming" },
    ];

    // function to save changes to an existing address
    const saveChanges = async () => {
        const saveData = {
            id: addresses[curAddress].id,
            line1: line1,
            line2: line2,
            line3: line3,
            city: city,
            state: state,
            zipcode: zipcode,
        }
        const url = `${process.env.REACT_APP_DJANGO_API}/update_address`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(saveData),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Done!",
                            text: "Saved address changes!",
                            confirmButtonColor: "#7BBA83",
                        });
                        setAddresses(result.data);
                        setCurAddress(0);
                        setLoading(false);
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    // function to save a new address
    const createAddress = async () => {
        const saveData = {
            line1: line1,
            line2: line2,
            line3: line3,
            city: city,
            state: state,
            zipcode: zipcode,
        }
        const url = `${process.env.REACT_APP_DJANGO_API}/create_address`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(saveData),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Done!",
                            text: "Saved address!",
                            confirmButtonColor: "#7BBA83",
                        });
                        setAddresses(result.data);
                        setCurAddress(0);
                        setNewAddress(false);
                        setLoading(false);
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    // function to delete an address
    const deleteAddress = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/delete_address`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: addresses[curAddress].id }),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Done!",
                            text: "Deleted address!",
                            confirmButtonColor: "#7BBA83",
                        });
                        setAddresses(result.data);
                        setCurAddress(0);
                        setLoading(false);
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    // function to make backend calls based on the operation selected
    useEffect(() => {
        if (loading) {
            switch (operation) {
                case "create":
                    createAddress();
                    setOperation(null);
                    break;
                case "save":
                    saveChanges();
                    setOperation(null);
                    break;
                case "delete":
                    deleteAddress();
                    setOperation(null);
                    break;
                default:
                    break;
            }
        }
    }, [loading]);

    // function to clear inputfields when adding new address and repopulate when toggled off
    useEffect(() => {
        // adding new address => empty fields
        if (newAddress) {
            setLine1("");
            setLine2("");
            setLine3("");
            setCity("");
            setState("");
            setZipcode("");
        } else {
            if (isAddressAvailable) {
                setLine1(addresses[curAddress].line_1);
                setLine2(addresses[curAddress].line_2);
                setLine3(addresses[curAddress].line_3);
                setCity(addresses[curAddress].city);
                setState(addresses[curAddress].state);
                setZipcode(addresses[curAddress].zip_code);
            }
        }
    }, [newAddress]);

    // function to update fields when address dropdown is changed
    useEffect(() => {
        if (isAddressAvailable) {
            setLine1(addresses[curAddress].line_1);
            setLine2(addresses[curAddress].line_2);
            setLine3(addresses[curAddress].line_3);
            setCity(addresses[curAddress].city);
            setState(addresses[curAddress].state);
            setZipcode(addresses[curAddress].zip_code);
        }
    }, [curAddress]);

    return (
        <>
            <div className="flex flex-col gap-10 py-8 px-10 md:px-40">
                <div className="flex flex-row items-center gap-4">
                    <h3 className="md:w-[20%] font-bold text-2xl text-gray-600">Addresses</h3>
                    <div className="flex-auto flex justify-end">
                        <div className="w-full md:w-1/3">
                            <TWSelect whitebox value={curAddress} disabled={loading || newAddress || !isAddressAvailable} onChange={e => setCurAddress(e.target.value)} >
                                {
                                    addresses.map((address, index) => {
                                        return <option key={index} value={index}>{address.line_1}</option>
                                    })
                                }
                            </TWSelect>
                        </div>
                    </div>
                    <div className="hidden md:flex justify-self-end items-end justify-end self-end content-end">
                        <TWButton
                            sm
                            onClick={() => setNewAddress(!newAddress)}
                            disabled={loading || !isAddressAvailable}
                        >
                            {newAddress ? "Cancel New Address" : "Add New Address"}
                        </TWButton>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-10">
                    <div className="flex flex-col gap-10 md:w-1/2">
                        <div>
                            <h4 className="w-full font-semibold text-gray-500">Line 1:</h4>
                            <div className="w-full flex">
                                <input type="text" value={line1} placeholder="Enter Street 1"
                                    onChange={e => { setLine1(e.target.value) }} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                            </div>
                        </div>

                        <div>
                            <h4 className="w-full font-semibold text-gray-500">Line 2 (optional):</h4>
                            <div className="w-full flex">
                                <input type="text" value={line2} placeholder="Enter Street 2"
                                    onChange={e => { setLine2(e.target.value) }} className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                            </div>
                        </div>

                        <div>
                            <h4 className="w-full font-semibold text-gray-500">Line 3 (optional):</h4>
                            <div className="w-full flex">
                                <input type="text" value={line3} placeholder="Enter Street 3"
                                    onChange={e => { setLine3(e.target.value) }} className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-10 mb-4 md:w-1/2">
                        <div>
                            <h4 className="w-full font-semibold text-gray-500">City:</h4>
                            <div className="w-full flex">
                                <input type="text" value={city} placeholder="Enter your city here"
                                    onChange={e => { setCity(e.target.value) }} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                            </div>
                        </div>

                        <div>
                            <h4 className="w-full font-semibold text-gray-500">State:</h4>
                            <div className="w-full flex">
                                <TWSelect whitebox value={state} disabled={loading} onChange={e => setState(e.target.value)} >
                                    {
                                        fiftyStates.map((province, index) => {
                                            return <option key={index} value={province.value}>{province.label}</option>
                                        })
                                    }
                                </TWSelect>
                            </div>
                        </div>

                        <div>
                            <h4 className="w-full font-semibold text-gray-500">Zipcode:</h4>
                            <div className="w-full flex">
                                <input type="text" value={zipcode} placeholder="Enter your zipcode here"
                                    onChange={e => { setZipcode(e.target.value) }} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4 md:flex-row w-full items-center justify-end px-10 md:px-0">
                    <div className="w-full flex-auto flex flex-row justify-start items-center">
                        {newAddress
                            ? <p className="font-bold text-mi-green text-lg">Adding New Address . . .</p>
                            : <div className="w-full md:w-1/6">
                                <TWButton
                                    delete
                                    md
                                    onClick={() => {
                                        setOperation("delete");
                                        setLoading(true);
                                    }}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    Delete Address
                                </TWButton>
                            </div>
                        }
                    </div>

                    <div className="w-full items-center md:w-1/6">
                        <TWButton
                            md
                            onClick={() => {
                                if (newAddress) {
                                    setOperation("create");
                                } else {
                                    setOperation("save");
                                }
                                setLoading(true);
                            }}
                            loading={loading}
                            disabled={loading}
                        >
                            Save Address
                        </TWButton>
                    </div>

                    <div className="w-full items-center md:hidden">
                        <TWButton
                            md
                            onClick={() => setNewAddress(!newAddress)}
                            disabled={loading || !isAddressAvailable}
                        >
                            {newAddress ? "Cancel New Address" : "Add New Address"}
                        </TWButton>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Address;
