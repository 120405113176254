import {
    completeIcon,
    failedIcon,
    pendingIcon,
    rescheduledIcon,
    voidIcon
} from "./TimelineIcons";

const VerticalTimelineStep = (props) => {

    const getIconAndColor = () => {
        if (props.complete) return { 
            icon: completeIcon, 
            color: "bg-mi-blue"
        };
        if (props.failed) return { 
            icon: failedIcon, 
            color: "bg-red-500"
        };
        if (props.rescheduled) return { 
            icon: rescheduledIcon, 
            color: "bg-yellow-500"
        };
        if (props.pending) return { 
            icon: pendingIcon, 
            color: "bg-mi-grey"
        };
        return { 
            icon: voidIcon,
            color: "bg-mi-grey"
        };
    }

    const { icon, color } = getIconAndColor();

    return (
        <div className={`flex flex-col items-center ${props.extendToNext ? 'mb-4' : ''}`}>
            {!props.isFirstStep && <div className={`w-1 bg-mi-grey flex-grow ${props.complete ? 'bg-mi-blue' : ''}`}></div>}
            <div className={`w-8 h-8 ${color} rounded-full flex items-center justify-center z-10 shadow-lg`}>
                {icon}
            </div>
            {!props.isLastStep && <div className={`w-1 bg-mi-grey flex-grow ${props.complete ? 'bg-mi-blue' : ''}`}></div>}
        </div>
    );
};

export default VerticalTimelineStep;
