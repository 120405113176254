import React, { useState, useEffect } from 'react';
import { auth } from "../../firebase";
import EditIcon from '@mui/icons-material/Edit';
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import TWButton from "../TWButton";
import AddModal from "./AddModal";
import { Tooltip } from "@mui/material";
import TWTextField from "../TWTextField";
import { isoToDate } from '../../dateUtils';
import ExtendModal from './ExtendModal';

const AboutPage = (props) => {
    const csrf_token = Cookies.get("csrftoken");
    const [loading, setLoading] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [extendModal, setExtendModal] = useState(false);
    const [editClientPhone, setEditClientPhone] = useState(false);
    const [unavailableDates, setUnavailableDates] = useState([]);
    const [dayAvailability, setDayAvailability] = useState({
        Sun: true,
        Mon: true,
        Tue: true,
        Wed: true,
        Thu: true,
        Fri: true,
        Sat: true,
    });
    const [clientId, setClientId] = useState(
        props.data.client_info && props.data.client_info.length
            ? props.data.client_info[0].client_id
            : ""
    );

    const deleteProduct = async (data) => {
        setLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/delete_product`;
        const requestMetaData = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                prodID: data,
            }),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert,
                        footer: "Please try again later.",
                    });
                } else {
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: result.alert,
                    }).then(() => window.location.reload());
                }
            });
    }

    const getUnavailableDatesFromDayAvailability = (startDate, endDate, dayAvailability, currentUnavailableDates) => {
        const newUnavailableDates = [];
        let date = new Date(startDate);
        while (date <= endDate) {
            const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
            if (!dayAvailability[dayName] && !currentUnavailableDates.some(d => isSameDay(d, date))) {
                newUnavailableDates.push(new Date(date));
            }
            date.setDate(date.getDate() + 1);
        }
        return newUnavailableDates;
    };

    const isSameDay = (d1, d2) => {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    };

    const confirmEndCampaign = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "By confirming you will be no longer able to edit the campaign. This action cannot be undone.",
            showCancelButton: true,
            confirmButtonColor: "#7BBA83",
            cancelButtonColor: "#ef4444",
            confirmButtonText: "Yes, confirm!"
        }).then((result) => {
            if (result.isConfirmed) {
                concludeCampaign();
            }
        });
    }

    const concludeCampaign = async () => {
        setLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/retire_campaign/${props.data.id}`;
        const requestMetaData = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                    setLoading(false);
                } else if (!result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Some proposals failed to send!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please make sure there are not any active contracts on this campagin.",
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: "Campaign successfully retired!",
                        confirmButtonColor: "#7BBA83",
                    }).then(() => window.location.reload());
                }
            });
    }

    useEffect(() => {
        const getUnavailableDates = async (data) => {
            const token = await auth.currentUser.getIdToken();
            const url = `${process.env.REACT_APP_DJANGO_API}/get_extend_details/${props.data.id}`;
            const requestMetaData = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrf_token,
                    "Authorization": `Bearer ${token}`,
                },
            };
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: result.alert,
                            footer: "Please try again later.",
                        });
                    } else {
                        const fetchedUnavailableDates = result.data.unavaliable_dates
                            ? result.data.unavaliable_dates.map(date => new Date(date))
                            : [];
                        const fetchedDayAvailability = {
                            Sun: result.data.avalibility_list.sunday,
                            Mon: result.data.avalibility_list.monday,
                            Tue: result.data.avalibility_list.tuesday,
                            Wed: result.data.avalibility_list.wednesday,
                            Thu: result.data.avalibility_list.thursday,
                            Fri: result.data.avalibility_list.friday,
                            Sat: result.data.avalibility_list.saturday,
                        };
                        const allUnavailableDates = getUnavailableDatesFromDayAvailability(
                            new Date(props.data.start_date),
                            new Date(props.data.end_date),
                            fetchedDayAvailability,
                            fetchedUnavailableDates
                        );
                        setUnavailableDates([...fetchedUnavailableDates, ...allUnavailableDates]);
                        setDayAvailability(fetchedDayAvailability);
                    }
                });
        }
        getUnavailableDates();
    }, []);

    const isValidPhoneNumber = (number) => {
        const regex = /^(\+\d{1,2}\s)?\d{1,4}\s?\d{1,4}\s?\d{1,4}$/;
        return regex.test(number);
    };

    const editClient = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/edit_client`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                clientId: clientId,
                campaignId: props.data.id,
                clientName: props.clientName,
                pocNumber: props.clientPhone,
            }),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        props.onClientPhoneChange(result.data.poc_number);
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    return (
        <>
            {/* CAMPAIGN AND DESCRIPTION */}
            <div className="pt-20">
                {/* CAMPAIGN DESCRIPTION */}
                <div className="bg-white rounded-3xl md:overflow-y-auto md:scrollbar p-10 border drop-shadow-lg">
                    <div>
                        <div className="text-xl font-bold text-neutral-600 font-poppin">
                            Description:
                        </div>
                        <div className="text-base font-poppins pt-4">
                            {props.data.description}
                        </div>
                    </div>
                </div>
            </div>

            <div className="rounded-xl pt-16">
                <div className="bg-white rounded-xl border shadow-lg p-10">
                    <div className="grid grid-cols-2 mb-4">
                        <h2 className="text-xl text-mi-black">Products</h2>
                        <div className="justify-self-end">
                            <TWButton
                                md
                                loading={loading}
                                disabled={loading || props.data.status === "Retired"}
                                onClick={() => setAddModal(true)}
                            >
                                + Add Product
                            </TWButton>
                        </div>
                    </div>
                    <table className="table-auto text-left w-full border border-mi-grey text-base text-black rounded-lg overflow-hidden">
                        <thead className="bg-mi-blue text-white">
                            <tr>
                                <th className="py-4 px-6">ID</th>
                                <th className="py-4 px-6">Product Name</th>
                                <th className="py-4 px-6">Product Details</th>
                                <th className="py-4 px-6">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.product.map((prod, key, arr) => {
                                const isLastItem = key === arr.length - 1;
                                return (
                                    <tr key={key} className={`bg-blue-100 even:bg-gray-100 ${isLastItem ? 'rounded-b-lg' : ''}`}>
                                        <td className="py-3 px-4">{prod.product_id}</td>
                                        <td className="py-3 px-4">{prod.product_name}</td>
                                        <td className="py-3 px-4">{prod.product_description}</td>
                                        <td className="py-3 px-4 text-start">
                                            <TWButton delete
                                                onClick={() => deleteProduct(prod.product_id)}
                                                disabled={props.data.status === "Retired"}
                                            >
                                                Delete
                                            </TWButton>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="pt-12">
                        <div className="text-xl text-mi-black">
                            <h2 className="text-xl">Additional Details</h2>
                        </div>
                        <div className="text-xs sm:text-base pt-4">
                            {props.data.additional_details}
                        </div>
                    </div>
                    {props.data.client_info && props.data.client_info.length > 0 &&
                        <div className="pt-12">
                            <div className="text-xl text-mi-black">
                                <h2 className="font-poppins text-xl">Client Info</h2>
                            </div>
                            <div className="text-xs font-poppins sm:text-base pt-4">
                                <div className="flex flex-row items-center">
                                    <h3 className="text-mi-black mr-2">Client Name:</h3>
                                    <p className="mr-1">{props.clientName}</p>
                                </div>
                                <div className="flex flex-row items-center mt-4">
                                    <h3 className="text-mi-black mr-2">Client Phone:</h3>
                                    {!editClientPhone ? (
                                        <>
                                            <p className="mr-1">{props.clientPhone}</p>
                                            <button onClick={() => setEditClientPhone(true)} disabled={props.data.status === "Retired"}>
                                                <EditIcon className="text-base" />
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <TWTextField
                                                type="tel"
                                                value={props.clientPhone}
                                                onChange={e => props.onClientPhoneChange(e)}
                                                disabled={props.data.status === "Retired"}
                                            />
                                            <TWButton
                                                sm
                                                onClick={async () => {
                                                    await editClient();
                                                    setEditClientPhone(false);
                                                }}
                                                disabled={!props.clientPhone || props.data.status === "Retired"}
                                            >
                                                Save
                                            </TWButton>
                                            <TWButton sm delete onClick={() => setEditClientPhone(false)} disabled={props.data.status === "Retired"}>
                                                Cancel
                                            </TWButton>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="rounded-xl pt-16">
                <div className="bg-white rounded-xl border shadow-lg p-10">
                    <div className="grid grid-cols-2">
                        <h2 className="text-xl text-mi-black">Campaign Duration</h2>
                        <div className="justify-self-end">
                            <TWButton
                                md
                                loading={loading}
                                disabled={loading || props.data.status === "Retired"}
                                onClick={() => setExtendModal(true)}
                            >
                                Extend Campaign
                            </TWButton>
                        </div>
                    </div>
                    <div className="text-xs font-poppins sm:text-base pt-4">
                        <div className="flex flex-row items-center">
                            <h3 className="text-mi-black mr-2">Launch Date:</h3>
                            <p className="mr-1">{isoToDate(props.data.start_date)}</p>
                        </div>
                        <div className="flex flex-row items-center mt-4">
                            <h3 className="text-mi-black mr-2">Conclusion Date:</h3>
                            <p className="mr-1">{isoToDate(props.data.end_date)}</p>
                        </div>
                    </div>
                </div>
            </div>

            {props.data.status === "Inactive" && (
                <div className="relative flex justify-end border-solid border-slate-200 mx-3 py-3">
                    <div className="max-w-sm">
                        <TWButton onClick={confirmEndCampaign} loading={loading}>Conclude Campaign</TWButton>
                    </div>
                </div>
            )}

            {addModal && <AddModal setShowModal={setAddModal} campaignID={props.data.id} />}
            {extendModal && (
                <ExtendModal
                    setShowModal={setExtendModal}
                    startDate={props.data.start_date}
                    campaignID={props.data.id}
                    endDate={props.data.end_date}
                    unavailableDates={unavailableDates}
                />
            )}
        </>
    );
}

export default AboutPage;
