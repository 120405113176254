import { useEffect, useState, Fragment } from "react";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import Modal from "../Modal";
import TWButton from "../TWButton";
import TWTextField from "../TWTextField";
import TWTextArea from "../TWTextArea";
import TWDropDown from "../TWDropDown";
import { useMediaQuery } from "react-responsive";

const CreateListModal = (props) => {
    const [title, setTitle] = useState("");

    const [loading, setLoading] = useState(false);

    const csrf_token = Cookies.get("csrftoken");

    const formComplete = () => {
        return (title);
    }

    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

    const createSaveList = async () => {
        setLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/create_save_list`;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                title: title,
            }),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert,
                        footer: "Please try again later.",
                    });
                } else {
                    setLoading(false);
                    props.setShowModal(false);
                    props.onNewListCreated(result.data);
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: result.alert,
                    });

                }
            });
    }

    return (
        <>
            {/* NON-MOBILE VERSION */}
            {!isSmallScreen && (
                <Modal noOverflow setShowModal={props.setShowModal}>

                    {/* header */}
                    <div className="flex flex-col gap-2 items-start w-full pt-5">

                        <h2 className="flex pl-6 mb-4">
                            Create List
                        </h2>


                        {/* <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mx-3 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white fixed top-6 right-4 z-40" data-modal-hide="defaultModal" onClick={() => props.setShowModal(false)} >
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button> */}
                    </div>
                    {/* horizontal line */}
                    <div className="border-b border-solid border-slate-200 w-11/12"></div>

                    {/* body */}
                    <div className="flex flex-col p-4 gap-2 w-[30rem]">
                        <div className="mb-4">
                            <div className="flex flex-col w-full">
                                <h4 className="w-full font-semibold text-gray-600 pb-2 pt-7">Name</h4>
                                <TWTextField whitebox placeholder="e.g.) Traveling" onChange={setTitle} />
                            </div>
                        </div>
                    </div>

                    {/* footer */}
                    {/* horizontal line */}
                    <div className = "pt-7"></div>
                    <div className="w-11/12 border-t pt-2 border-solid border-slate-200"></div>
                    <div className="flex flex-col gap-2 mb-4 mr-7 items-end md:justify-end w-full pt-3">
                        <div className="flex flex-row gap-2">
                            <div className="flex-auto md:grow-0">
                                <TWButton delete loading={loading} onClick={() => props.setShowModal(false)}>
                                    Cancel
                                </TWButton>
                            </div>
                            <div className="flex-auto md:grow-0">
                                <TWButton md loading={loading} disabled={!formComplete() || loading} onClick={() => { createSaveList() }}>
                                    Create
                                </TWButton>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}

            {/* MOBILE VERSION */}
            {isSmallScreen && (
                <div className=" p-5 z-50 mt-auto scrollbar bg-white h-min shadow-xl ">
                    <Modal noOverflow {...(isSmallScreen && { minimum: true })} setShowModal={props.setShowModal}>
                        {/* header */}
                        <div className="flex flex-col ml-12 mt-6 w-11/12">

                            <h2 className="flex mb-4">
                                Create List
                            </h2>
                            {/* horizontal line */}
                            <div className="border-b  border-slate-200 w-11/12"></div>
                        </div>

                        {/* body */}
                        <div className="flex flex-col p-4 gap-2 w-full">
                            <div className="mb-4">
                                <div className="flex flex-col w-full">
                                    <h4 className="w-full font-semibold text-gray-600 pb-2">Name</h4>

                                    <TWTextField whitebox placeholder="e.g.) Traveling" onChange={setTitle} />
                                </div>


                            </div>

                        </div>

                        {/* footer */}
                        <div className="flex flex-col gap-2 mb-4 mr-7 items-end md:justify-end w-full pt-3">
                            <div className="w-11/12 border-t border-solid border-slate-200"></div>
                            <div className="flex flex-row gap-2">
                                <div className="flex-auto md:grow-0">
                                    <TWButton delete loading={loading} onClick={() => props.setShowModal(false)}>
                                        Cancel
                                    </TWButton>
                                </div>
                                <div className="flex-auto md:grow-0">
                                    <TWButton md loading={loading} disabled={!formComplete() || loading} onClick={() => { createSaveList() }}>
                                        Create
                                    </TWButton>
                                </div>
                            </div>
                        </div>


                    </Modal>

                </div>
            )}
        </>
    );
}
export default CreateListModal;
