import ProfilePic from "../ProfilePic";
import viewContract from "../../assets/images/chat_view_contract.svg";

import { useNavigate} from 'react-router-dom';
import { searchContracts} from "../contracts/Contracts.js";


const Header = ({ setChatToggle, conversation }) => {
    const navigate = useNavigate();

    return (
        <>
            <header className="h-full">
                <div className="items-center gap-0 h-full">


                    <div className="flex flex-row gap-3 sm:gap-7">
                        <div className="h-full">
                            <div className="h-full w-[3rem] mx-auto">
                                <ProfilePic border={true} image={conversation.profilePicture ? conversation.profilePicture : null} />
                            </div>
                        </div>
                        <div className="flex w-full flex-col justify-center">
                            <h3 className="text-left sm:text-base text-sm">{conversation.name} </h3>
                            <p className="text-sm sm:text-md text-gray-400">{conversation.campaignNames.map((name, index) => {
                                    return ( index < conversation.campaignNames.length-1 ? name.concat(", ") : name);
                            })}</p>
                        </div>
                        <div className="w-full flex justify-end items-center pr-4">
                        <button onClick={() => navigate(`/contracts/${conversation.name}`)}>
                            {JSON.stringify(conversation.campaignNames) != JSON.stringify(["No Campaigns"]) ? 
                                <img src={viewContract} alt="View Contract" className="w-auto" /> : null
                            }
                        </button>
                    </div>
                    </div>
                    
                </div>
                <div className="border-b pt-3"></div>
            </header>
        </>
    );
}
export default Header;