import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState } from "react";
import ReactGA from "react-ga4";

import Signin from "../signin/Signin"
import Signup from "../signup/Signup"
import Explore from "../explore/Explore";
import Chat from "../chat/Chat";
import Contracts from "../contracts/Contracts";
import PageWrapper from "../PageWrapper";
import CreateAccount from "../signup/CreateAccount";
import Campaign from "../campaign/Campaign";
import DetailsPage from "../contract_detail/DetailsPage";
import Profile from "../settings/Profile";
import Dashboard from "../influencer_dashboard/Dashboard";
import LinkModal from "../influencer_dashboard/LinkModal";
import Thankyou from "../thankyou/Thankyou";
import RouteChangeTracker from "./RouteChangeTracker";
import PasswordReset from "../signin/PasswordReset";
import Home from "../home/Home";


function App() {
    const [accountType, setAccountType] = useState();
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

    return (
        <BrowserRouter>
            <RouteChangeTracker />
            <Routes>
                <Route path="/" element={<Signin />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/create_account" element={<CreateAccount />} />
                <Route path="/password_recovery" element={<PasswordReset />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/thankyou" element={<Thankyou setAccountType={setAccountType} />} />
                <Route path="/link" element={<LinkModal setAccountType={setAccountType} />} />
                <Route path="/home" element={<PageWrapper setAccountType={setAccountType} >{(accountType === "Influencer")
                    ? <Home />
                    : <Campaign accountType={accountType} />
                }</PageWrapper>} />
                <Route path="/chat" element={<PageWrapper setAccountType={setAccountType} ><Chat accountType={accountType}/></PageWrapper>} />
                <Route path="/chat/:contractID" element={<PageWrapper setAccountType={setAccountType} ><Chat accountType={accountType}/></PageWrapper>} />
                <Route path="/contracts" element={<PageWrapper setAccountType={setAccountType} ><Contracts accountType={accountType}/></PageWrapper>} />
                <Route path="/profile" element={<PageWrapper setAccountType={setAccountType} ><Profile /></PageWrapper>} />
                <Route path="/contracts/:query" element={<PageWrapper setAccountType={setAccountType} ><Contracts accountType={accountType}/></PageWrapper>} />
                <Route path="/contract/:contractID" element={<PageWrapper setAccountType={setAccountType} ><DetailsPage /></PageWrapper>} />
                {/* influencer only paths */}
                {(accountType === "Influencer") &&
                    <Route path="/home" element={<PageWrapper setAccountType={setAccountType} ><Dashboard /></PageWrapper>} />
                }
                {/* business only paths */}
                {(accountType === "Business" || accountType === "Agency") &&
                    <>
                        <Route path="/explore" element={<PageWrapper setAccountType={setAccountType} ><Explore accountType={accountType}/></PageWrapper>} />
                        <Route path="/explore/:query" element={<PageWrapper setAccountType={setAccountType} ><Explore accountType={accountType}/></PageWrapper>} />
                        <Route path="/campaigns" element={<PageWrapper setAccountType={setAccountType} ><Campaign accountType={accountType}/></PageWrapper>} />
                        <Route path="/campaigns/:query" element={<PageWrapper setAccountType={setAccountType} ><Campaign accountType={accountType}/></PageWrapper>} />
                    </>
                }


                {/* for routes that don"t exist */}
                <Route path="*" element={<PageWrapper setAccountType={setAccountType} ><p>There's nothing here!</p></PageWrapper>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
