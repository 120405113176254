import { auth } from "../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie"

import { Ping } from "./Pings";

const ChatNotificationListing = ({ data, refreshChatNotifications }) => {
    const csrf_token = Cookies.get("csrftoken");

    const deleteSelf = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/delete_chat_notification`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ notifID: data.id }),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        refreshChatNotifications(result.data);
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    return (
        <>
            <div className="relative overflow-x-clip">
                <a href={`/chat/${data.contract_id}`}>
                    <div className="flex flex-row items-center justify-center w-full px-4 py-4 hover:bg-gray-200 gap-5">
                        <img className="rounded-full object-cover h-full w-20 overflow-hidden" src={data.profile_pic} />
                        <h3 className="flex-auto text-left font-semibold text-gray-500">{data.sender} sent you a message!</h3>
                        <div className="h-6 w-6 rounded-full flex items-center justify-center text-white font-semibold bg-mi-green text-sm">
                            {data.num_unreads}
                        </div>
                        <button onClick={deleteSelf} className="h-min w-fit">
                            <Ping className="text-xs text-red-500 scale-150" >x</Ping>
                        </button>
                    </div>
                </a>

            </div>
        </>
    )
}
export default ChatNotificationListing;
