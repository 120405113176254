import {
    completeIcon,
    failedIcon,
    pendingIcon,
    rescheduledIcon,
    voidIcon
} from "./TimelineIcons";

const HorizontalTimelineStep = (props) => {

    const getIconAndColor = () => {
        if (props.complete) return { 
            icon: completeIcon, 
            color: "bg-mi-blue", 
            height: "h-2" 
        };
        if (props.failed) return { 
            icon: failedIcon, 
            color: "bg-mi-blue", 
            height: "h-2" 
        };
        if (props.rescheduled) return { 
            icon: rescheduledIcon, 
            color: "bg-mi-blue", 
            height: "h-2" 
        };
        if (props.pending) return { 
            icon: pendingIcon, 
            color: "bg-mi-grey", 
            height: "h-1" 
        };
        return { 
            icon: voidIcon,
            color: "bg-mi-grey", 
            height: "h-1" 
        };
    }

    const lineWidth = () => {
        if (props.isLastStep && props.complete) return "w-full";
        if (props.extendToNext) return "w-full";
        return "w-1/2";
    };

    const getRoundedClass = () => {
        if (props.isFirstStep) return "rounded-l-lg";
        if (props.isLastStep) return "rounded-r-lg";
        return "";
    };
    
    const { icon, color, height } = getIconAndColor();

    return (
        <>
            <div className={`relative flex flex-row w-full items-center justify-center`}>
                <div className="absolute flex items-center justify-center z-10 w-8 h-8 ring-1 bg-mi-grey ring-[mi-grey] rounded-full">
                    {icon}
                </div>
                <div className="w-full grid grid-cols-2">
                    {/* Base grey line */}
                    <div className="w-full h-1 my-auto rounded-xl bg-mi-grey" />
                    {/* Colored segment on top */}
                    <div className={`absolute top-0 left-0 my-auto ${color} ${height} ${lineWidth()} ${getRoundedClass()}`} />
                </div>
            </div>
        </>
    );
}
export default HorizontalTimelineStep;
