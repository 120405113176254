import { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import vector from "../assets/images/vector.svg";
import { useLocation } from "react-router-dom";
import TopBar from "./TopBar";
import whiteLogoGif from "../assets/images/whiteLogo2.svg";
import Menu from "../assets/images/menu.svg";
import SidebarContext from "./SidebarContext";
import xButton from "../assets/images/notification_x_button.svg";

const CollapsibleSidebar = (props) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' });
    const { openSidebar, setOpenSidebar } = useContext(SidebarContext);

    useEffect(() => {
        setOpenSidebar(isBigScreen);
    }, [isBigScreen]);

    const location = useLocation();
    const isLocationChat = (location.pathname.startsWith('/chat'));

    const sidebarStyle = {
        background: `${isLocationChat ? "rgba(255,255,255, 1)" : "linear-gradient(180deg, rgba(112, 150, 209, 0) 0%, rgba(112, 150, 209, 0.15) 100%)"}`,
        minHeight: '100vh',
    };

    return (
        <>
            <div className={`${openSidebar ? (isBigScreen ? `${isLocationChat ? "w-[30rem]" : "w-96"}` : "w-full bg-white") : "w-0"} flex rounded-br-2xl m-0 fixed z-20 md:static`}
                style={(isBigScreen && openSidebar) ? sidebarStyle : { minHeight: '100vh' }}>

                <div className="flex flex-col w-full relative">
                    <div className={`${openSidebar ? "w-full" : "w-[10rem]"} sm:flex hidden text-white`}>
                        {openSidebar && isBigScreen &&
                            <div className="ml-4 mt-4 flex justify-center items-center cursor-pointer hover:bg-mi-grey border rounded-lg h-8 w-8">
                                <button onClick={() => setOpenSidebar(false)}>
                                    <img src={Menu} alt="Close sidebar" className="h-6 w-full" />
                                </button>
                            </div>
                        }

                        {!openSidebar && isBigScreen &&
                            <div className="ml-4 mt-4 flex justify-center items-center cursor-pointer hover:bg-mi-grey border rounded-lg h-8 w-8">
                                <button onClick={() => setOpenSidebar(true)}>
                                    <img src={Menu} alt="Open sidebar" className="h-6 w-full" />
                                </button>
                            </div>
                        }
                        <a href="/home" className="translate-x-5">
                            {!props.noLogo && <img src={whiteLogoGif} className="scale-125" />}
                        </a>

                    </div>
                    <div className="flex flex-col overflow-clip w-full h-full">
                        {props.children}
                    </div>

                </div>
                {openSidebar && !isBigScreen &&
                    <div className="absolute top-96 right-1 transform -translate-y-1/2 cursor-pointer">
                        <button onClick={() => setOpenSidebar(false)}>
                            <img src={vector} alt="Close" className="h-6 w-6" />
                        </button>
                    </div>
                }
            </div>

            {!openSidebar && !isBigScreen &&
                <div className="absolute top-8 left-8 transform -translate-y-1/2 cursor-pointer z-30">
                    <button onClick={() => setOpenSidebar(true)}>
                        <img src={vector} alt="Open" className="h-6 w-6 rotate-180" />
                    </button>
                </div>
            }

            {/* <TopBar accountType={props.accountType} openSidebar={openSidebar} /> */}
        </>
    );
}

export default CollapsibleSidebar;