import React, { createContext, useState } from "react";
import { useMediaQuery } from "react-responsive";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' });
    const [openSidebar, setOpenSidebar] = useState(isBigScreen);

    return (
        <SidebarContext.Provider value={{ openSidebar, setOpenSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
};

export default SidebarContext;