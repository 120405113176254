import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail, EmailAuthProvider, GoogleAuthProvider } from "firebase/auth";

import Swal from "sweetalert2";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    "apiKey": "AIzaSyCiOc9WD_P1is22__7h_IoxcWPAMhrYXVI",
    "authDomain": "my-influency-dev.firebaseapp.com",
    "databaseURL": "https://my-influency-dev.firebaseio.com",
    "projectId": "my-influency-dev",
    "storageBucket": "my-influency-dev.appspot.com",
    "messagingSenderId": "636854476111",
    "appId": "1:636854476111:web:a456807e0b3009843cf93a",
    "measurementId": "G-4M2LWX8MYR"
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

// authentication
const loginConfig = ({
    signInFlow: "redirect",
    signInSuccessUrl: "/home",
    signInOptions: [
        // email & pass
        {
            provider: EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: false
        },
        // Google
        {
            provider: GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
                // Forces account selection even when one account
                // is available.
                prompt: 'select_account'
            }
        },

    ]
});

const signupConfig = ({
    signInFlow: "redirect",
    signInSuccessUrl: "/signup",
    signInOptions: [
        // email & pass
        {
            provider: EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: false
        },
        // Google
        {
            provider: GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
                // Forces account selection even when one account
                // is available.
                prompt: 'select_account'
            }
        },
    ]
});

const sendPasswordRecoveryEmail = (email) => {
    sendPasswordResetEmail(auth, email)
        .then(() => {
            Swal.fire({
                icon: "success",
                title: "Done!",
                text: "We sent you an email to recover your password!",
                confirmButtonColor: "#7BBA83",
            }).then(() => window.location.href = "/signin");
        })
        .catch((error) => {
            let message = null;
            if (error.code === "auth/invalid-email") {
                message = "You must enter a vaild email address!";
            } else if (error.code === "auth/user-not-found") {
                message = "We couldn't find an account with that email! :(";
            }
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: message,
                confirmButtonColor: "#7BBA83",
            });
        });
}

export {
    loginConfig,
    signupConfig,
    auth,
    firestore,
    sendPasswordRecoveryEmail,
}