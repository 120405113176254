import { useNavigate } from "react-router-dom";
import searchIcon from "../assets/images/search_icon.svg";

const TWSearch = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="relative w-11/12">
                <div className="flex absolute inset-y-0 right-0 items-center pl-3 pointer-events-none">
                    <img src={searchIcon} alt="Search" className="h-full w-full" />
                </div>
                <input type="text" className={`bg-white border text-gray-900 text-sm rounded-full ${(props.shadow) ? "" : "shadow-all-xl"} block w-full pl-5 p-2 placeholder-mi-action-blue`} placeholder={props.children} required
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            if (e.target.value) {
                                navigate(`/${props.url}/${e.target.value}`);
                            } else {
                                if (!props.curr) {
                                    window.location.reload(false);
                                } else {
                                    navigate(`/${props.url}`);
                                }
                            }
                        }
                    }}></input>
            </div>

        </>
    );

}
export default TWSearch;
