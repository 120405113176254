import { isoToDate } from "../../dateUtils";
import { useState } from "react";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { Rating } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ProfilePic from "../ProfilePic";
import TWButton from "../TWButton";
import HorizontalTimeline from "../timeline/HorizontalTimeline";

const BusinessTimelineCard = (props) => {
    const [rating, setRating] = useState(props.data.rating);
    //const [tosAccept, setTosAccept] = useState(false);
    const csrf_token = Cookies.get("csrftoken");

    const confirmPickupWrapper = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "By confirming pickup, you are confirming that the influencer has picked up your product provided for content creation and/or visited your place of business as intended for your campaign. This action cannot be undone.",
            showCancelButton: true,
            confirmButtonColor: "#7BBA83",
            cancelButtonColor: "#ef4444",
            confirmButtonText: "Yes, confirm pickup!"
        }).then((result) => {
            if (result.isConfirmed) {
                props.confirmPickup();
            }
        });
    }

    // helper function to detect when a new rating is inputted
    const rate = (newRating) => {
        if (newRating !== rating) {
            create_rating(newRating);
            setRating(newRating);
        }
    }

    // function to send a new rating to the backend
    const create_rating = async (newRating) => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/create_rating`;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                rating: newRating,
                contractID: props.data.contract_id,
            }),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Done!",
                            text: "Successfully updated rating!",
                            confirmButtonColor: "#7BBA83",
                        });
                    };
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    return (
        <>
            {(props.data.status === "Current" || props.data.status === "Fullfilled")
                ? <h2>Contract Timeline</h2>
                : <h2 className="font-bold text-neutral-950 text-xl font-poppins">Proposal Timeline</h2>
            }
            <div className="w-full bg-white rounded-3xl gap-2 md:overflow-y-auto md:scrollbar p-8 border drop-shadow-lg">
                <div className="w-full flex flex-row px-8 mb-8 items-center gap-6">
                    <div className="w-[75px]">
                        <ProfilePic border image={props.data.partner.profile_picture} />
                    </div>
                    <div className="flex flex-col">
                        <p className="font-bold text-neutral-950 font-poppins">{props.data.partner.name}</p>
                        <p className="text-xs font-bold text-neutral-500">{props.data.category}</p>
                    </div>
                </div>
                <div className="flex flex-col my-4 md:w-[95%] ml-10">
                    <HorizontalTimeline data={props.data} step={props.step} />
                </div>
                <div className="grid grid-cols-2 px-4 md:px-8 py-4 gap-4">
                    <div>
                        <h2 className="w-full mb-4 text-left text-mi-blue ">
                            {(props.step === 1 && props.data.updated === null)
                                ? "Proposal Sent!"
                                :(props.step === 1 && props.data.updated !== null)
                                    ? <div className="flex flex-col">"Proposal Sent!"<div><span className="text-neutral-600 text-xs font-normal font-['Poppins']">Congratulations! You just sent an proposal to teearaida.<br/>The influencer has 48 hours to respond. This offer expires on </span><span className="text-neutral-600 text-xs font-bold font-['Poppins']">{isoToDate(props.data.accept_expiration_date)}</span></div></div>
                                    : props.step === 1.75
                                        ? "Proposal Declined!"
                                        : props.step === 2
                                            ? "Proposal Accepted!"
                                            : props.step === 2.75
                                                ? "Contract Expired: Payment Failed"
                                                : props.step === 3
                                                    ? `Product Pickup: ${isoToDate(props.data.visit_date)}`
                                                    : props.step === 3.5
                                                        ? <>
                                                            Product Pickup: <a className="text-amber-500">{isoToDate(props.data.amendment_approved[0].new_visit_date)}</a>
                                                        </>
                                                        : props.step === 3.8
                                                            ? <>
                                                                Product Pickup: <a className="text-red-500">{isoToDate(props.data.visit_date)}</a>
                                                            </>
                                                            : props.step === 3.9
                                                                ? <>
                                                                    Product Pickup: <a className="text-red-500">{isoToDate(props.data.amendment_approved[0].new_visit_date)}</a>
                                                                </>
                                                                : props.step === 4
                                                                    ? "Awaiting Post!"
                                                                    : props.step === 4.5
                                                                        ? "Post Rescheduled"
                                                                        : (props.step === 4.75 || props.step === 4.9)
                                                                            ? "Post Overdue"
                                                                            : props.step === 5
                                                                                ? "Post Published!"
                                                                                : "" // should never reach
                            }
                        </h2>
                        {props.step === 1
                            ? <></>
                            : props.step === 1.75
                                ? <>
                                    <p className="text-gray-600 font-poppins mb-4">
                                        Unfortunately, they weren't a good match. Go explore more!
                                    </p>
                                    <p className="text-gray-600 font-poppins">
                                        Decline reason: {props.data.cancellation_reason}
                                    </p>
                                </>
                                : props.step === 2
                                    ? <>
                                        <p className="text-gray-600 font-poppins mb-4">
                                            This contract will expire on <b>{isoToDate(props.data.pay_expiration_date)}</b>
                                        </p>
                                        <p className="text-gray-600 font-poppins">
                                            If you fail to complete payment to the platform before the above expiration date, this contract is null and void.
                                        </p>
                                    </>
                                    : props.step === 2.75
                                        ? <>
                                            <p className="text-gray-600 font-poppins mb-4">
                                                This contract expired on <b>{isoToDate(props.data.pay_expiration_date)}</b>
                                            </p>
                                        </>
                                        : (props.step === 3 || props.step === 3.5 || props.step === 3.8 || props.step === 3.9)
                                            ? <>
                                                <p className="text-gray-600 font-poppins">{props.data.address.line_1}</p>
                                                {props.data.address.line_2 ? <p className="text-gray-600 font-poppins">{props.data.address.line_2}</p> : <></>}
                                                {props.data.address.line_3 ? <p className="text-gray-600 font-poppins">{props.data.address.line_3}</p> : <></>}
                                                <p className="text-gray-600 font-poppins">{props.data.address.city}, {props.data.address.state} {props.data.address.zip_code}</p>
                                            </>
                                            : (props.step === 4 || props.step === 4.5)
                                                ? <p className="text-gray-600 font-poppins mb-4">
                                                    Please allow the influencer a few days to curate the perfect post for your product. You will be notified of any updates.
                                                </p>
                                                : (props.step === 4.75 || props.step === 4.9)
                                                    ? <p className="text-gray-600 font-poppins mb-4">
                                                        Please communicate with the influencer and file a claim if necessary. You will be notified of any updates.
                                                    </p>
                                                    : props.step === 5
                                                        ? <>
                                                            <p className="text-gray-600 font-poppins mb-4">
                                                                Congratulations! The influencer has published the final post!
                                                            </p>
                                                            <a href={props.data.post.permalink} className="text-mi-green font-semibold font-poppins underline">
                                                                Click here to view post
                                                            </a>
                                                        </>
                                                        : "" // should never reach
                        }
                    </div>
                    {(props.step >= 3 && props.step < 4) &&
                        <div>
                            <p className="w-full mb-4 text-left text-slate-700 text-lg font-semibold Dosis">
                                Product Included: {props.data.campaign.product_name}
                            </p>
                            <p className="text-gray-600 font-poppins">
                                {props.data.campaign.product_description}
                            </p>
                        </div>
                    }
                    {(props.step === 5) &&
                        <div className="flex flex-col w-full h-full justify-end items-end">
                            <p className="text-gray-600 font-poppins">Rate this Influencer</p>
                            <Rating
                                name="simple-controlled"
                                value={parseInt(rating)}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        rate(newValue);
                                    }
                                }}
                            />
                        </div>
                    }
                </div>
                {(!props.data.visit_confirmed_business && props.step >= 3 && props.step < 4) &&
                    <div className="w-full flex flex-row justify-end items-center p-4">
                        <div className="w-1/6 relative">
                            <TWButton sm onClick={confirmPickupWrapper} >
                                <div className="flex flex-row gap-2 items-center">
                                    <CheckRoundedIcon className="text-white" />
                                    Confirm Successful Pickup
                                </div>
                            </TWButton>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
export default BusinessTimelineCard;