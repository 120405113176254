import React, { useState, useEffect, useMemo } from 'react';
import '../campaign/custom-calendar.css';
import CalendarComponentInfluencer from "../campaign/CalendarInfluencer";
import Modal from "../Modal";
import { format } from 'date-fns';
import TWButton from '../TWButton';
import Swal from "sweetalert2";

const DatesModal = ({ setShowModal, onDateSelected, onTimeSelected, contractID, time, setTz, step, minDate, maxDate, dateRange, unavailableDates, busHr, timezone, visitDate, submissionDate }) => {
    const [date, setDate] = useState(null);
    const [visitTime, setVisitTime] = useState("");
    const [timeRange, setTimeRange] = useState([]);

    const onDateChange = (date) => {
        setDate(date);
        onDateSelected(date);
    };

    const isSameDay = (d1, d2) => {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    };

    const isUnavailableDate = (date) => {
        return unavailableDates.some(unavailableDate =>
            isSameDay(date, new Date(unavailableDate))
        );
    };

    const handleTimeSelection = (newTime) => {
        setVisitTime(newTime);
        onTimeSelected(newTime);
    };

    const formatTime = (hour, minute) => {
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        const formattedMinute = minute.toString().padStart(2, '0');
        return `${formattedHour}:${formattedMinute} ${period}`;
    };

    const generateTimeRange = (day) => {
        const daySchedule = busHr[day];
        if (!daySchedule) {
            return [];
        }

        const startHour = parseInt(daySchedule.start.split(':')[0], 10);
        const endHour = parseInt(daySchedule.end.split(':')[0], 10);
        const times = [];

        for (let hour = startHour; hour <= endHour; hour++) {
            times.push(formatTime(hour, 0));
        }

        return times;
    };

    const day = useMemo(() => {
        if (!date || !(date instanceof Date)) return null;
        return format(date, 'EEEE').toLowerCase();
    }, [date]);

    const formattedDate = useMemo(() => {
        if (!date || !(date instanceof Date)) return '';
        return format(date, 'PPPP'); // Formats the date in a long format, e.g., "Thursday, May 23, 2024"
    }, [date]);

    useEffect(() => {
        if (day && time === true) {
            const times = generateTimeRange(day);
            setTimeRange(times);
        }
    }, [day, busHr, time]);

    const handleSubmit = () => {
        if (!date || (time && !visitTime)) {
            Swal.fire({
                icon: "error",
                title: "Missing information",
                text: "Please select both a date and a time if required.",
                confirmButtonColor: "#7BBA83",
            });
            return;
        }
        onDateSelected(date);
        if (time) {
            onTimeSelected(visitTime);
        }
        setShowModal(false);
    };

    const getHeaderText = (step) => {
        switch (step) {
            case 1:
                return "Select Date: Visit Date";
            case 2:
                return "Select Date: Submission Date";
            case 3:
                return "Reschedule Date: Visit Date";
            case 4:
                return "Reschedule Date: Submission Date";
            default:
                return "Select Date";
        }
    };

    return (
        <>
            <Modal setShowModal={setShowModal}>
                <div className="p-4">
                    <div className="flex flex-row mt-6 mb-4 items-center w-full border-b border-solid border-slate-200">
                        <h2 className="flex-auto mb-4">
                            {getHeaderText(step)}
                        </h2>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mx-3 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white fixed top-8 right-4 z-40" data-modal-hide="defaultModal" onClick={() => setShowModal(false)} >
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    <div className="flex flex-row mb-8 items-center w-full justify-between">
                        <h3 className="text-neutral-600 font-semibold font-['Poppins']">Visiting Hours</h3>
                        <h3 className="text-neutral-600 font-semibold font-['Poppins']">Timezone: {timezone}</h3>
                    </div>
                    <div className='mb-8'>
                        <h3>Selected Date: {formattedDate}</h3>
                    </div>
                    <div className="flex flex-col md:flex-row gap-12 mb-8">
                        <CalendarComponentInfluencer
                            onDateChange={onDateChange}
                            isSameDay={isSameDay}
                            isUnavailableDate={isUnavailableDate}
                            selectSingleDate={true}
                            dateRange={dateRange}
                            minDate={minDate}
                            maxDate={maxDate}
                            visitDate={visitDate}
                            submissionDate={submissionDate}
                        />
                        {timeRange && time === true ?
                            <div className='flex flex-col'>
                                <h3>Availability for {day ? day.charAt(0).toUpperCase() + day.slice(1) : ''} {timezone}:</h3>
                                <div className="grid grid-cols-2 justify-center items-center gap-2.5 p-2 w-96">
                                    {timeRange.map((time, index) => (
                                        <button
                                            key={index}
                                            className={`h-8 px-7 py-1.5 ${visitTime === time ? 'bg-mi-blue text-white' : 'bg-slate-200 text-mi-blue'} rounded-md border border-mi-blue border-opacity-40 justify-center items-center flex`}
                                            onClick={() => handleTimeSelection(time)}
                                        >
                                            <div className="text-sm font-semibold font-poppins">{time}</div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                            : <></>}

                    </div>
                    <div className="flex justify-end">
                        <TWButton md onClick={handleSubmit}>
                            Submit
                        </TWButton>
                    </div>
                </div>
            </Modal >
        </>
    );
};

export default DatesModal;
