import { auth } from "./firebase";

import Cookies from "js-cookie";
import Swal from "sweetalert2";

const csrf_token = Cookies.get("csrftoken");

const checkout = async (contractID) => {
    const token = await auth.currentUser.getIdToken();
    const url = `${process.env.REACT_APP_DJANGO_API}/checkout/${contractID}`;
    const requestMetaData = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrf_token,
            "Authorization": `Bearer ${token}`,
        },
    };
    await fetch(url, requestMetaData)
        .then(res => res.json())
        .then((result) => {
            if (!result || !result.success) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    confirmButtonColor: "#7BBA83",
                    footer: "Please try again later.",
                });
            } else {
                window.open(result["data"]["url"], "_self");
            }
        });
}

export {
    checkout,
}
