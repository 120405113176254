const TWSelect = (props) => {
    return (
        <>
            {props.whitebox ?
                <select disabled={props.disabled}
                    defaultValue={props.defaultValue}
                    value={props.value}
                    onChange={props.onChange}
                    className={`w-full p-2 rounded-lg border ${props.shadow ? "shadow-all-lg" : ""} text-gray-800`}>
                    {props.children}
                </select> 
                :
                <select disabled={props.disabled}
                    defaultValue={props.defaultValue}
                    value={props.value}
                    onChange={props.onChange}
                    className={`rounded-xl w-full p-1 ${(props.lightblue) ? "bg-mi-light-blue" : "bg-indigo-50"} text-gray-700 p-2 ${props.shadow ? "shadow-lg" : ""} disabled:cursor-not-allowed disabled:opacity-50`}>
                    {props.children}
                </select>
            }
        </>

    );
}
export default TWSelect;