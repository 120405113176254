import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import TWButton from "../TWButton";
import whiteLogoGif from "../../assets/images/whiteLogoGif.gif";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const Thankyou = (props) => {

    const [accessKey, setAccessKey] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const csrf_token = Cookies.get("csrftoken");

    // check authentication
    useEffect(() => {
        // authentication loading
        if (loading) {
            return;
        }
        // user is logged into an account => set timezone & check account status
        if (user) {
            const checkAuth = async () => {
                const token = await auth.currentUser.getIdToken();
                const url = `${process.env.REACT_APP_DJANGO_API}/check_user`;
                const requestMetaData = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf_token,
                        "Authorization": `Bearer ${token}`,
                    },
                };
                fetch(url, requestMetaData)
                    .then(res => res.json())
                    .then((result) => {
                        if (!result || !result.success) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                        } else {
                            const signupStep = result.data.step;
                            const accountType = result.data.user_type;
                            props.setAccountType(accountType);
                            if (!accountType || (signupStep !== 4 && accountType === "Influencer") || (signupStep !== 5 && (accountType === "Business" || accountType === "Agency"))) { navigate("/signup"); }
                            else if (result.data.access) { navigate("/home"); }
                        }
                    });
            }
            checkAuth();
            // no user logged in => redirect to signin screen
        } else {
            navigate("/signin");
        }
    }, [user, loading, error]);

    const addAccessKey = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/bind_access_key`;
        const requestMetaData = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ accessKey: accessKey }),
        };
        fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: result.data,
                    });
                } else {
                    navigate(`/home`);
                }
            });
    }

    return (
        <>
            <Helmet>
                <title>
                    MyInfluency | Thank you
                </title>
            </Helmet>
            <div className="flex flex-col h-screen w-screen justify-center items-center bg-gradient-to-br from-mi-blue to-mi-green">
                <div className="px-10 md:w-1/3">
                    <img alt="MyInfluency Logo" src={whiteLogoGif} className="object-scale-down" />
                </div>
                <div className="m-3 border-2 border-solid border-white rounded-xl">
                    <div className="flex flex-col gap-2 rounded-xl w-fit p-4">
                        <h2 className="text-center text-3xl text-white font-semibold p-1">Thank You for Signing Up!</h2>
                        <div className="text-white md:text-center">
                            <p>Thanks for signing up to My Influency! It appears you did not enter an access key when you signed up.</p>
                            <br className="md:hidden" />
                            <p>At this time, we are running a closed-beta launch, but we will be opening up the site to all users soon!</p>
                            <br className="md:hidden" />
                            <p>If you have an access key, you can enter it below:</p>
                        </div>

                    </div>

                </div>
                <div className="flex flex-row gap-2 w-full px-8 md:w-1/3">
                    <input className="flex-auto p-2 rounded-lg" type="text" placeholder="Please enter your access key" onChange={e => setAccessKey(e.target.value)} />
                    <div className="w-1/3">
                        <TWButton onClick={addAccessKey}>
                            Submit
                        </TWButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Thankyou;