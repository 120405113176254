import { useEffect, useState, useRef } from "react";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Chart from 'chart.js/auto';
import mouseClick from "../../assets/images/mouseClick.png";
import community from "../../assets/images/community.png";
import chatMoney from "../../assets/images/chatMoney.png";
import { isoToMonthDate } from "../../dateUtils";

const OverviewPage = (props) => {
    const csrf_token = Cookies.get("csrftoken");
    const [viewData, setViewData] = useState(null);
    const [engageData, setEngageData] = useState(null);
    const [impressions, setImpressions] = useState(0);
    const [engagement, setEngagement] = useState(0);
    const [milli, setMilli] = useState(0);


    let viewChart = useRef(null);
    let engageChart = useRef(null);
    const view_line = useRef(null);
    const milli_line = useRef(null);

    const createGradient = (ctx, chartArea) => {
        if (!chartArea) {
            console.warn("chartArea is not available!");
            return "rgba(114, 168, 218, 0.2)";
        }

        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, "rgba(114, 168, 218, 0.2)");
        gradient.addColorStop(0, "rgba(255, 255, 255, 1)");

        return gradient;
    };


    const engageOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false
            },
            axis: {
                x: {
                    type: 'timeseries',
                    label: 'TimeStamps',

                    show: false
                },
                y: {
                    label: 'Engagement',
                    display: false,
                },
            },
            beforeUpdate: chart => {
                const gradient = createGradient(chart.ctx, chart.chartArea);
                chart.data.datasets[0].backgroundColor = gradient;
            },
        },
        scales: {
            x: {
                grid: { 
                    display: false,
                    drawBorder: false,
                    drawOnChartArea: false, 
                    drawTicks: false, 
                },
                ticks: {
                    display: true,
                    padding: 20,
                }
            },
            y: {
                grid: { 
                    display: true,
                    drawBorder: false,
                    drawTicks: false,
                },
                ticks: {
                    display: true
                }
            }
        }
    };

    const impressionOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false,
            },
            axis: {
                x: {
                    type: 'timeseries',
                    label: 'TimeStamps',
                    show: false
                },
                y: {
                    label: 'Impressions',
                    display: false,
                },
            },
            beforeUpdate: chart => {
                const gradient = createGradient(chart.ctx, chart.chartArea);
                chart.data.datasets[0].backgroundColor = gradient;
            },
        },
        scales: {
            x: {
                grid: { 
                    display: false,
                    drawBorder: false,
                    drawOnChartArea: false, 
                    drawTicks: false, 
                },
                ticks: {
                    display: true,
                    padding: 20,
                }
            },
            y: {
                grid: { 
                    display: true,
                    drawBorder: false,
                    drawTicks: false,
                },
                ticks: {
                    display: true
                }
            }
        }
    };

    // function to set livefeed if contracts are provided
    useEffect(() => {
        // if (props.contracts) {
        //     setLivefeed(props.contracts.map((contract, index) => {
        //         const data = {
        //             ig_handle: contract.partner_ig_handle,
        //             likes: contract.post.like_count,
        //             comments: contract.post.comment_count,
        //         };
        //         return <LivefeedListing key={index} data={data} />;
        //     }));
        // }
        /*const testData = {
            data: {
                "timestamps": [
                    "2023-6-12","2023-6-20","2023-7-1", "2023-7-15", "2023-7-30"
                ],
                "engagement": [5,10,15,20,50],
                "impressions": [11,53,60,150,200],
                "campaign_cost": 5000
            }
        }*/
        const get_business_campaign_insights = async () => {
            const token = await auth.currentUser.getIdToken();
            const url = `${process.env.REACT_APP_DJANGO_API}/get_business_campaign_insights/${props.data.id}`;
            const requestMetaData = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrf_token,
                    "Authorization": `Bearer ${token}`,
                },
            };
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        let added_impressions = {};
                        added_impressions = result.data.impressions.map((elem, index) => result.data.impressions.slice(0, index + 1).reduce((a, b) => a + b));
                        setViewData({
                            labels: Object.keys(result.data.timestamps).map(key => isoToMonthDate(result.data.timestamps[key].slice(result.data.timestamps[key].indexOf('-') - 4))),
                            datasets: [
                                {
                                    label: 'Total Impressions',
                                    data: Object.keys(added_impressions).map(key => added_impressions[key]),
                                    fill: true,
                                    backgroundColor: (context) => createGradient(context.chart.ctx, context.chart.chartArea),
                                    borderColor: '#72A8DA',
                                    borderWidth: 2,
                                    tension: 0.4,
                                    pointBackgroundColor: 'blue',
                                    pointBorderColor: 'black',
                                },
                            ],
                        });
                        let added_engagement = {};
                        added_engagement = result.data.engagement.map((elem, index) => result.data.engagement.slice(0, index + 1).reduce((a, b) => a + b));
                        setEngageData({
                            labels: Object.keys(result.data.timestamps).map(key => isoToMonthDate(result.data.timestamps[key].slice(result.data.timestamps[key].indexOf('-') - 4))),
                            datasets: [
                                {
                                    label: 'Total Engagement',
                                    data: Object.keys(added_engagement).map(key => added_engagement[key]),
                                    fill: true,
                                    backgroundColor: (context) => createGradient(context.chart.ctx, context.chart.chartArea),
                                    borderColor: '#72A8DA',
                                    borderWidth: 2,
                                    tension: 0.4,
                                    pointBackgroundColor: 'blue',
                                    pointBorderColor: 'black',
                                },
                            ],
                        });
                        const total_impressions = result.data.impressions.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                        if (total_impressions !== 0) {
                            setMilli(Math.round(result.data.campaign_cost / total_impressions));
                        }
                        else {
                            setMilli(0);
                        }
                        setImpressions(total_impressions);
                        setEngagement(result.data.engagement.reduce((previousValue, currentValue) => previousValue + currentValue, 0));
                    } // comment out for test
                }); // comment out for test
        } // comment out for test
        get_business_campaign_insights(); // comment out for test
    }, []);

    useEffect(() => {
        const renderViewChart = () => {
            if (viewData) {
                if (viewChart.current) viewChart.current.destroy(); // destroy existing chart
                viewChart.current = new Chart(view_line.current, {
                    type: "line",
                    options: engageOptions,
                    data: engageData,
                    height: "600px",
                });
                const gradient = createGradient(viewChart.current.ctx, viewChart.current.chartArea);
                viewChart.current.data.datasets[0].backgroundColor = gradient;
                viewChart.current.update();
            }
        };
        const renderEngageChart = () => {
            if (viewData) {
                if (engageChart.current) engageChart.current.destroy(); // destroy existing chart
                engageChart.current = new Chart(milli_line.current, {
                    type: "line",
                    options: impressionOptions,
                    data: viewData,
                    height: "600px",
                });
                const gradient = createGradient(engageChart.current.ctx, engageChart.current.chartArea);
                engageChart.current.data.datasets[0].backgroundColor = gradient;
                engageChart.current.update();
            }
        };
        renderViewChart();
        renderEngageChart();
        return () => {
            if (viewChart.current) viewChart.current.destroy();
            if (engageChart.current) engageChart.current.destroy();
        };
    }, [viewData, engageData]);

    return (
        <>
            {/* GRAPH & LIVEFEED */}
            <div className="flex flex-col md:overflow-auto gap-2 pt-2 w-full">
                {/* PERFORMANCE GRAPH */}
                <div className="flex flex-col gap-2 w-full">
                    <div className="text-neutral-800 text-lg font-bold font-poppins text-left">Summary Stats</div>

                    <div className="flex flex-wrap justify-center gap-4 md:gap-10 mt-4">
                        <div className="w-full h-20 md:w-auto md:flex-grow bg-indigo-400 bg-opacity-20 rounded-2xl flex items-center justify-center px-5 gap-5">
                            <img src={mouseClick} alt="Mouse Click" className="h-3/4 bg-indigo-400 bg-opacity-50 rounded-full" />
                            <div>
                                <div className="text-indigo-400 text-3xl font-bold font-poppins">{impressions}</div>
                                <div className="text-neutral-600 text-base font-semibold font-poppins leading-none">Total Impressions</div>
                            </div>
                        </div>

                        <div className="flex-grow h-20 bg-amber-300 bg-opacity-20 rounded-2xl flex items-center justify-center px-5 gap-5">
                            <img src={community} alt="Community" className="h-3/4 bg-amber-300 bg-opacity-40 rounded-full" />
                            <div>
                                <div className="text-amber-300 text-3xl font-bold font-poppins">{engagement}</div>
                                <div className="text-neutral-600 text-base font-semibold font-poppins leading-none">Total Engagement</div>
                            </div>
                        </div>

                        <div className="flex-grow h-20 bg-mi-green bg-opacity-30 rounded-2xl flex items-center justify-center px-5 gap-5">
                            <img src={chatMoney} alt="Chat Money" className="h-3/4 bg-neutral-400 bg-opacity-40 rounded-full" />
                            <div>
                                <div className="text-mi-green text-3xl font-bold font-poppins">${milli}</div>
                                <div className="text-neutral-600 text-base font-semibold font-poppins leading-none">Cost Per Mille</div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="flex flex-col w-full md:flex-auto md:flex-row overflow-auto justify-around gap-4 pb-1">
                    <div className="w-full grid grid-cols-1 grow mt-8">
                        <div className="text-neutral-800 text-base md:text-lg font-bold font-poppins text-left">
                            Total Impressions
                        </div>
                        <div className="divide-y bg-white rounded-xl drop-shadow-xl border m-4 p-4 md:p-8 h-auto md:h-[450px]">
                            <canvas ref={milli_line}></canvas>
                        </div>
                        <div className="text-neutral-800 text-base md:text-lg font-bold font-poppins text-left mt-8">
                            Total Engagement
                        </div>
                        <div className="divide-y bg-white rounded-xl drop-shadow-xl border m-4 p-4 md:p-8 h-auto md:h-[450px]">
                            <canvas ref={view_line}></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OverviewPage;
