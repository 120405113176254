import Modal from "../../Modal";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import VisitDetailCard from "./VisitDetailCard";
import { auth } from "../../../firebase";

const VisitDetailModal = (props) => {
    const [addressList, setAddressList] = useState();

    const csrf_token = Cookies.get("csrftoken");

    const getEditDetails = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_edit_details/${props.contractID}`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        // generate data
                        setAddressList(result.data.address_list)
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }
    useEffect(() => {
        getEditDetails();
    },[]);



    return (
        <>
            <Modal setShowModal={props.setShowModal}>
                { addressList ? <VisitDetailCard data={props.data} addressList={addressList} setAddressID={props.setAddressID} setAddressLoc={props.setAddressLoc} setProdDes={props.setProdDes} setProdName={props.setProdName} setShowModal={props.setShowModal} /> :<></>}
            </Modal>
        </>
    );
}
export default VisitDetailModal;
