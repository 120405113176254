import React from 'react';

const VideoHeader = (props) => {
    return (
        <div className='flex flex-auto justify-center absolute w-full text-white p-3'>
            {props.post_type[0] === 'R' && (
                <>Reels</>
            )}
            {props.post_type[0] === 'S' && (
                <>Stories</>
            )}
        </div>
    )
}
export default VideoHeader;