import { auth } from "../../firebase";
import { isoToDate } from "../../dateUtils";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import TWButton from "../TWButton";

const Upgrade = ({ data }) => {
    const csrf_token = Cookies.get("csrftoken");

    const isPremium = "premium_info" in data;

    const checkout_subscription = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/upgrade_account`;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ "subscription_id": 1 }),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    window.open(result["data"]["url"], "_self");
                }
            });
    }

    const manage_subscription = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/manage_subscription`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    window.open(result["data"], "_self");
                }
            });
    }

    return (
        <div className="flex flex-col gap-10 py-8 px-10 md:px-40">
            <h3 className="md:w-[20%] font-bold text-2xl text-gray-600">Upgrade Account</h3>
            <p className="font-semibold text-gray-500">
                {isPremium
                    ? `You are on a premium agency plan! Your plan expires on: ${isoToDate(data.premium_info.expire_date)}`
                    : "Upgrade your account to enable full the full agency suite of functionality including full control over contract dates and sending proposals with custom price proposals!"
                }
            </p>
            <div className="w-full flex flex-row justify-end">
                <div className="md:w-1/6">
                    <TWButton onClick={isPremium ? manage_subscription : checkout_subscription}>
                        {isPremium ? "Manage Subscription" : "Upgrade Account"}
                    </TWButton>
                </div>
            </div>
        </div>
    );
}
export default Upgrade;