import { useRef, useState, useEffect, useLayoutEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

// components
import TWButton from "../TWButton";
import TWDropDown from "../TWDropDown";
import CollapsibleSidebar from "../CollapsibleSidebar";
import CampaignListing from "./CampaignListing";
import CreateCampaignModal from "./CreateCampaignModal";
import Spinner from "../Spinner";
import OverviewPage from "./OverviewPage";
import TrackingPage from "./TrackingPage";
import ContentPage from "./ContentPage";
import AboutPage from "./AboutPage";
import TopBar from "../TopBar";
import CampaignListingMobile from "../campaign/CampaignListingMobile";
import whiteLogoGif from "../../assets/images/whiteLogo.svg";
import campaignEmptyState from "../../assets/images/campaign_empty_state.svg";

const Campaign = (props) => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

    // modal states
    const [createModal, setCreateModal] = useState(false);

    // filter/sorting states
    const [typeFilter, setTypeFilter] = useState("active");

    // sidebar content states
    const [activeCampaigns, setActiveCampaigns] = useState([]);
    const [inactiveCampaigns, setInactiveCampaigns] = useState([]);
    const [retiredCampaigns, setRetiredCampaigns] = useState([]);
    const [clientName, setClientName] = useState("");
    const [clientPhone, setClientPhone] = useState("");

    const curCampaignIdRef = useRef(null);

    // main content states
    const [curCampaign, setCurCampaign] = useState(null);
    const [tab, setTab] = useState("tracking");
    const [loading, setLoading] = useState(false);

    const csrf_token = Cookies.get("csrftoken");

    const getActiveCampaigns = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_active_campaigns`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate active list
                    setActiveCampaigns(result.data);
                }
            });
    }

    function handleCampaignClick(campaign) {
        curCampaignIdRef.current = campaign.id;
        setCurCampaign(campaign);
        if (campaign.client_info && campaign.client_info.length > 0) {
            setClientName(campaign.client_info[0].name)
            setClientPhone(campaign.client_info[0].number)
        }
    }

    function onClientPhoneChange(newPhone) {
        setClientPhone(newPhone);
        if (curCampaign && curCampaign.client_info && curCampaign.client_info.length > 0) {
            const updatedCampaign = { ...curCampaign }; // Clone to ensure immutability
            updatedCampaign.client_info[0].number = newPhone; // Update the phone number in the cloned object
            setCurCampaign(updatedCampaign); // Set the updated campaign object back to state
        }
    }

    // function to get old campaigns if user enables it
    const getOldCampaigns = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_old_campaigns`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // For inactive campaigns
                    if (result.data && result.data.inactive) {
                        setInactiveCampaigns(result.data.inactive)
                    }

                    if (result.data && result.data.retired) {
                        setRetiredCampaigns(result.data.retired)
                    }
                }
            });
    }

    // function to get search query or all active campaigns on page load
    useEffect(() => {
        getActiveCampaigns();
    }, []);

    // function to detect when user enables old campaigns (handles filter-by change)
    useEffect(() => {
        if ((typeFilter === "all" || typeFilter === "draft" || typeFilter === "retired") && (!retiredCampaigns.length || !inactiveCampaigns.length)) {
            getOldCampaigns();
        }
    }, [typeFilter]);

    // function to clear tab content when current campaign changes
    useLayoutEffect(() => {
    }, [curCampaign])

    // helper function to retrieve data from backend if needed when user changes tab
    useEffect(() => {
        if (curCampaign) {
            switch (tab) {
                case "overview":    // analytics page
                    break;
                case "tracking":
                    break;
                case "content":
                    break;
                case "info":
                    break;
                default:
                    break;
            }
        }
    }, [tab]);

    // helper function to retrieve data from backend if needed when user changes tab
    useEffect(() => {
        if (loading && curCampaign) {
            switch (tab) {
                case "overview":    // analytics page
                    setLoading(false);
                    break;
                case "tracking":
                    setLoading(false);
                    break;
                case "content":
                    setLoading(false);
                    break;
                case "info":
                    setLoading(false);
                    break;
                default:
                    break;

            }
        }
    }, [loading]);

    return (
        <>
            <div className="h-full w-full flex flex-row">

                {/* sidebar */}
                <CollapsibleSidebar open>

                    <div className={`${isSmallScreen ? 'hidden' : 'block'}`}>
                        {/* to create new campaigns */}
                        {/* <a href="/home">
                            <div className="w-[10rem] flex-shrink-0 text-white">
                                <img src={whiteLogoGif} alt="My Influency" className="object-scale-down pt-1" />
                            </div>
                        </a> */}
                        <div className="flex flex-col gap-8 pt-8 p-4 mx-2 h-full">

                            {/* to create new campaigns */}
                            <div className="px-2">
                                <TWButton onClick={() => setCreateModal(true)}>
                                    + New Campaign
                                </TWButton>
                            </div>


                            {/* Sort filters */}
                            <div className="flex flex-row gap-2 items-center px-2">
                                <div className="flex-auto items-center">
                                    <TWDropDown
                                        lg
                                        onChange={setTypeFilter}
                                        data={
                                            [
                                                { value: "all", label: "All" },
                                                { value: "active", label: "Active" },
                                                { value: "draft", label: "Draft" },
                                                { value: "retired", label: "Concluded" },
                                            ]
                                        }
                                    >Filter By Type</TWDropDown>
                                </div>
                            </div>

                            {/* CAMPAIGN LIST */}
                            <div className="flex-auto overflow-y-auto md:scrollbar py-4">
                                {
                                    (typeFilter === "all")
                                        ? <>
                                            {activeCampaigns.map(campaign =>
                                                <CampaignListing
                                                    key={campaign.id}
                                                    data={campaign}
                                                    selected={campaign.id === curCampaignIdRef.current}
                                                    onClick={() => handleCampaignClick(campaign)}
                                                />
                                            )}
                                            {inactiveCampaigns.map(contract =>
                                                <CampaignListing
                                                    key={contract.id}
                                                    data={contract}
                                                    onClick={setCurCampaign}
                                                />
                                            )}
                                            {retiredCampaigns.map(contract =>
                                                <CampaignListing
                                                    key={contract.id}
                                                    data={contract}
                                                    onClick={setCurCampaign}
                                                />
                                            )}
                                        </>
                                        : (typeFilter === "active")
                                            ? <div className="cursor-pointer">
                                                {activeCampaigns.map(campaign =>
                                                    <CampaignListing
                                                        key={campaign.id}
                                                        data={campaign}
                                                        selected={campaign.id === curCampaignIdRef.current}
                                                        onClick={() => handleCampaignClick(campaign)}
                                                    />
                                                )}
                                            </div>
                                            : (typeFilter === "draft")
                                                ? <div>
                                                    {inactiveCampaigns.map(contract =>
                                                        <CampaignListing
                                                            key={contract.id}
                                                            data={contract}
                                                            onClick={setCurCampaign}
                                                        />
                                                    )}
                                                </div>
                                                : (typeFilter === "retired")
                                                    ? <div>
                                                        {retiredCampaigns.map(contract =>
                                                            <CampaignListing
                                                                key={contract.id}
                                                                data={contract}
                                                                onClick={setCurCampaign}
                                                            />
                                                        )}
                                                    </div>
                                                    : <></>
                                }
                            </div>

                        </div>
                    </div>

                    {/* Dropdown for smaller screens */}
                    {isSmallScreen && (
                        <div className="w-full h-full bg-white p-4">

                            <div className="flex justify-between items-center mt-8 mb-4 gap-8">
                                <div className="text-3xl font-poppins ml-4 text-gray-950 font-bold">Campaigns</div>
                                <TWDropDown
                                    xs
                                    onChange={setTypeFilter}
                                    data={[
                                        { value: "all", label: "All" },
                                        { value: "active", label: "Active" },
                                        { value: "draft", label: "Draft" },
                                        { value: "retired", label: "Retired" },
                                    ]}
                                >
                                    Filter
                                </TWDropDown>
                            </div>

                            <div className="mb-6 px-2 mt-16 w-full">
                                <TWButton full onClick={() => setCreateModal(true)}>
                                    + New Campaign
                                </TWButton>
                            </div>
                            <div className="overflow-y-auto h-[calc(100vh-250px)] overscroll-contain scrollbar">
                                <div className="w-full  scrollbar px-2 py-4">
                                    {
                                        (typeFilter === "all")
                                            ? <>
                                                {activeCampaigns.map(campaign =>
                                                    <CampaignListingMobile
                                                        key={campaign.id}
                                                        data={campaign}
                                                        selected={campaign.id === curCampaignIdRef.current}
                                                        onClick={() => handleCampaignClick(campaign)}
                                                    />
                                                )}
                                                {inactiveCampaigns.map(contract =>
                                                    <CampaignListingMobile
                                                        key={contract.id}
                                                        data={contract}
                                                        onClick={setCurCampaign}
                                                    />
                                                )}
                                                {retiredCampaigns.map(contract =>
                                                    <CampaignListingMobile
                                                        key={contract.id}
                                                        data={contract}
                                                        onClick={setCurCampaign}
                                                    />
                                                )}
                                            </>
                                            : (typeFilter === "active")
                                                ? <div className="cursor-pointer">
                                                    {activeCampaigns.map(campaign =>
                                                        <CampaignListingMobile
                                                            key={campaign.id}
                                                            data={campaign}
                                                            selected={campaign.id === curCampaignIdRef.current}
                                                            onClick={() => handleCampaignClick(campaign)}
                                                        />
                                                    )}
                                                </div>
                                                : (typeFilter === "draft")
                                                    ? <div>
                                                        {inactiveCampaigns.map(contract =>
                                                            <CampaignListingMobile
                                                                key={contract.id}
                                                                data={contract}
                                                                onClick={setCurCampaign}
                                                            />
                                                        )}
                                                    </div>
                                                    : (typeFilter === "retired")
                                                        ? <div>
                                                            {retiredCampaigns.map(contract =>
                                                                <CampaignListingMobile
                                                                    key={contract.id}
                                                                    data={contract}
                                                                    onClick={setCurCampaign}
                                                                />
                                                            )}
                                                        </div>
                                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    )}

                </CollapsibleSidebar>

                <div className="flex flex-col w-full">
                    {!isSmallScreen && <TopBar accountType={props.accountType} />}
                    {/* MAIN CONTENT */}
                    <div className="flex-auto h-full max-w-full flex flex-col pt-2 pb-2 pl-4 pr-4 lg:px-4 lg:pt-4 lg:pb-2 lg:pl-14 lg:pr-36">
                        {/* <div className="font-semibold text-lg mb-4 text-gray-700 md:hidden">{curCampaign ? curCampaign.title : null}</div> */}
                        <div className="flex flex-col md:flex-row  md:justify-between mt-8" >
                            <div className="text-black text-3xl font-extrabold font-poppins pl-12 md:pl-0 text-start mb-8">
                                {curCampaign ? curCampaign.title : null}
                            </div>
                            <div className="flex flex-row gap-2 justify-center md:justify-end font-poppins">
                                <div className="mx-1 text-sm drop-shadow-xl">
                                    <p className={`inline-block md:rounded-xl py-2 px-2 md:px-4 cursor-pointer font-semibold
                        ${tab === "tracking" ? "bg-transparent border-b-2 border-mi-blue text-mi-blue md:bg-mi-blue md:text-white md:border-none" : "text-mi-black md:bg-white hover:bg-mi-blue hover:text-white"}`}
                                        onClick={() => setTab("tracking")}>Tracking</p>
                                </div>
                                <div className="mx-1 text-sm drop-shadow-xl">
                                    <p className={`inline-block md:rounded-xl py-2 px-2 md:px-4 cursor-pointer font-semibold
                        ${tab === "overview" ? "bg-transparent border-b-2 border-mi-blue text-mi-blue md:bg-mi-blue md:text-white md:border-none" : "text-mi-black md:bg-white hover:bg-mi-blue hover:text-white"}`}
                                        onClick={() => setTab("overview")}>Analytics</p>
                                </div>
                                <div className="mx-1 text-sm drop-shadow-xl">
                                    <p className={`inline-block md:rounded-xl py-2 px-2 md:px-4 cursor-pointer font-semibold
                        ${tab === "content" ? "bg-transparent border-b-2 border-mi-blue text-mi-blue md:bg-mi-blue md:text-white md:border-none" : "text-mi-black md:bg-white hover:bg-mi-blue hover:text-white"}`}
                                        onClick={() => setTab("content")}>Content</p>
                                </div>
                                <div className="mx-1 text-sm drop-shadow-xl">
                                    <p className={`inline-block md:rounded-xl py-2 px-2 md:px-4 cursor-pointer font-semibold
                        ${tab === "info" ? "bg-transparent border-b-2 border-mi-blue text-mi-blue md:bg-mi-blue md:text-white md:border-none" : "text-mi-black md:bg-white hover:bg-mi-blue hover:text-white"}`}
                                        onClick={() => setTab("info")}>About</p>
                                </div>
                            </div>


                        </div>
                        <div className="flex-auto flex flex-col md:w-full md:h-full md:max-h-full">
                            {(!curCampaign)
                                ? <div className="flex flex-col items-center justify-center mt-24">
                                    <img src={campaignEmptyState} alt="No Campaigns right now" className="w-auto" />
                                    <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">No campaigns right now</h1>
                                    <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">Create a new campaign to start tracking</h1>
                                </div>
                                : (loading)
                                    ? <Spinner />
                                    : (tab === "overview")
                                        ? <OverviewPage data={curCampaign} key={curCampaign.id} />
                                        : (tab === "tracking")
                                            ? <TrackingPage data={curCampaign} key={curCampaign.id} />
                                            : (tab === "content")
                                                ? <ContentPage data={curCampaign} key={curCampaign.id} />
                                                : (tab === "info")
                                                    ? <AboutPage
                                                        data={curCampaign}
                                                        key={curCampaign.id}
                                                        clientName={clientName}
                                                        clientPhone={clientPhone}
                                                        onClientPhoneChange={onClientPhoneChange}
                                                    />
                                                    : <></>
                            }
                        </div>
                    </div>
                    {/* CREATE CAMPAIGN MODAL */}
                    {createModal && <CreateCampaignModal accountType={props.accountType} setShowModal={setCreateModal} />}
                </div>


            </div >
        </>
    )
}

export default Campaign;
