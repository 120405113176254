import React, { useLayoutEffect, useRef, useState } from "react";
import ProfilePic from "../ProfilePic";
import ImageCarousel from "../ImageCarousel";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import BlueChatIcon from "../../assets/images/blue_chat_icon.svg";
import RedHeart from "../../assets/images/red_heart.svg";
import CloseButton from "../../assets/images/blue_close_button.svg";
import VideoCard from '../video/VideoCard';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver'
import Tooltip from '@mui/material/Tooltip';

const ContentPagePostContainer = (props) => {
    const [captionOverflow, setCaptionOverflow] = useState(false);
    const [fullCaption, setFullCaption] = useState(false);

    const captionRef = useRef(null);

    const downloadImage = async (imageSrc, imageName) => {
        try {
            for (const slide of imageSrc) {
                const response = await fetch(slide, { mode: 'cors' });
                const imageBlob = await response.blob();
                saveAs(imageBlob, imageName || "downloaded_image.jpg");
            }
        } catch (error) {
            console.error("Error downloading the image:", error);
        }
    };

    useLayoutEffect(() => {
        // Check if the caption is overflowing only when the component mounts or the fullCaption state changes
        if (captionRef.current) {
            setCaptionOverflow(captionRef.current.scrollHeight > captionRef.current.clientHeight);
        }
    }, [fullCaption]);


    return (
        <div className="flex flex-col shadow-lg bg-white w-full h-full overflow-hidden rounded-xl shadow-lg p-1">
            <div className="relative w-full">
                <button className="absolute top-2 right-2 z-10">
                    <img src={CloseButton} alt="Close" className="w-4 h-4" />
                </button>
                <div className="p-4 flex flex-col sm:flex-row justify-between items-start">
                    <div className="flex items-center gap-2">
                        <div className="w-10 h-10">
                            <ProfilePic border={false} image={props.data.profile_pic} />
                        </div>
                        <div className="flex flex-col justify-around">
                            <div className="text-black text-sm font-bold">{props.data.name}</div>
                            <div className="text-gray-500 text-xs">@{props.data.ig_handle}</div>
                            <div className="text-gray-500 text-xs">{props.data.location}</div>
                        </div>
                    </div>
                </div>
                <div className="w-full p-3">
                    {props.data.images[0] === "Media is unavailable." ? (
                        <div className="flex items-center justify-center h-40 bg-gray-200">
                            <ImageNotSupportedIcon className="text-mi-blue" fontSize="large" />
                        </div>
                    ) : (
                        props.data.post_type[0] === 'I' ? (
                            <ImageCarousel carouselData={props.data.images} />
                        ) : (
                            props.data.post_type[0] === 'V' ? (
                                <div className="flex w-full justify-center">
                                    <VideoCard
                                        url={props.data.images[0]}
                                        likes={props.data.like_count}
                                        plays="N/A"
                                        comments={props.data.comment_count}
                                        profilePic={props.profile_pic}
                                        igHandle={props.ig_handle}
                                        caption={props.data.caption}
                                        post_type={props.data.post_type}
                                    />
                                </div>
                            ) : props.data.post_type[0] === 'R' || props.data.post_type[0] === 'S' ? (
                                <div className="flex w-full justify-center">
                                    <VideoCard
                                        url={props.data.images[0]}
                                        likes={props.data.like_count}
                                        plays="N/A"
                                        comments={props.data.comment_count}
                                        profilePic={props.profile_pic}
                                        igHandle={props.ig_handle}
                                        caption={props.data.caption}
                                        post_type={props.data.post_type}
                                    />
                                </div>
                            ) : null
                        )

                    )}
                </div>
            </div>
            <div className="flex flex-row justify-start  gap-3 mb-2 ml-4 mt-2 md:gap-17">
                <span className="md:flex-none flex flex-row justify-end items-center font-semibold text-gray-600">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 mr-2" viewBox="0 0 20 20" fill="#7ea7d7">
                        <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                    </svg>
                    {props.data.comment_count}
                </span>
                <span className="md:flex-none flex flex-row justify-end items-center font-semibold text-gray-600">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 mr-2" viewBox="0 0 20 20" fill="#dc8e7d">
                        <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                    </svg>
                    {props.data.like_count}
                </span>
                {props.data.post_type[0] === 'I' && (
                    <span className="flex justify-end w-full items-center">
                        <button className="flex items-center hover:bg-neutral-300 w-26 mr-2 rounded-full" onClick={() => downloadImage(props.data.images, `image_.jpg`)}>
                            <Tooltip title="Download All" arrow>
                                <DownloadIcon />
                            </Tooltip>

                        </button>
                    </span>
                )}
            </div>

            <div className="px-4 py-2 w-full">
                <div ref={captionRef} className={`text-gray-700 ${!fullCaption ? "line-clamp-2" : ""} break-words`}>
                    {props.data.caption}
                </div>
                {captionOverflow && (
                    <div className="text-right mt-2">
                        <button onClick={() => setFullCaption(!fullCaption)} className="text-mi-blue text-sm">
                            {fullCaption ? "Hide" : "Read more..."}
                        </button>
                    </div>
                )}


            </div>


        </div>
    );
};

export default ContentPagePostContainer;
