import data from '@emoji-mart/data';
import { Picker } from 'emoji-mart';
import React from 'react';

function EmojiPicker(props) {
  const ref = React.useRef();

  React.useEffect(() => {
    new Picker({ ...props, data, ref });
  }, []);
  return <div ref={ref} />;
}

const EmojiContainer = (props) => {
  const [theme, setTheme] = React.useState('light');
  const handleEmojiSelect = (emoji) => {
    props.onEmojiClick(emoji);
  };

  return (
    <React.Fragment>
      {/* <button
        onClick={() => {
          setTheme(theme === 'light' ? 'dark' : 'light')
        }}
      >
        Toggle
      </button> */}
      <EmojiPicker
        onEmojiSelect={handleEmojiSelect}
        theme={theme}
        native
        emojiSize={25}
        sheetSize={32}
        data={data}
      />
    </React.Fragment>
  );
};

export default EmojiContainer;