import ProfilePic from "../ProfilePic";

const BusinessProfileCard = ({ data }) => {
    return (
        <>
            <div className="flex flex-col divide-y-2 p-4 h-fit w-full bg-white rounded-xl mb-8">
                <div className="flex flex-col md:flex-row gap-4 pb-2 items-center">
                    <div className="w-1/4 md:w-[12%] max-w-[5rem]">
                        <ProfilePic border image={data.profile_picture} />
                    </div>
                    <p className="font-semibold text-lg text-gray-500 font-poppins">{data.name}</p>
                    <div className="flex-auto flex flex-row justify-end items-center flex-wrap md:flex-nowrap gap-4 max-w-full">
                        {(data.business_url)
                            ? <div className="flex flex-row gap-1 w-full justify-center md:justify-end">
                                <a href={data.business_url} className="font-semibold text-mi-green underline font-poppins">{data.company_name}</a>
                            </div>
                            : <></>
                        }
                    </div>
                </div>
                <div className="flex flex-col gap-4 pt-2">
                    <div className="font-semibold text-gray-500 font-poppins">Bio: <p className="inline font-normal text-base text-gray-600 font-poppins">{data.bio}</p></div>
                    <div className="font-semibold text-gray-500 font-poppins">Company Name: <p className="inline font-normal text-base text-gray-600 font-poppins">{data.company_name}</p></div>
                </div>

            </div>
        </>
    );
}
export default BusinessProfileCard;