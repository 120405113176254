import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { isoToMonthDate, isoToDate, isoTime } from "../dateUtils";
import { auth } from "../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie"

import { Ping } from "./Pings";

const ContractNotificationListing = ({ data, setContractNotifications }) => {
    const navigate = useNavigate();
    const csrf_token = Cookies.get("csrftoken");
    
    const deleteSelf = async (canNavigate) => {
        const url = `${process.env.REACT_APP_DJANGO_API}/delete_contract_notification`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: data.id }),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        const updatedNotifications = result.data.filter(notification => notification.id !== data.id);
                        setContractNotifications(updatedNotifications);
                        if (canNavigate) {
                            navigate(`/contract/${data.contract_id}`);
                        }
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    return (
        <>
            <div className="relative overflow-x-clip">
                <div onClick={() => {
                    deleteSelf(true);
                }} className="w-full px-4 py-4 hover:bg-gray-200">
                    {/* <h3 className="font-semibold text-gray-500">{data.type}! (Deal #{data.contract_id})</h3> */}
                    <div className="flex flex-row gap-5 justify-center items-center">
                        <img className="rounded-full object-cover h-full w-20 overflow-hidden" src={data.profile_pic} />
                        <div className="flex flex-col">
                            <h3 className="font-semibold text-black">{data.type}!</h3>
                            <p className="text-gray-700 text-sm">{data.description}</p>
                            <p className="text-gray-400 text-sm mt-3">{isoToMonthDate(data.timestamp)} {isoTime(data.timestamp)}</p>
                        </div>
                    </div>
                </div>
                <button onClick={() => { deleteSelf(false); }} className="absolute top-10 right-2 h-min w-fit hover:bg-mi-grey hover:rounded-md">
                    <Ping className="text-xs text-red-500 scale-150" >x</Ping>
                </button>
                <hr></hr>
            </div>
        </>
    )
}
export default ContractNotificationListing;