import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import ChooseType from "./ChooseType";
import InfluencerSignupFlow from "./InfluencerSignupFlow";
import BusinessSignupFlow from "./BusinessSignupFlow";
import Spinner from "../Spinner";
import { Helmet } from "react-helmet";

const Signup = () => {
    const [user, loading, error] = useAuthState(auth);
    const [pageLoading, setPageLoading] = useState(true);
    const [userType, setUserType] = useState(null);
    const [step, setStep] = useState(1);
    const [firstName, setFirstName] = useState(null);
    const navigate = useNavigate();
    const csrf_token = Cookies.get("csrftoken");

    const setUserTypeAndReset = (type) => {
        setUserType(type);
        setStep(1);
        // reset other states if needed
    };    

    // check authentication
    useEffect(() => {
        // authentication is loading
        if (loading) {
            return;
        }
        // user is signed into an account => check account status
        if (user) {
            const checkAuth = async () => {
                const url = `${process.env.REACT_APP_DJANGO_API}/check_user`;
                const token = await auth.currentUser.getIdToken();
                const requestMetaData = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf_token,
                        "Authorization": `Bearer ${token}`,
                    },
                };
                fetch(url, requestMetaData)
                    .then(res => res.json())
                    .then((result) => {
                        if (!result || !result.success) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                        } else {
                            const accountType = result.data.user_type;
                            const signupStep = result.data.step;

                            if ((signupStep === 4 && accountType === "Influencer") || (signupStep === 5 && (accountType === "Business" || accountType === "Agency"))) {
                                navigate("/home");
                            } else {
                                setStep(signupStep);
                                setFirstName(result.data.user_name);
                                setPageLoading(false);
                                setUserType(accountType);
                            }
                        }
                    });
            }

            checkAuth();
            // No user logged in => redirect to signin screen
        } else {
            navigate("/create_account");
        }
    }, [user, loading, error]);

    return (
        <>
            <Helmet>
                <title>
                    MyInfluency | Signup
                </title>
            </Helmet>
            {(pageLoading)
                ? <div className="w-full h-screen flex flex-col"><Spinner /></div>
                : (!userType)
                    ? <ChooseType setUserType={setUserTypeAndReset} />
                    : (userType === "Influencer")
                        ? <InfluencerSignupFlow step={step} firstName={firstName} setUserType={setUserTypeAndReset} />
                        : <BusinessSignupFlow step={step} firstName={firstName} setUserType={setUserTypeAndReset} />}
        </>
    );
}
export default Signup;

