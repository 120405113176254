// import ProfilePic from "../ProfilePic";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import DashboardEditProfileModal from "../influencer_dashboard/DashboardEditProfileModal";

import { useEffect, useState } from "react";
import actionIcon from "../../assets/images/action_icon.svg";
import blackChatIcon from "../../assets/images/black_chat_icon.svg";
import Dashboard from "../influencer_dashboard/Dashboard";
import HomeView from "./HomeView"


const Home = (props) => {

    const [profileInfo, setProfileInfo] = useState({ price: true });

    const csrf_token = Cookies.get("csrftoken");
    const [tab, setTab] = useState("home");
    const [contractNotif, setContractNotif] = useState("");
    const [messageNotif, setMessageNotif] = useState("");
    const [showModal, setShowModal] = useState(false);

    const getProfileInfo = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_dashboard_profile`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    setProfileInfo(result.data);
                    const tags = result.data.tags
                    if (!result.data.price || tags.length === 0 ) {
                        setShowModal(true);
                    }
                }
            });
    }

    const getNotifications = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_notifications`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // Set the Notification Counters
                    setContractNotif(result.data.contract_notifications.length);
                    setMessageNotif(result.data.chat_notifications.length);
                    //setLoading(false);
                }
            });
    }


    useEffect(() => {
        getProfileInfo();
        getNotifications();
    }, []);


    const renderView = () => {
        switch (tab) {
            case 'home':
                return <HomeView data={profileInfo} />;
            case 'dashboard':
                return <Dashboard />;
            default:
                return <HomeView />;
        }
    };


    return (
        <div className="flex flex-col w-full h-full gap-4 md:px-14 md:pt-6 mb-8">
            {showModal && <DashboardEditProfileModal setShowModal={setShowModal} preFillBio={profileInfo.bio} profileInfo={profileInfo} setProfileInfo={setProfileInfo} />}
            <div className="flex w-full justify-between items-center">
                {tab === "home"
                    ? <div className="flex justify-between items-center self-stretch px-0 py-6">
                        <div className="flex flex-grow items-center gap-6">
                            <div className="text-black font-bold text-xl text-center md:text-left font-poppins ml-5">Campaigns</div>
                            <div className="md:flex hidden items-center gap-6">
                                <div className="flex w-10 justify-between items-center">
                                    <div className="w-4 shrink-0 text-[color:var(--blue-test-1,#2C436B)] text-xl not-italic font-semibold leading-normal Dosis">{contractNotif}</div>
                                    <div className="w-6 h-6 shrink-0">
                                        <img src={actionIcon} alt="Notification" className="w-full h-full" />
                                    </div>
                                </div>
                                <div className="flex w-10 justify-between items-center gap-2">
                                    <div className="w-6 h-6 shrink-0">
                                        <img src={blackChatIcon} alt="Notification" className="w-full h-full" />
                                    </div>
                                    <div className="w-4 shrink-0 text-[color:var(--blue-test-1,#2C436B)] text-xl not-italic font-semibold leading-normal Dosis">{messageNotif}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <h1>Analytics</h1>
                }

                {/* MAIN CONTENT */}
                <div className="flex px-4 justify-end pt-4 pb-2 md:pl-16 md:pr-36">
                    <div className="flex flex-col md:flex-row md:justify-between" >
                        <div className="flex flex-row gap-2 justify-center md:justify-end font-poppins">
                            <div className="mx-1 text-sm drop-shadow-xl">
                                <p className={(tab === "home") ? "inline-block rounded-xl py-2 px-2 md:px-4 text-[white] text-center bg-mi-blue cursor-pointer" : "bg-white inline-block py-2 px-2 md:px-4 text-mi-black border rounded-xl text-center cursor-pointer font-semibold hover:bg-mi-blue hover:text-white"}
                                    onClick={() => setTab("home")}>Home</p>
                            </div>
                            <div className="mx-1 text-sm drop-shadow-xl">
                                <p className={(tab === "dashboard") ? "inline-block rounded-xl py-2 px-2 md:px-4 text-[white] text-center bg-mi-blue cursor-pointer" : "bg-white inline-block py-2 px-2 md:px-4 text-mi-black border rounded-xl text-center cursor-pointer font-semibold hover:bg-mi-blue hover:text-white"}
                                    onClick={() => setTab("dashboard")}>Analytics</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Render the view */}
            {renderView()}
        </div>
    );
}

export default Home;
