import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { SearchIcon } from "@heroicons/react/solid";
import CollapsibleSidebar from "../CollapsibleSidebar";
import TWDropDown from "../TWDropDown";
import ContractHeader from "./ContractHeader";
import ContractListing from "./ContractListing";
import SidebarFilter from "./SidebarFilter";
import Spinner from "../Spinner";
import TopBar from "../TopBar";
import { useMediaQuery } from "react-responsive";
import whiteLogoGif from "../../assets/images/whiteLogo.svg";
import TWSearch from "../TWSearch";

const Contracts = (props) => {
    const { query } = useParams();

    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

    const [tab, setTab] = useState(null);
    const [enabled, setEnabled] = useState({
        current: true,
        readyForPay: true,
        pending: true,
        declined: false,
        fulfilled: false,
    });
    const [sortBy, setSortBy] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [readyForPayData, setReadyForPayData] = useState(null);
    const [pendingData, setPendingData] = useState(null);
    const [declinedData, setDeclinedData] = useState(null);
    const [fulfilledData, setFulfilledData] = useState(null);

    // loaded automatically
    const [currentLoading, setCurrentLoading] = useState(true);
    const [rfpLoading, setRFPLoading] = useState(true);
    const [pendingLoading, setPendingLoading] = useState(true);
    // loaded on demand
    const [declinedLoading, setDeclinedLoading] = useState(false);
    const [fulfilledLoading, setFulfilledLoading] = useState(false);

    const currentRef = useRef(null);
    const readyForPayRef = useRef(null);
    const pendingRef = useRef(null);
    const declinedRef = useRef(null);
    const fulfilledRef = useRef(null);

    const csrf_token = Cookies.get("csrftoken");

    const filters = [
        { label: "Campaign", value: "campaign" },
        { label: "Offer Date", value: "offerDate" },
        { label: "Expiration Date", value: "expirationDate" },
        { label: "Visit Date", value: "visitDate" },
        { label: "Rough Draft Date", value: "pfaDate" },
        { label: "Submission Date", value: "submissionDate" },
    ];

    const [userData] = useState(props.accountType);
    const [priceColumnName, setPriceColumnName] = useState("");


    useEffect(() => {
        if (userData) {
            if (props.accountType === "Business" || props.accountType === "Agency") {
                setPriceColumnName("Price");
            } else if (userData.user_type === "Influencer") {
                setPriceColumnName("Price")
            }
        }
    }, [userData])

    // function to scroll different list sections into view
    useEffect(() => {
        if (tab === "current") {
            currentRef.current?.scrollIntoView({ behavior: "smooth" });
        } else if (tab === "ready for pay") {
            readyForPayRef.current?.scrollIntoView({ behavior: "smooth" });
        } else if (tab === "pending") {
            pendingRef.current?.scrollIntoView({ behavior: "smooth" });
        } else if (tab === "declined") {
            declinedRef.current?.scrollIntoView({ behavior: "smooth" });
        } else if (tab === "fulfilled") {
            fulfilledRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        setTab(null);
    }, [tab]);

    useEffect(() => {
        async function loadData() {
            const promises = [];

            if (enabled.current && !currentData) {
                setCurrentLoading(true);
                promises.push(getCurrentContracts());
            }

            if (enabled.readyForPay && !readyForPayData) {
                setRFPLoading(true);
                promises.push(getRFPContracts());
            }

            if (enabled.pending && !pendingData && !query) {
                setPendingLoading(true);
                promises.push(getPendingContracts());
            }

            if (enabled.declined && !declinedData) {
                setDeclinedLoading(true);
                promises.push(getDeclinedContracts());
            }

            if (enabled.fulfilled && !fulfilledData) {
                setFulfilledLoading(true);
                promises.push(getFulfilledContracts());
            }

            const results = await Promise.all(promises);

            for (let i = 0; i < promises.length; i++) {
                switch (promises[i]) {
                    case getCurrentContracts:
                        setCurrentData(results[i]);
                        setCurrentLoading(false);
                        break;
                    case getRFPContracts:
                        setReadyForPayData(results[i]);
                        setRFPLoading(false);
                        break;
                    case getPendingContracts:
                        setPendingData(results[i]);
                        setPendingLoading(false);
                        break;
                    case getDeclinedContracts:
                        setDeclinedData(results[i]);
                        setDeclinedLoading(false);
                        break;
                    case getFulfilledContracts:
                        setFulfilledData(results[i]);
                        setFulfilledLoading(false);
                        break;
                    default:
                        break;
                }
            }
        }

        loadData();
    }, [enabled, currentData, readyForPayData, pendingData, declinedData, fulfilledData]);

    // function to load active contracts or search query on page load
    useEffect(() => {
        if (query) {
            searchContracts(query);
        }
    }, [query]);  // only run when `query` changes

    // function to sort contracts based on chosen filter
    useEffect(() => {
        const compareCampaign = (a, b) => {
            const titleA = a.props.data.campaign.title.toLowerCase();
            const titleB = b.props.data.campaign.title.toLowerCase();
            return titleA.localeCompare(titleB);
        }

        const compareOfferDate = (a, b) => {
            return (a.props.data.offer_date < b.props.data.offer_date)
                ? -1
                : (a.props.data.offer_date > b.props.data.offer_date)
                    ? 1
                    : 0;
        }

        const compareExpirationDate = (a, b) => {
            return (a.props.data.expiration_date < b.props.data.expiration_date)
                ? -1
                : (a.props.data.expiration_date > b.props.data.expiration_date)
                    ? 1
                    : 0;
        }

        const compareVisitDate = (a, b) => {
            return (a.props.data.visit_date < b.props.data.visit_date)
                ? -1
                : (a.props.data.visit_date > b.props.data.visit_date)
                    ? 1
                    : 0;
        }

        const comparePfaDate = (a, b) => {
            const pfaA = new Date(a.props.data.pfa_date);
            const pfaB = new Date(b.props.data.pfa_date);
            return pfaA - pfaB;
        }

        const compareSubmissionDate = (a, b) => {
            const subA = new Date(a.props.data.submission_date);
            const subB = new Date(b.props.data.submission_date);
            return subA - subB;
        }

        switch (sortBy) {
            case null:
                break;
            case "campaign":
                setCurrentData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareCampaign);
                    return newArray;
                });
                setReadyForPayData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareCampaign);
                    return newArray;
                });
                setPendingData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareCampaign);
                    return newArray;
                });
                if (declinedData) {
                    setDeclinedData((prev) => {
                        const newArray = prev.map(a => { return { ...a } });
                        newArray.sort(compareCampaign);
                        return newArray;
                    });
                }
                if (fulfilledData) {
                    setFulfilledData((prev) => {
                        const newArray = prev.map(a => { return { ...a } });
                        newArray.sort(compareCampaign);
                        return newArray;
                    });
                }
                break;
            case "offerDate":
                setCurrentData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareOfferDate);
                    return newArray;
                });
                setReadyForPayData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareOfferDate);
                    return newArray;
                });
                setPendingData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareOfferDate);
                    return newArray;
                });
                if (declinedData) {
                    setDeclinedData((prev) => {
                        const newArray = prev.map(a => { return { ...a } });
                        newArray.sort(compareOfferDate);
                        return newArray;
                    });
                }
                if (fulfilledData) {
                    setFulfilledData((prev) => {
                        const newArray = prev.map(a => { return { ...a } });
                        newArray.sort(compareOfferDate);
                        return newArray;
                    });
                }
                break;
            case "expirationDate":
                setReadyForPayData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareExpirationDate);
                    return newArray;
                });
                setPendingData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareExpirationDate);
                    return newArray;
                });
                break;
            case "visitDate":
                setCurrentData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareVisitDate);
                    return newArray;
                });
                setReadyForPayData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareVisitDate);
                    return newArray;
                });
                if (fulfilledData) {
                    setFulfilledData((prev) => {
                        const newArray = prev.map(a => { return { ...a } });
                        newArray.sort(compareVisitDate);
                        return newArray;
                    });
                }
                break;
            case "pfaDate":
                setCurrentData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(comparePfaDate);
                    return newArray;
                });
                setReadyForPayData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(comparePfaDate);
                    return newArray;
                });
                if (fulfilledData) {
                    setFulfilledData((prev) => {
                        const newArray = prev.map(a => { return { ...a } });
                        newArray.sort(comparePfaDate);
                        return newArray;
                    });
                }
                break;
            case "submissionDate":
                setCurrentData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(compareSubmissionDate);
                    return newArray;
                });
                setReadyForPayData((prev) => {
                    const newArray = prev.map(a => { return { ...a } });
                    newArray.sort(comparePfaDate);
                    return newArray;
                });
                if (fulfilledData) {
                    setFulfilledData((prev) => {
                        const newArray = prev.map(a => { return { ...a } });
                        newArray.sort(comparePfaDate);
                        return newArray;
                    });
                }
                break;
            default:
                break;
        }

    }, [sortBy])

    // function to get contracts matching search query from backend
    const searchContracts = async (query) => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/search_contracts/${query}`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate current list
                    setCurrentData(result.data.current.map((contract, index) => {
                        return <ContractListing key={index} type="current" data={contract} />;
                    }));
                    setCurrentLoading(false);

                    // generate ready for pay list
                    setReadyForPayData(result.data.ready_for_pay.map((contract, index) => {
                        return <ContractListing key={index} type="readyForPay" data={contract} />;
                    }));
                    setRFPLoading(false);

                    // generate pending list
                    setPendingData(result.data.pending.map((contract, index) => {
                        return <ContractListing key={index} type="pending" data={contract} />;
                    }));
                    setPendingLoading(false);

                    // generate declined list
                    setDeclinedData(result.data.declined.map((contract, index) => {
                        return <ContractListing key={index} type="declined" data={contract} />;
                    }));
                    setDeclinedLoading(false);

                    // generate fulfilled list
                    setFulfilledData(result.data.fulfilled.map((contract, index) => {
                        return <ContractListing key={index} type="fulfilled" data={contract} />;
                    }));
                    setFulfilledLoading(false);
                }
            });
    }

    // function to get current contracts from backend
    const getCurrentContracts = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_current_contracts`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate current list
                    setCurrentData(result.data.map((contract, index) => {
                        return <ContractListing key={index} type="current" data={contract} />;
                    }));
                    setCurrentLoading(false);
                }
            });
    }

    // function to get ready for pay contracts from backend
    const getRFPContracts = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_ready_for_pay_contracts`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate readyForPay list
                    setReadyForPayData(result.data.map((contract, index) => {
                        return <ContractListing key={index} type="readyForPay" data={contract} />;
                    }));
                    setRFPLoading(false);
                }
            });
    }

    // function to get pending contracts from backend
    const getPendingContracts = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_pending_contracts`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate pending list
                    setPendingData(result.data.map((contract, index) => {
                        return <ContractListing key={index} type="pending" data={contract} />;
                    }));
                    setPendingLoading(false);
                }
            });

    }


    // function to get declined contracts from backend
    const getDeclinedContracts = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_declined_contracts`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate declined list
                    setDeclinedData(result.data.map((contract, index) => {
                        return <ContractListing key={index} type="declined" data={contract} />;
                    }));
                    setDeclinedLoading(false);
                }
            });
    }

    // function to get fulfilled contracts from backend
    const getFulfilledContracts = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_fulfilled_contracts`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate fulfilled list
                    setFulfilledData(result.data.map((contract, index) => {
                        return <ContractListing key={index} type="fulfilled" data={contract} />;
                    }));
                    setFulfilledLoading(false);
                }
            });
    }


    return (
        <>
            <div className="h-full max-h-full flex flex-row">
                {/* SIDEBAR */}
                <CollapsibleSidebar>
                    <div className="mx-4 w-full">
                        <TWSearch url="contracts" curr={query}>Search</TWSearch>
                    </div>
                    {/* <div className="flex items-center w-full px-2 mt-5"> */}
                    {/* Search Bar */}
                    {/* <div className="w-7">
                            <SearchIcon className="text-gray-600" />
                        </div>
                        <input type="text" placeholder="Search" className="flex-auto rounded-full p-1 mx-2 outline-none bg-gray-100 focus:text-gray-700"
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    if (e.target.value) {
                                        navigate(`/contracts/${e.target.value}`);
                                    } else {
                                        if (!query) {
                                            window.location.reload(false);
                                        } else {
                                            navigate("/contracts");
                                        }
                                    }
                                }
                            }} /> */}
                    {/* </div> */}

                    {/* Filter By Dropdown */}
                    <div className="flex items-center pb-4 pt-4 w-full">
                        <div className="flex-auto mx-4 p-1">
                            <TWDropDown data={filters} onChange={setSortBy}>Sort By</TWDropDown>
                        </div>
                    </div>

                    {/* Filter List */}
                    <div className="flex flex-col flex-auto w-full p-4">
                        <SidebarFilter setTab={setTab} setEnabled={setEnabled} checked>Current</SidebarFilter>
                        <SidebarFilter setTab={setTab} setEnabled={setEnabled} checked>Ready For Pay</SidebarFilter>
                        <SidebarFilter setTab={setTab} setEnabled={setEnabled} checked>Proposals</SidebarFilter>
                        <SidebarFilter setTab={setTab} setEnabled={setEnabled}>Declined</SidebarFilter>
                        <SidebarFilter setTab={setTab} setEnabled={setEnabled}>Fulfilled</SidebarFilter>
                    </div>
                </CollapsibleSidebar>

                <div className="flex flex-col w-full">
                    {!isSmallScreen && <TopBar accountType={props.accountType} />}


                    {/* MAIN CONTENT */}
                    <div className="flex-auto h-full flex flex-col p-4">
                        <div className="flex-auto w-full flex flex-col rounded-xl overflow-y-auto md:scrollbar">
                            {/* Current Tab */}
                            {enabled.current &&
                                <div>
                                    <div ref={currentRef} />
                                    <ContractHeader setTab={setTab} enabledTabs={enabled} col="Submission Date" priceColumnName={priceColumnName}>Current Contracts</ContractHeader>
                                    <div className="flex flex-col divide-y divide-solid divide-gray-300">
                                        {(currentLoading) && <div className="w-full flex-ro justify-center p-4"><Spinner /></div>}
                                        {currentData &&
                                            <>
                                                {(currentData.length === 0)
                                                    ? <div className="w-full text-center p-4 font-semibold text-gray-500">No Current Contracts</div>
                                                    : <>{currentData}</>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {/* Ready For Pay Tab */}
                            {enabled.readyForPay &&
                                <div>
                                    <div ref={readyForPayRef} />
                                    <ContractHeader setTab={setTab} enabledTabs={enabled} col="Expiration Date" priceColumnName={priceColumnName}>Ready For Pay</ContractHeader>
                                    <div className="flex flex-col divide-y divide-solid divide-gray-300">
                                        {(rfpLoading) && <div className="w-full flex-ro justify-center p-4"><Spinner /></div>}
                                        {readyForPayData &&
                                            <>
                                                {(readyForPayData.length === 0)
                                                    ? <div className="w-full text-center p-4 font-semibold text-gray-500">No Contracts Ready for Pay</div>
                                                    : <>{readyForPayData}</>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {/* Pending Tab */}
                            {enabled.pending &&
                                <div>
                                    <div ref={pendingRef} />
                                    <ContractHeader setTab={setTab} enabledTabs={enabled} col="Expiration Date" priceColumnName={priceColumnName}>Proposals</ContractHeader>
                                    <div className="flex flex-col divide-y divide-solid divide-gray-300">
                                        {(pendingLoading) && <div className="w-full flex-ro justify-center p-4"><Spinner /></div>}
                                        {pendingData &&
                                            <>
                                                {(pendingData.length === 0)
                                                    ? <div className="w-full text-center p-4 font-semibold text-gray-500">No Pending Contracts</div>
                                                    : <>{pendingData}</>
                                                }
                                            </>
                                        }
                                    </div>

                                </div>
                            }
                            {/* Declined Tab */}
                            {enabled.declined &&
                                <div>
                                    <div ref={declinedRef} />
                                    <ContractHeader setTab={setTab} enabledTabs={enabled} col="Decline Date" priceColumnName={priceColumnName}>Declined Proposals</ContractHeader>
                                    <div className="flex flex-col divide-y divide-solid divide-gray-300">
                                        {(declinedLoading) && <div className="w-full flex-ro justify-center p-4"><Spinner /></div>}
                                        {declinedData &&
                                            <>
                                                {(declinedData.length === 0)
                                                    ? <div className="w-full text-center p-4 font-semibold text-gray-500">No Declined Contracts :)</div>
                                                    : <>{declinedData}</>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {/* Fulfilled Tab */}
                            {enabled.fulfilled &&
                                <div>
                                    <div ref={fulfilledRef} />
                                    <ContractHeader setTab={setTab} enabledTabs={enabled} col="Submission Date" priceColumnName={priceColumnName}>Fulfilled Contracts</ContractHeader>
                                    <div className="flex flex-col divide-y divide-solid divide-gray-300">
                                        {(fulfilledLoading) && <div className="w-full flex-ro justify-center p-4"><Spinner /></div>}
                                        {fulfilledData &&
                                            <>
                                                {(fulfilledData.length === 0)
                                                    ? <div className="w-full text-center p-4 font-semibold text-gray-500">No Fulfilled Contracts</div>
                                                    : <>{fulfilledData}</>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
export default Contracts;
