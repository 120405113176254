import Modal from "../Modal";
import ClaimsCard from "./ClaimsCard";
import { useMediaQuery } from "react-responsive";

const ClaimsModal = (props) => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1050px)' });
    return (
        <>
            <Modal {...(isSmallScreen && { minimum: true })} setShowModal={props.setShowModal}>
                <ClaimsCard data={props.data} />
            </Modal>
        </>
    );
}
export default ClaimsModal;