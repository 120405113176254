import { Rating } from "@mui/material";

import ReactGA from "react-ga4";
import ProfilePic from "../ProfilePic";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import InstagramIcon from '@mui/icons-material/Instagram';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import peopleIcon from "../../assets/images/people.png";
import greenDollar from "../../assets/images/green_dollar_icon.svg";


const ProfileCard = (props) => {

    const openProfile = () => {
        const data = {
            influencerid: props.data.uid,
        }
        props.setModalUid(data);
        props.setShowModal(true);

        ReactGA.send({
            hitType: "view_item",
            currency: "USD",
            value: props.data.price,
            items: [{
                item_id: props.data.uid,
                item_name: props.data.name,
                price: props.data.price,
                item_category: props.data.category,
                item_category2: props.data.other_category,
            }]
        })
    }

    return (
        <>
            <div className="max-w-sm h-90 w-72 mx-4 my-3 rounded-md overflow-hidden rounded-lg hover:cursor-pointer hover:bg-gray-100 shadow-all-xl hover:scale-105 transition ease-out duration-200" onClick={openProfile}>
                <div className="rounded-full h-36 w-36 mx-auto mt-3">
                    <ProfilePic border={true} image={props.data.profile_picture} />

                </div>
                <div className="mb-3 mx-10 flex flex-col justify-center items-center gap-1 p-2">
                    <span className="text-neutral-950 font-bold text-lg">{props.data.name}</span>
                    <div className="flex items-center gap-1">
                        <a href={`https://instagram.com/${props.data.ig_handle}`} className="text-mi-blue text-sm font-bold hover:text-mi-green" style={{ maxWidth: 'calc(100% - 20px)' }}>
                            @{props.data.ig_handle}
                        </a>
                    </div>
                    <div className="flex flex-row gap-2 justify-center  items-center">
                        <Rating readOnly value={props.data.rating ? props.data.rating : 0} precision={0.1} />
                        <p className="text-gray-700 text-xs font-semibold">({props.data.num_ratings})</p>
                    </div>
                    <div className="rounded-2xl bg-mi-light-grey border px-14 py-1">
                        <span className="text-neutral-800 text-sm font-bold">{(props.data.category === "Other") ? props.data.other_category : props.data.category}</span>
                    </div>
                    <div className="flex justify-start items-start w-full ml-9 mt-3">
                        <div className="flex flex-row items-center gap-3 flex-wrap">
                            <img alt="Followers" src={peopleIcon} className="object-scale-down w-9" />
                            <span className="text-mi-blue font-bold text-lg">{props.data.follower_count}</span>
                        </div>
                    </div>
                    <div className="flex justify-start items-center w-full ml-9 gap-1">
                        <div className="flex flex-row items-center gap-2 flex-wrap">
                            <div className="rounded-full border bg-[#b6d1b6] flex justify-center items-center w-[2.28rem] h-[2.28rem]">
                                <img alt="Feed Post" src={greenDollar} className="fill-current text-black w-5" />
                            </div>
                            <span className="text-mi-green font-bold text-lg">${props.data.price_feed_posts ? props.data.price_feed_posts : "N/A"}

                            </span>
                            <p className="text-xs text-mi-green mt-1">feed post</p>
                        </div>


                    </div>
                    {/* <span className="text-gray-700 NotoSans"><AttachMoneyIcon className="mr-2 text-mi-blue" />{props.data.price_reels ? props.data.price_reels : "N/A"} Reels</span>
                    <span className="text-gray-700 NotoSans"><AttachMoneyIcon className="mr-2 text-mi-blue" />{props.data.price_videos ? props.data.price_videos : "N/A"} Videos</span> */}
                </div>
            </div>
        </>
    );
}
export default ProfileCard;
