import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { saveAs } from 'file-saver'
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from "react";

const ImageCarousel = (props) => {
    const [visible, setVisible] = useState(false);

    const downloadImage = async (imageSrc, imageName) => {
        try {
            const response = await fetch(imageSrc, { mode: 'cors' });
            const imageBlob = await response.blob();
            saveAs(imageBlob, imageName || "downloaded_image.jpg");
        } catch (error) {
            console.error("Error downloading the image:", error);
        }
    };

    return (
        <>
            <Carousel autoPlay={true} infiniteLoop={true}>
                {
                    props.carouselData.map((slide, index) => {
                        return (
                            <div onMouseEnter={() => setVisible(true)}
                                onMouseLeave={() => setVisible(false)} key={index}>
                                <img src={slide} alt="Carousel" />
                                {visible && (
                                    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-10 flex items-center justify-center">
                                        <button className="flex items-center bg-mi-grey hover:bg-neutral-300 w-26 p-4 rounded-full h-10 mt-4" onClick={() => downloadImage(slide, `image_${index}.jpg`)}>
                                            <DownloadIcon />
                                            <p className="text-xs">Download</p>
                                        </button>
                                    </div>
                                )}
                            </div>
                        );
                    })
                }

            </Carousel>
        </>
    )
}
export default ImageCarousel;
