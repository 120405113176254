import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const completeIcon =
    <>
        <div className="relative font-semibold text-lg text-gray-500">
            <p className="w-[30px] h-[30px] ring-1 bg-mi-blue ring-mi-blue rounded-full" />
        </div>
        <CheckRoundedIcon className="absolute text-white" />
    </>;

const failedIcon =
    <>
        <div className="relative font-semibold text-lg text-gray-500">
            <p className="w-[30px] h-[30px] ring-1 bg-[#EA877A] ring-[#EA877A] rounded-full" />
        </div>
        <CloseRoundedIcon className="absolute text-white" />
    </>;

const sentIcon =
    <>
        <div className="relative font-semibold text-lg text-gray-500">
            <p className="w-[30px] h-[30px] ring-1 bg-[#2C436B] ring-[#2C436B] rounded-full" />
        </div>
        <SendRoundedIcon className="absolute text-white" />
    </>;

const pendingIcon =
    <>
        <div className="relative font-semibold text-lg text-gray-500">
            <p className="w-[30px] h-[30px] ring-1 bg-white ring-[#2C436B] rounded-full" />
        </div>
        <AccessAlarmIcon className="absolute text-white" />
    </>;

const rescheduledIcon =
    <>
        <div className="font-semibold text-lg text-gray-500">
            <p className="w-[30px] h-[30px] ring-1 bg-[#FFC450] ring-[#FFC450] rounded-full" />
        </div>
        <EditCalendarRoundedIcon className="absolute text-white" />
    </>;

const voidIcon = <div className="font-semibold text-lg text-gray-500" />;

export {
    completeIcon,
    failedIcon,
    sentIcon,
    pendingIcon,
    rescheduledIcon,
    voidIcon,
}