const TWTextInput = ({ placeholder, value, onChange, step }) => {
    return (
        <input
            type="number"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            step={step}
            className="w-full p-2 rounded-lg border text-gray-800 bg-white"
        />
    );
}

export default TWTextInput;
