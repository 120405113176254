import React, { useState } from "react";
import FacebookLogin from "react-facebook-login";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import "../Spinner";

import { auth } from "../../firebase";
import Spinner from "../Spinner";

const LinkToFacebook = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const csrf_token = Cookies.get("csrftoken");
    const fbClientID = process.env.REACT_APP_FB_CLIENT_ID;

    const responseFacebook = async (response) => {
        if (response.status === "unknown")
            return;
        setLoading(true);
        const url = `${process.env.REACT_APP_DJANGO_API}/link_to_facebook`;
        const token = await auth.currentUser.getIdToken();
        const postBody = {
            fbToken: response.accessToken,
        };
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(postBody)
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then(result => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    if (result.data === "No pages found") {
                        Swal.fire({
                            icon: "error",
                            title: "We couldn't find your page! :(",
                            text: "Please make sure your Instagram business/creator account is linked to a Facebook page on this account.",
                            confirmButtonColor: "#7BBA83",
                        });
                    } else if (result.data === "Facebook Account already linked.") {
                        Swal.fire({
                            icon: "error",
                            title: "Facebook Account already linked!",
                            text: "Oops, looks like this Facebook account is already linked to another MyInfluency account.",
                            confirmButtonColor: "#7BBA83",
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Done!",
                            text: "Successfully linked Facebook!",
                            confirmButtonColor: "#7BBA83",
                        }).then(() => navigate("/home"));
                    }
                }
            });
    }



    return (
        <>
            {
                loading
                    ? <Spinner />
                    : <FacebookLogin
                        appId={fbClientID}
                        //redirectUri="https://app.myinfluency.com/auth" //used to use "https://localhost:8000/link"
                        disableMobileRedirect={true}
                        autoLoad={false}
                        scope="public_profile,email,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_show_list,business_management"
                        callback={responseFacebook}
                        textButton="Link to Facebook"
                    />
            }
        </>
    );
}

export default LinkToFacebook;
