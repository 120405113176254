import { useState } from "react";
import TWButton from "../TWButton";
import AmendmentCard from "./AmendmentCard";

const BusinessAmendment = ({ data }) => {
    const [showAllAmendments, setShowAllAmendments] = useState(false);

    return (
        <>
            {(data.amendment_approved && data.amendment_approved.length > 0) &&
                <>
                    <p className="w-full text-left font-bold text-mi-blue Dosis text-xl">Reschedule Log</p>
                    <div className="flex flex-col gap-4 w-full">
                        {data.amendment_approved.slice(0, 2).map((amendment, index) => <AmendmentCard key={index} amendment={amendment} />)}
                        {data.amendment_approved.slice(2).map((amendment, index) => {
                            return (
                                <div key={index} className={`${showAllAmendments ? "" : "hidden"}`}>
                                    <AmendmentCard amendment={amendment} />
                                </div>
                            );
                        })}
                        <div className="flex flex-row justify-end pr-4">
                            <TWButton secondary onClick={() => setShowAllAmendments(!showAllAmendments)}>{showAllAmendments ? "Show less" : "Show all..."}</TWButton>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default BusinessAmendment;
