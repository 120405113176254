import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import ReactDOM from 'react-dom';

const Modal = (props) => {
    const modal = useRef(null);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1050px)' });

    useEffect(() => {
        setTimeout(() => {
            modal.current.classList.remove('scale-0');
        }, 100);
    }, []);

    const modalContent = (
        <>
            <div className="opacity-25 fixed inset-0 z-30 bg-black" onClick={() => props.setShowModal(false)} />
            <div 
                ref={modal} 
                className={`fixed m-auto flex flex-col z-30 items-center ${props.noOverflow ? "" : "overflow-y-auto" } md:scrollbar rounded-xl transform  scale-0 bg-white ${isSmallScreen ? 'max-h-[95%] max-w-[95%] ' : 'max-h-[85%] max-w-[85%] md:w-max md:h-max' } ${props.minimum ? 'min-h-min -ml-1 w-full inset-4 translate-y-1/2' : 'inset-0'} transition-transform duration-300`}
                style={{ wordWrap: "break-word" }}
            >
                
                {props.children}
            </div>
        </>
    );

    return ReactDOM.createPortal(
        modalContent,
        document.getElementById('root')
    );
};

export default Modal;
