import { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import moment from 'moment-timezone';

import TWButton from "../TWButton";
import TWTextField from "../TWTextArea";
import LinkToStripe from "../link/LinkToStripe";

const AcceptContract = ({ data, visitDate, submissionDate, visitTime, tz }) => {
    const [textValue, setTextValue] = useState();
    const [operation, setOperation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tosAccept, setTosAccept] = useState(false);
    const csrf_token = Cookies.get("csrftoken");

    const acceptComplete = () => {
        return (visitDate && visitTime &&
            submissionDate
        );
    }

    const declinedComplete = () => {
        return (textValue);
    }

    const newVisitDate = () => {
        const date = moment.tz(visitDate, "ddd MMM DD YYYY HH:mm:ss GMTZZ (z)", tz);
        const newTime = moment(visitTime, "h:mm A");
        const combinedDateTime = date.clone().set({
            hour: newTime.get('hour'),
            minute: newTime.get('minute'),
            second: 0,
            millisecond: 0
        });
        return (combinedDateTime.format());
    };

    const newSubmissionDate = () => {
        // Parse the date string and set the timezone
        const date = moment.tz(submissionDate, "ddd MMM DD YYYY HH:mm:ss GMTZZ (z)", tz);

        // Set the time to the last moment of the day (23:59:59)
        const endOfDay = date.clone().endOf('day');

        return (endOfDay.format());
    };

    const acceptContract = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/accept_contract`;
        if ((newSubmissionDate() - newVisitDate()) < 3) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Visit date must be at least 3 days before submission date!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
            setLoading(false);
            return;
        }
        const requestMetaData = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                id: data.contract_id,
                visitDate: newVisitDate(),
                submissionDate: newSubmissionDate(),
                currURL: window.location.href,
            }),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    if (result.data && result.data.failure_type === 2 && result.data.signup_link) {
                        const signupLinkUrl = result.data.signup_link.url;
                        Swal.fire({
                            title: "Stripe Setup Incomplete",
                            text: "You will be redirected to complete the Stripe Signup Process. If already completed, please wait 20 minutes",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#7BBA83",
                            cancelButtonColor: "#ef4444",
                            confirmButtonText: "Yes, link Stripe!"
                        }).then((swalResult) => {
                            if (swalResult.isConfirmed) {
                                window.location.href = signupLinkUrl;
                            }
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    }
                } else {
                    setOperation(null);
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: "Successfully Accepted Contract!",
                        confirmButtonColor: "#7BBA83",
                    }).then(() => window.location.reload());
                };
            });
    }

    const declineContract = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/decline_contract`;
        const requestMetaData = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                id: data.contract_id,
                cancellationReason: textValue,
            }),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    setOperation(null);
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: "Successfully Declined Contract!",
                        confirmButtonColor: "#7BBA83",
                    }).then(() => window.location.reload());
                };
            });
    }

    useEffect(() => {
        if (loading) {
            switch (operation) {
                case "accept":
                    acceptContract();
                    break;
                case "declined":
                    declineContract();
                    break;
                default:
                    break;
            }
        }
    }, [loading]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col pb-4 pt-2 gap-2 w-full rounded-xl bg-white">
                <div>
                    <h2 className="font-semibold mt-6 mb-8 mb-1 text-mi-blue">To accept: </h2>
                    <p className="text-gray-600 text-sm">
                        {data.agreement_text}
                    </p>
                    <div className="w-full flex flex-row items-center mt-9">
                        <label className="flex items-center cursor-pointer">
                            <Checkbox
                                onChange={() => setTosAccept(!tosAccept)}
                                className="p-0 pt-0.5 text-gray-400 mr-1"
                                checked={tosAccept}
                            />
                            <p className="text-sm font-bold text-neutral-800">
                                I have read and understand the terms of this proposal
                            </p>
                        </label>
                    </div>
                </div>
                {!data.has_stripe &&
                    <p className="text-sm text-right font-semibold text-gray-500">To accept your proposal, please link your account with Stripe</p>
                }
                <div className="mt-2 flex flex-col gap-2 md:flex-row md:justify-end md:gap-4">
                    {!data.has_stripe &&
                        <div className="md:w-1/6">
                            <LinkToStripe />
                        </div>
                    }
                    <div className="md:w-1/6">
                        <TWButton
                            sm
                            disabled={(!acceptComplete() || !tosAccept) || loading || !data.has_stripe}
                            loading={loading && operation === "accept"}
                            onClick={() => {
                                setOperation("accept");
                                setLoading(true);
                            }}
                        >
                            Accept Proposal
                        </TWButton>
                    </div>
                </div>


                <h2 className="mt-4 font-semibold text-mi-blue mb-4">To decline: </h2>

                <TWTextField className="w-full" placeholder="Provide Decline Reason ..." onChange={setTextValue}>{data.cancellation_reason}</TWTextField>
                <div className="mt-2 md:flex md:flex-row md:justify-end">
                    <div className="md:w-1/6">
                        <TWButton delete disabled={!declinedComplete() || loading}
                            loading={loading && operation === "declined"}
                            onClick={() => {
                                setOperation("declined");
                                setLoading(true);
                            }} sm>Decline Proposal</TWButton>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AcceptContract;