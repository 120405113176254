import TrackingCard from "./TrackingCard"

const TimelineListing = (props) => {
    return (
        <>
            <div className="flex flex-col gap-y-5">
                {props.data.map((data, index) => {
                        return (
                            <TrackingCard key={index} data={data}></TrackingCard>
                        );
                })}
            </div>

        </>
    )
}
export default TimelineListing;