import Modal from "../Modal";
import { useState } from "react";
import TWButton from "../TWButton";
import Swal from "sweetalert2";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import TWTextField from "../TWTextField";
import TWTextArea from "../TWTextArea";
import { useMediaQuery } from "react-responsive";

const AddModal = (props) => {
    const [loading, setLoading] = useState(false)
    const [prodName, setProdName] = useState("")
    const [prodDes, setProdDes] = useState("")
    const csrf_token = Cookies.get("csrftoken");

    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

    const addProduct = async () => {
        setLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/add_product`;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                campaignID: props.campaignID,
                prodName: prodName,
                prodDes: prodDes,
            }),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert,
                        footer: "Please try again later.",
                    });
                } else {
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: result.alert,
                    }).then(() => window.location.reload());

                }
            });
    }
    return (
        <>
            <Modal className="pointer-events-none" {...(isSmallScreen && { minimum: true })}setShowModal={props.setShowModal}>
                <div className="flex flex-col w-full md:w-[50rem] h-full bg-white rounded-lg">
                    {/*header*/}
                    <div className="flex flex-row gap-4 items-center justify-between p-5 border-b border-solid border-gray-200">
                        <h3 className="text-3xl font-semibold text-gray-600">
                            Add
                        </h3>
                    </div>
                    {/*body*/}
                    <div className="flex flex-col gap-1 p-4 h-full">
                        <div className="mb-4 md:px-2">
                            <h4 className="mb-2 text-xl font-semibold text-gray-500">Product Name</h4>
                            <div className="flex">
                                <TWTextField whitebox placeholder="e.g. 5$ Footlong!" onChange={setProdName} />
                            </div>
                        </div>
                        <div className="mb-4 md:px-2 md:col-span-2">
                            <h4 className="mb-2 text-xl font-semibold text-gray-500">Product Description</h4>
                            <div className="flex">
                                <TWTextArea onChange={setProdDes} />
                            </div>
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex flex-row gap-1 items-center justify-end w-full pt-3 p-4 border-t border-solid border-gray-200">
                        <div className="flex justify-end md:grow-0">
                            <TWButton md loading={loading} disabled={loading} onClick={addProduct}>
                                <span className="text-white flex flex-row items-center text-sm gap-1 pl-1 pr-1">
                                    Add Item
                                </span>
                            </TWButton>
                        </div>
                        <div className="flex md:grow-0">
                            <TWButton delete md loading={loading} disabled={loading} onClick={() => props.setShowModal(false)}>
                                <span className="text-white flex flex-row items-center text-sm gap-1 pl-1 pr-1">
                                    Cancel
                                </span>
                            </TWButton>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
}
export default AddModal;