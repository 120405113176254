import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const RouteChangeTracker = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.set({"page": location.pathname});
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
        });
    }, [location]);

    return <div></div>;
}
export default RouteChangeTracker;