import ProfilePic from "../ProfilePic";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Spinner from "../Spinner";
import Timeline from "../timeline/Timeline";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import actionIcon from "../../assets/images/action_icon.svg";
import chatIcon from "../../assets/images/transparent_chat_icon.svg";
import messageIcon from "../../assets/images/message_icon.svg";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const TrackingCard = (props) => {
    const csrf_token = Cookies.get("csrftoken");
    const [timelineData, setTimelineData] = useState("");
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const getTimelineData = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_timeline_data/${props.data.contract_id}`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    setTimelineData(result.data);
                }
            });
        setLoading(false);
    };

    useEffect(() => {
        getTimelineData();
    }, []);

    return (
        <>
            <div className="flex flex-col md:flex-row h-50 sm:h-70 bg-white rounded-[30px] shadow-custom justify-start items-center w-full mb-3">
                {loading
                    ? <div className="w-full h-full flex flex-col"><Spinner /></div>
                    : <div className="flex w-full flex-col md:flex-grow md:order-2 py-7 ">
                        <div className="flex flex-row justify-between gap-2 ">
                            <div className="flex flex-row justify-start items-center gap-6 mx-4">
                                <div className="w-[60px] h-[60px] flex-none">
                                    <ProfilePic image={timelineData.profile_picture}/>
                                </div>
                                <div className="flex flex-col">
                                    <h3 className="text-center text-lg flex font-poppins text-slate-600 whitespace-nowrap">
                                        {timelineData.name}
                                    </h3>
                                    <p className="text-xs font-bold text-neutral-400">{props.data.category}</p>
                                </div>
                            </div>
                            <div className="flex flex-row justify-end items-center gap-3 mx-4">
                                <div className="bg-mi-green rounded-full p-2 relative" onClick={() => navigate(`/contract/${props.data.contract_id}`)}>
                                    <ReceiptLongIcon className="text-white" />
                                    {props.data.contract_notif && (
                                        <div className="w-6 h-6 absolute top-[-10px] right-[-10px]">
                                            <img src={actionIcon} alt="Notification" className="w-full h-full" />
                                        </div>
                                    )}
                                </div>
                                <div className="bg-mi-blue relative rounded-full p-2" onClick={() => navigate(`/chat`)}>
                                    <div className="w-6 h-6 shrink-0">
                                        <img src={chatIcon} alt="Chat" className="w-full h-full" />
                                    </div>
                                    {props.data.chat_notif && (
                                        <div className="w-6 h-6 absolute top-[-10px] right-[-10px]">
                                            <img src={messageIcon} alt="Notification" className="w-full h-full" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* TIMELINE - change steps here */}
                        <div className="flex flex-col mt-2 ml-10 pr-5">
                            <Timeline step={timelineData.step} data={timelineData} />
                        </div>

                    </div>
                }
            </div >

        </>

    );
};

export default TrackingCard;