import { useEffect, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const CropPicture = (props) => {
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState(null);
    const [image, setImage] = useState(null);

    // set image to start at full image by default
    useEffect(() => {
        if (!crop && image) {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            const base64Image = canvas.toDataURL("image/png");
            // send cropped image to parent
            if (props.setImage) {
                props.setImage(base64Image);
            }
        }
    }, [image]);

    const selectImage = (file) => {
        setSrc(URL.createObjectURL(file));
    };

    const cropImageNow = () => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL("image/png");

        // send cropped image to parent
        if (props.setImage) {
            props.setImage(base64Image);
        }
    };

    return (
        <div>
            <center>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                        selectImage(e.target.files[0]);
                    }}
                />

                {(src) &&
                    <>
                        <div className="font-semibold text-gray-500 text-left pt-2">Crop Image:</div>
                        <ReactCrop crop={crop} aspect={1} onChange={c => setCrop(c)} onComplete={cropImageNow} >
                            <img src={src} alt="Canvas" onLoad={e => setImage(e.currentTarget)} />
                        </ReactCrop>
                    </>
                }
                {/* <div>{(output && output !== "data:,") && <img src={output} />}</div> */}
            </center>
        </div>
    );
}
export default CropPicture;
