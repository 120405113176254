import { useState } from "react";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import TWButton from "../TWButton";
import TWDropDown from "../TWDropDown";
import TWTextArea from "../TWTextArea";

const ClaimsCard = (props) => {
    const csrf_token = Cookies.get("csrftoken");
    const [reason, setReason] = useState();
    const [otherReason, setOtherReason] = useState();

    const formComplete = () => {
        return reason;
    }

    const createClaim = async () => {
        if (reason === "Select Reason") {
            setReason(null);
            Swal.fire({
                icon: "error",
                title: "Select a reason for your claim!",
            });
        } else {
            const token = await auth.currentUser.getIdToken();
            const url = `${process.env.REACT_APP_DJANGO_API}/create_claim`;
            const requestMetaData = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrf_token,
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    reason: reason,
                    otherReason: otherReason,
                    contractID: props.data.contract_id,
                }),
            };
            try {
                await fetch(url, requestMetaData)
                    .then(res => res.json())
                    .then((result) => {
                        if (!result || !result.success) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                        } else {
                            Swal.fire({
                                icon: "success",
                                title: "Done!",
                                text: "Successfully Filed Claim!",
                                confirmButtonColor: "#7BBA83",
                            }).then(() => window.location.reload());
                        }
                    });
            } catch (error) {
                console.error(error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    confirmButtonColor: "#7BBA83",
                    footer: "Please try again later.",
                });
            }
        }
    }

    return (
        <>
            <div className="flex flex-col p-4 mb-2 h-fit w-full bg-white rounded-xl gap-4">
                <p className="w-full text-left font-bold text-mi-blue Dosis text-xl">Claims</p>
                {(props.data.partner.is_influencer === true)
                    ? <TWDropDown
                        onChange={setReason}
                        data={
                            [
                                { value: 1, label: "The Influencer did not post the ad content or removed the ad content from their page." },
                                { value: 2, label: "The Influencer posted the ad content after the last acceptable date." },
                                { value: 4, label: "Other" },
                            ]
                        }>Select Reason</TWDropDown>
                    : <TWDropDown
                        onChange={setReason}
                        data={
                            [
                                { value: 0, label: "The business refused to procure the product/service listed in the proposal." },
                                { value: 4, label: "Other" },
                            ]
                        }>Select Reason</TWDropDown>
                }
                <TWTextArea className="w-full" placeholder="Provide Additional Comments/Details ..." onChange={setOtherReason}></TWTextArea>
                <div className="md:flex md:flex-row md:items-center md:justify-end w-full">
                    <div className="md:w-1/3">
                        <TWButton sm disabled={!formComplete()} onClick={createClaim}>Submit Claim</TWButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClaimsCard;