const pathToTitle = {
    "": "MyInfluency | Login",
    "signin": "MyInfluency | Login",
    "signup": "MyInfluency | Signup",
    "thankyou": "MyInfluency | Thank you",
    "link": "MyInfluency | Link accounts",
    "home": "MyInfluency | Home",
    "chat": "MyInfluency | Chat",
    "profile": "MyInfluency | Profile settings",
    "dashboard": "MyInfluency | Dashboard",
    "explore": "MyInfluency | Explore",
    "campaigns": "MyInfluency | Campaigns",
    "contracts": "MyInfluency | Contracts"
}

const getTitleFromPath = (location) => {
    const path = location.pathname.split("/");
    if (path.length === 1) {
        return pathToTitle[""];
    } else if (path[1] === "contract") {
        const contractID = path[2];
        return `MyInfluency | Contract #${contractID}`;
    } else {
        return pathToTitle[path[1]];
    }
}

export default getTitleFromPath;