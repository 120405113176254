import { useRef, useState, useEffect } from "react";
import { auth, firestore } from "../../firebase";
import { onSnapshot, collection, query, orderBy, limit, addDoc } from "firebase/firestore";
import { useMediaQuery } from 'react-responsive';

import Cookies from "js-cookie";
import Header from "./Header";

import OtherMessage from "./OtherMessage";
import MyMessage from "./MyMessage";
import { getCurrentISO } from "../../dateUtils";
import chatEmptyState from "../../assets/images/chat_empty_state.svg";
import greenSearchIcon from "../../assets/images/green_search_icon.svg";
import chatNotepad from "../../assets/images/chat_notepad_symbol.svg";
import emojiButton from "../../assets/images/chat_smile_face.svg";
import plusButton from "../../assets/images/chat_plus.svg";
import EmojiContainer from './EmojiContainer';

import { isoToDate, isoToDayAndMonthDate, isPast } from "../../dateUtils";
import TopBar from "../TopBar";

const ChatWindow = ({ conversationData, setChatToggle, rooms, accountType, chatToggle }) => {
    const [messages, setMessages] = useState([]);
    const [formValue, setFormValue] = useState("");
    const messagesEndRef = useRef(null);
    const textareaRef = useRef(null);
    const [campaignFilter, setCampaignFilter] = useState(-1);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    // const [showUpload, setShowUpload] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState(null);

    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

    let displayPastDate = false;
    let pastDate = "";
 
    let dbPointer, qs;
    if (conversationData.roomID) {
        dbPointer = collection(firestore, "rooms", conversationData.roomID, "messages");
        qs = query(dbPointer, orderBy("timestamp", "desc"), limit(50));
    }

    useEffect(() => {
        if (qs) {
            const unsubscribe = onSnapshot(qs, (querySnapshot) => {
                // Reverse the order of messages since they are fetched in descending order
                const fetchedMessages = querySnapshot.docs.map(doc => doc.data()).reverse();
                setMessages(fetchedMessages);
            });

            return () => {
                unsubscribe();
                setMessages([]);
            };
        }
    }, [conversationData]);

    // Extrapolate displayed message timestamp from database stored timestamp
    function getNewTimestamp(timestamp){
        let new_ts = isoToDate(timestamp);

        // Extract time value to return
        let time = new_ts.match('[0-9][0-9]*:[0-9][0-9]am|[0-9][0-9]*:[0-9][0-9]pm');
        return time;
    }

    function getDayAndMonthDate(timestamp){
        let day_month_date = isoToDayAndMonthDate(timestamp);

        if (pastDate !== day_month_date){
            displayPastDate = true;
            pastDate = day_month_date;
        } else {
            displayPastDate = false;
        }
    }

    // async function to create a chat notification upon sending message
    const createChatNotification = async (roomID) => {
        const url = `${process.env.REACT_APP_DJANGO_API}/create_chat_notification`;
        const csrf_token = Cookies.get("csrftoken");
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ roomID: roomID }),
        };

        fetch(url, requestMetaData);
    }

    const onEmojiClick = (emoji) => {
        setFormValue(prevFormValue => prevFormValue + emoji.native);
    };

    //for chat textarea which resizes height if input reaches next line
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "0px";
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = `${Math.min(scrollHeight, 120)}px`; // limited height the textarea can go
        }
    }, [formValue]);

    // Function to send messages to Firestore
    const sendMessageFSDB = async (e) => {
        e.preventDefault();
        if (dbPointer && formValue !== "" && formValue !== null) {
            createChatNotification(conversationData.roomID);
            await addDoc(dbPointer, {
                sender: auth.currentUser.uid,
                timestamp: getCurrentISO(),
                content: formValue,
            });

            setFormValue("");
            setSelectedEmoji(null);
            scrollToBottom();
        }
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // function that scrolls new messages into view on state change
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <>

            <div className="flex h-screen flex-auto flex-col bg-white">
                {(!isSmallScreen) ?
                    <>
                        {<TopBar accountType={accountType} />}
                    </>

                    :
                    <>
                        {<TopBar accountType={accountType} setChatToggle={setChatToggle} chatToggle={chatToggle} />}
                    </>
                }

                {/* MESSAGES VIEWPORT */}
                <div className={`flex flex-col h-full gap-2 p-5 overflow-y-auto scrollbar ${conversationData.roomID ? 'bg-slate-50' : 'md:bg-slate-100 bg-white sm:bg-white'} sm:shadow-xl md:mt-5 md:mx-7 md:mb-7 rounded-xl`}>
                    {conversationData.roomID && (
                        <div className="flex-shrink mt-2">
                            <Header setChatToggle={setChatToggle} conversation={conversationData} />
                        </div>
                    )}
                    <div className={`${conversationData.roomID ? "flex flex-col justify-end h-full overflow-y-auto gap-y-4 scrollbar" : "m-auto"}`}>
                        <div className="overflow-y-auto px-4 scrollbar flex mt-2 flex-col gap-y-7 mt-8" >
                            {messages && conversationData.roomID ?
                                messages.map((msg, index) => {
                                    let ts = getNewTimestamp(msg.timestamp);
                                    getDayAndMonthDate(msg.timestamp);

                                    return (msg.sender === auth.currentUser.uid)
                                        ? <MyMessage timestamp={ts} displayPastDate={displayPastDate} pastDate={pastDate} key={index}>{msg.content}</MyMessage>
                                        : <OtherMessage timestamp={ts} displayPastDate={displayPastDate} pastDate={pastDate} conversationData={conversationData} key={index}>{msg.content}</OtherMessage>
                                })

                                : (
                                    <div className="flex flex-col items-center justify-center m-auto">
                                        <img src={chatEmptyState} alt="No chat messages right now" className="w-auto" />
                                        <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">No chats right now</h1>
                                        {(rooms.length === 0) ?
                                            <>
                                                <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">Send a message to start the chat</h1>
                                            </>
                                            :
                                            <>
                                                <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">Select a chat to get started</h1>
                                            </>
                                        }
                                    </div>

                                )
                            }
                            <div ref={messagesEndRef} />
                        </div>
                    </div>
                    {/* INPUT ROW */}
                    {conversationData.roomID && (
                        <div className="w-full flex items-center bg-white  bg-gray-100 rounded-full outline-gray-400 focus:text-gray-700 ">
                            <div className=" w-full p-4 pl-8 grid">
                                <textarea
                                    rows="1"
                                    type="text"
                                    ref={textareaRef}
                                    className="w-full resize-none outline-none overflow-y-auto scrollbar "
                                    placeholder="Send a Message"
                                    value={formValue}
                                    onChange={(e) => {
                                        setFormValue(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            if (e.nativeEvent.shiftKey) {
                                            } else if (e.target.value && conversationData.roomID) {
                                                sendMessageFSDB(e);
                                            }
                                        }
                                    }} />
                            </div>
                            <div className="flex items-center gap-3 pr-7">
                                <button className="hover:bg-gray-200 rounded-full" onClick={() => { setShowEmojiPicker(!showEmojiPicker); }}>
                                    <img src={emojiButton} alt="Emoji" className="w-auto scale-105" />
                                </button>
                                {/* <button onClick={() => { setShowUpload(!showUpload); setShowEmojiPicker(false); }}>
                                    <img src={plusButton} alt="Additional chat options" className="w-auto scale-105" />
                                </button> */}
                                {showEmojiPicker && (
                                    <div className="absolute bottom-[6rem] right-[3rem]">
                                        <EmojiContainer onEmojiClick={onEmojiClick} />
                                    </div>
                                )}
                                {/* {showUpload && (
                                <div className="absolute bottom-[8rem] right-[5rem] h-20 w-20 border bg-white">
                                    <button>Upload</button>
                                </div>
                            )} */}
                            </div>
                        </div>
                    )}

                </div>

            </div>
        </>
    );
}
export default ChatWindow;
