import ProfilePic from "../ProfilePic";

const OtherMessage = (props) => {
    return (
        <>

            {props.displayPastDate 
                ? <div> <p className="flex justify-center text-xs text-gray-500">{props.pastDate}</p> </div>
                : null
            }
            <div className="flex justify-start gap-x-6">
                <div className="w-12">
                    <ProfilePic border={true} image={props.conversationData.profilePicture ? props.conversationData.profilePicture : null} />
                </div>
                <div className="max-w-[80%] px-4 py-2 break-all text-gray-700 bg-white rounded-tr-xl rounded-br-xl rounded-bl-xl shadow-md">
                    <span className="block">{props.children}</span>
                </div>
            </div>
            <div className="relative flex justify-start">
                <p className="absolute bottom-0 left-20 text-xs text-gray-500">{props.timestamp}</p>
            </div>
        </>
    );
}
export default OtherMessage;