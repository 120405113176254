import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import TimelineListing from "./TimelineListing";
import { useEffect, useState } from "react";
import actionIcon from "../../assets/images/action_icon.svg";
import blackChatIcon from "../../assets/images/black_chat_icon.svg";
import EmptyStatePicture from "../../assets/images/empty_state_image.svg";
import Spinner from "../Spinner";

const TrackingPage = (props) => {
    const csrf_token = Cookies.get("csrftoken");

    const [contractNotif, setContractNotif] = useState("");
    const [messageNotif, setMessageNotif] = useState("");
    const [contractList, setContractList] = useState([]);
    const [loading, setLoading] = useState(true);

    const getTrackingPageData = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_tracking_data/${props.data.id}`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };

        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    setContractNotif(result.data.contract_notif_count);
                    setMessageNotif(result.data.chat_notif_count);
                    setContractList(result.data.contract_list);
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch tracking data.",
                });
            })
            .finally(() => {
                setLoading(false); // Set loading to false after the request is finished
            });
    };

    useEffect(() => {
        getTrackingPageData();
    }, []);

    return (
        <>
            <div className="flex-auto flex-row md:flex-col gap-2 w-full">
                <div className="flex flex-col rounded-xl gap-2">
                    <div>
                        <div className="flex justify-between items-center self-stretch px-0 py-6">
                            <div className="flex flex-grow justify-between items-center gap-6">
                                <h2 className="text-black font-bold text-xl text-left font-poppins mb-5">Influencer Tracking</h2>
                                <div className="flex items-center gap-6">
                                    <div className="flex w-10 justify-between items-center gap-2">
                                        <div className="w-6 h-6 shrink-0">
                                            <img src={actionIcon} alt="Notification" className="w-full h-full" />
                                        </div>
                                        <div className="w-4 shrink-0 text-[color:var(--blue-test-1,#2C436B)] text-xl not-italic font-semibold leading-normal Dosis">{contractNotif}</div>
                                    </div>
                                    <div className="flex w-10 justify-between items-center gap-2">
                                        <div className="w-6 h-6 shrink-0">
                                            <img src={blackChatIcon} alt="Notification" className="w-full h-full" />
                                        </div>
                                        <div className="w-4 shrink-0 text-[color:var(--blue-test-1,#2C436B)] text-xl not-italic font-semibold leading-normal Dosis">{messageNotif}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Conditionally render based on loading and contractList */}
                        {loading 
                        ? (<Spinner />) 
                        : (
                            contractList && contractList.length > 0 ? (
                                <TimelineListing data={contractList} />
                            ) : (
                                <div className="w-11/12 h-full text-center font-semibold rounded-xl flex flex-col shrink-0 justify-center items-center mx-auto">
                                    <img src={EmptyStatePicture} alt="You have no campaigns right now, you will be notified via email when you receive a proposal" className="w-auto" />
                                    <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">You have no influencers right now</h1>
                                    <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">To get started, take a look at our explore page and start hiring</h1>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrackingPage;
