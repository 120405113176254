import React, { useState } from "react";
import Modal from "../Modal";
import TWButton from "../TWButton";
import Swal from "sweetalert2";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import { useMediaQuery } from "react-responsive";
import DatePicker from "react-datepicker";
import CalendarComponentBusiness from "./CalendarBusiness";
import CloseBlackButton from "../../assets/images/black_close_button.svg";
import { Checkbox } from "@mui/material";
import { isoToDate, getCurrentISO  } from "../../dateUtils";

const ExtendModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [newEndDate, setNewEndDate] = useState(null);
    const [currContract, setCurrContract] = useState(false);
    const csrf_token = Cookies.get("csrftoken");

    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

    const handleCheckboxChange = () => {
        setCurrContract(!currContract);
    };

    const extendCampaign = async () => {
        setLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/extend_campaign`;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                campaignID: props.campaignID,
                newDate: newEndDate.toISOString(),
                currContract: currContract,
            }),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert,
                        footer: "Please try again later.",
                    });
                } else {
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: result.alert,
                    }).then(() => window.location.reload());
                }
            });
    }

    const handleDateChange = (date) => {
        setNewEndDate(date);
    };

    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }

    const isUnavailableDate = (date) =>
        props.unavailableDates.some(unavailableDate =>
            date.getFullYear() === unavailableDate.getFullYear() &&
            date.getMonth() === unavailableDate.getMonth() &&
            date.getDate() === unavailableDate.getDate()
        );

    return (
        <>
            <Modal setShowModal={props.setShowModal}>
                {/* header */}
                <div className="w-[95%] ml-4 mr-4">
                    <div className="flex flex-row mt-6 mb-4 items-center w-full border-b border-solid border-slate-200">
                        <h2 className="flex-auto mb-4">
                            Extend Campaign
                        </h2>
                        <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mx-3 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white fixed top-5 right-2">
                            <img src={CloseBlackButton} onClick={() => props.setShowModal(false)} alt="Close" className="w-6 h-6" />
                        </button>
                    </div>

                    {/*body*/}
                    <div className="flex flex-row gap-1 p-4 h-full">
                        <div className="mb-4 md:px-2">
                            <CalendarComponentBusiness
                                onDateChange={handleDateChange}
                                isSameDay={isSameDay}
                                isUnavailableDate={isUnavailableDate}
                                dateRange={[new Date(props.startDate), newEndDate || new Date(props.endDate)]}
                                selectSingleDate={false}
                                currentStep={-1} // Unique step for extend functionality
                                minEndDate={new Date(props.endDate)}
                            />
                        </div>
                        <div className="mb-4 md:px-2 md:col-span-2">
                            <div className="mb-4 md:px-2">
                                <h3 className="font-semibold text-neutral-600">New End Date: </h3>
                                <DatePicker
                                    minDate={new Date(props.endDate).setDate(new Date(props.endDate).getDate() + 1)}
                                    className="flex-auto rounded-xl p-1 shadow-lg bg-gray-200 outline-gray-400 text-gray-700"
                                    placeholderText="Select New End Date"
                                    selected={newEndDate}
                                    onChange={handleDateChange}
                                />
                            </div>
                            <div className="w-full flex flex-row items-center mt-9">
                                <Checkbox
                                    checked={currContract}
                                    onChange={handleCheckboxChange}
                                    className="p-0 pt-0.5 text-gray-400 mr-1"
                                />
                                <p className="text-sm font-bold text-neutral-800">Extend existing contracts' end date associated with this campaign</p>
                            </div>
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex flex-row gap-1 items-center justify-end w-full pt-3 p-4 border-t border-solid border-gray-200">
                        <div className="flex justify-end md:grow-0">
                            <TWButton md loading={loading} disabled={loading || !newEndDate || !currContract} onClick={extendCampaign}>
                                <span className="text-white flex flex-row items-center text-sm gap-1 pl-1 pr-1">
                                    Extend
                                </span>
                            </TWButton>
                        </div>
                        <div className="flex md:grow-0">
                            <TWButton delete md loading={loading} disabled={loading} onClick={() => props.setShowModal(false)}>
                                <span className="text-white flex flex-row items-center text-sm gap-1 pl-1 pr-1">
                                    Cancel
                                </span>
                            </TWButton>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ExtendModal;
