import { useState, useEffect } from "react";

import TWButton from "../../TWButton";
const CompensationCard = (props) => {
    const [suggestPrice, setSuggestPrice] = useState(props.price);

    const formComplete = () => {
        return suggestPrice;
    }
    const saveEdit = () => {
        props.setPrice(suggestPrice);
        props.setShowModal(false);
    }

    return (
        <>
            <div className="flex flex-col p-4 mb-2 h-fit w-full bg-white rounded-xl gap-4">
                <p className="w-full text-left font-bold text-mi-blue Dosis text-xl">Compensation</p>
                <hr className="color-[#DBDFE6] "></hr>
                <p className="w-full text-left font-bold text-mi-blue Dosis text-lg">Proposed Rate</p>
                <div className="relative">
                    <span className="absolute left-2 top-2.5">{props.currency}</span>
                    <input type="number" className="px-5 py-2.5 rounded-[5px] border-[solid] border-black" value={suggestPrice} onChange={e => { setSuggestPrice(e.target.value) }} step="0.01" />
                </div>
                <div className="md:flex md:flex-row md:items-center md:justify-end w-full">
                    <div className="md:w-1/3">
                        <TWButton sm disabled={!formComplete()} onClick={() => saveEdit()}>Save Edits</TWButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompensationCard;