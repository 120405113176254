import React, { useState, useEffect } from 'react';

const days = [
  { name: 'Sun', enabled: true },
  { name: 'Mon', enabled: true },
  { name: 'Tue', enabled: true },
  { name: 'Wed', enabled: true },
  { name: 'Thu', enabled: true },
  { name: 'Fri', enabled: true },
  { name: 'Sat', enabled: true },
];


const ScheduleForm = ({ dayAvailability, updateDayAvailability, timeAvailability, updateSchedule }) => {
  const [schedule, setSchedule] = useState(
    days.reduce((acc, day) => {
      const dayData = dayAvailability[day.name];
      const timeData = timeAvailability[day.name];
      acc[day.name] = {
        start: timeData ? timeData.start : '09:00',
        end: timeData ? timeData.end : '21:00',
        enabled: dayData !== undefined ? dayData : day.enabled
      };
      return acc;
    }, {})
  );

  useEffect(() => {
    setSchedule((currentSchedule) => {
      const updatedSchedule = { ...currentSchedule };
      days.forEach((day) => {
        const dayData = dayAvailability[day.name];
        const timeData = timeAvailability[day.name];
        if (dayData !== undefined) {
          updatedSchedule[day.name].enabled = dayData;
        }
        if (timeData) {
          updatedSchedule[day.name].start = timeData.start;
          updatedSchedule[day.name].end = timeData.end;
        }
      });
      return updatedSchedule;
    });
  }, [dayAvailability, timeAvailability]);


  const handleTimeChange = (day, type, value) => {
    const updatedSchedule = {
      ...schedule,
      [day]: { ...schedule[day], [type]: value },
    };
    setSchedule(updatedSchedule);
    if (type === 'enabled') {
      updateDayAvailability({ ...dayAvailability, [day]: value });
      updateSchedule({ ...updatedSchedule });
    } else {
      updateSchedule({ ...updatedSchedule });    }

  };

  return (
    <div className="bg-white rounded-lg p-4 space-y-4">
      {days.map((day) => (
        <div key={day.name} className="flex items-center justify-between gap-16">
          <div className="flex items-center">
            <input
              type="checkbox"
              id={day.name}
              className="w-5 h-5 text-blue-600 rounded focus:ring-blue-500"
              checked={schedule[day.name].enabled}
              onChange={(e) =>
                handleTimeChange(day.name, 'enabled', e.target.checked)
              }
            />
            <label htmlFor={day.name} className="ml-2 text-sm font-medium text-gray-900">
              {day.name}
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="time"
              className="border-gray-300 focus:border-blue-500 rounded text-sm"
              value={schedule[day.name].start}
              onChange={(e) =>
                handleTimeChange(day.name, 'start', e.target.value)
              }
              disabled={!schedule[day.name].enabled}
            />
            <span className="mx-2">-</span>
            <input
              type="time"
              className="border-gray-300 focus:border-blue-500 rounded text-sm"
              value={schedule[day.name].end}
              onChange={(e) =>
                handleTimeChange(day.name, 'end', e.target.value)
              }
              disabled={!schedule[day.name].enabled}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ScheduleForm;
