import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import LinkToFacebook from "../link/LinkToFacebook";

import whiteLogoGif from "../../assets/images/whiteLogoGif.gif";
import Spinner from "../Spinner";
import TWButton from "../TWButton";
import { Helmet } from "react-helmet";

const LinkModal = (props) => {
    const navigate = useNavigate();
    const csrf_token = Cookies.get("csrftoken");
    const [facebookStatus, setFacebookStatus] = useState(null);
    const [user, loading] = useAuthState(auth);
    const [waitForSocials, setWaitForSocials] = useState(true);

    useEffect(() => {
        // authentication loading
        if (loading) {
            return;
        }
        // user is logged into an account => set timezone & check account status
        if (user) {
            const checkAuth = async () => {
                const token = await user.getIdToken();
                const url = `${process.env.REACT_APP_DJANGO_API}/check_user`;
                const requestMetaData = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf_token,
                        "Authorization": `Bearer ${token}`,
                    },
                };
                fetch(url, requestMetaData)
                    .then(res => res.json())
                    .then((result) => {
                        if (!result || !result.success) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                        } else {
                            const signupStep = result.data.step;
                            const accountType = result.data.user_type;
                            props.setAccountType(accountType);
                            if (!accountType || (signupStep !== 4 && accountType === "Influencer") || (signupStep !== 5 && (accountType === "Business" || accountType === "Agency"))) { navigate("/signup"); }
                            else if (!result.data.access) { navigate("/thankyou"); }
                            else if (accountType === "Influencer" && !result.data.social_setup) {
                                getSocialSetupStatus();
                            }
                            else if (accountType === "Business" || accountType === "Agency") {
                                navigate("/home");
                            }
                        }
                    });
            }
            checkAuth();
            // no user logged in => redirect to signin screen
        } else {
            navigate("/signin");
        }
    });

    const getSocialSetupStatus = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_social_setup_status`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    let user = result.data;
                    if (user === "Business is not required to link any accounts.") navigate("/home");
                    else if ((user.fb)) navigate("/home");
                    else {
                        setFacebookStatus(user.fb);
                        setWaitForSocials(false);
                    }
                };
            });
    }

    const unlinkFB = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/unlink_fb`;
        const requestMetaData = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: "Please try again later.",
                    });
                } else {
                    window.location.reload();
                };
            });
    }

    return (
        <>
            <Helmet>
                <title>
                    MyInfluency | Link accounts
                </title>
            </Helmet>
            <div className="flex flex-col justify-center items-center gap-10 h-screen w-screen bg-gradient-to-br from-mi-blue to-mi-green">
                <button onClick={async () => { await auth.signOut();}} className="absolute top-2 right-2 font-semibold text-right text-gray-800 px-4 py-2 hover:text-gray-200">Logout</button>
                <div className="px-10 md:w-1/3">
                    <img src={whiteLogoGif} alt="My Influency" className="object-scale-down" />
                </div>
                <div className="flex flex-col gap-2 bg-white rounded-xl w-fit p-4">
                    <h2 className="text-center text-xl text-gray-600 font-semibold">Link Accounts</h2>
                    {(waitForSocials)
                        ? <div className="flex justify-center"><Spinner /></div>
                        : <>
                            <div className="flex justify-center">
                                {(facebookStatus)
                                    ? <div className="text-mi-green font-semibold justify-self-center">{"Facebook linked"}</div>
                                    : <LinkToFacebook />
                                }
                            </div>

                            <div className="flex justify-center">
                                {(facebookStatus) &&
                                    <TWButton onClick={unlinkFB}>Unlink Facebook</TWButton>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default LinkModal;