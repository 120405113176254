import defaultPFP from "../assets/images/placeholder.png";

const ProfilePic = (props) => {
    const execute = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <>
            <div className="aspect-ratio-box">
                <div onClick={execute} className={`h-full w-full rounded-full bg-gradient-to-br from-mi-blue to-mi-green ${props.border ? "p-[2px]" : "p-0"} ${props.clickable ? "hover:bg-gradient-to-br hover:from-mi-green hover:to-mi-blue" : ""}`}>
                        <img alt="Profile" className={`rounded-full object-cover h-full w-full overflow-hidden ${props.clickable ? "transform" : ""}`} src={props.image ? props.image : defaultPFP}></img>            
                </div>
            </div>
        </>
    );
}
export default ProfilePic;
