import "../../index.css"; // imports tailwindcss

import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { useParams } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/solid";
import { useMediaQuery } from 'react-responsive';

import Cookies from "js-cookie";
import Swal from "sweetalert2";

import TWSelect from "../TWSelect";
import CollapsibleSidebar from "../CollapsibleSidebar";
import RoomListing from "./RoomListing";
import Header from "./Header";
import ChatWindow from "./ChatWindow";
import TopBar from "../TopBar";
import whiteLogoGif from "../../assets/images/whiteLogo.svg";
import greenSearchIcon from "../../assets/images/green_search_icon.svg";
import whiteChatNotepad from "../../assets/images/white_chat_notpad.svg";
import chatBack from "../../assets/images/chat_back_icon.svg";
import chatEmptyState from "../../assets/images/chat_empty_state.svg";

const Chat = (props) => {
    const { contractID } = useParams();

    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });


    const [currConversation, setCurrConversation] = useState({
        roomID: null,
        name: null,
        profilePicture: null,
        contractStatus: null,
        campaignNames: [],
    });

    const [campaignFilter, setCampaignFilter] = useState(-1);
    const [rooms, setRooms] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [chatToggle, setChatToggle] = useState(false); // toggle to chat for mobile version
    const isBigScreen = useMediaQuery({ query: "(min-width: 641px)" });
    const csrf_token = Cookies.get("csrftoken");

    //NEW STATE VARIABLES
    const [searchBarString, setSearchBarString] = useState("");
    const [notifs, setNotifs] = useState({});
    const [roomsInfo, setRoomsInfo] = useState([]);
    const [pageLoad, setPageLoad] = useState(true)

    // get chatrooms for initial page load
    useEffect(() => {
        fetchRooms();
    }, []);

    useEffect(() => {
        if (!pageLoad) {
            getNotifications();
        }
    }, [pageLoad]);

    useEffect(() => {
        if (notifs) {
            setRooms(roomsInfo.map((room, index) => {
                return (
                    <div key={index}>
                        <RoomListing onClick={setConversation}
                            profilePicture={room.profilePicture}
                            roomID={room.roomID}
                            campaignNames={room.campaignNames}
                            memberUids={room.memberUids}
                            notifs={notifs}>
                            {room.memberNames}
                        </RoomListing>
                    </div>
                );
            }));
        }
    }, [notifs]);

    useEffect(() => {
        if (campaignFilter != -1) {
            fetchFilteredRooms();
        }
        else if (!pageLoad) {
            // generate room list
            setRooms(roomsInfo.map((room, index) => {
                return (
                    <div key={index}>
                        <RoomListing onClick={setConversation}
                            profilePicture={room.profilePicture}
                            roomID={room.roomID}
                            campaignNames={room.campaignNames}
                            memberUids={room.memberUids}
                            notifs={notifs}>
                            {room.memberNames}
                        </RoomListing>
                    </div>
                );
            }));
        }
    }, [campaignFilter]);

    // create dictionary of (first_name, notification number) key-value pairs 
    const getNotifications = async () => {
        const token = await auth.currentUser.getIdToken();
        const url =`${process.env.REACT_APP_DJANGO_API}/get_notifications`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    let tempNotifs = {};

                    result.data.chat_notifications.map((chat) => {
                        if (chat.uid in tempNotifs){
                            tempNotifs[chat.uid] += 1;
                        } else {
                            tempNotifs[chat.uid] = 1;
                        }
                    });

                    setNotifs(tempNotifs);
                }
            });
    }

    // filter sidebar chat room list based on selected campaign
    const fetchFilteredRooms = async () => {
        const token = await auth.currentUser.getIdToken();
        const url =`${process.env.REACT_APP_DJANGO_API}/get_campaign_filtered_chatrooms/${campaignFilter}`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate room list
                    setRooms(result.data.map((room, index) => {
                        return (
                            <div key={index}>
                                <RoomListing onClick={setConversation}
                                    profilePicture={room.member_profile_picture}
                                    roomID={room.room_id}
                                    campaignNames={room.campaign_name}
                                    memberUids={room.member_uids}
                                    notifs={notifs}>
                                    {room.member_names}
                                </RoomListing>
                            </div>
                        );
                    }));
                }
            });
    }

    const fetchRooms = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = contractID ? `${process.env.REACT_APP_DJANGO_API}/get_chatrooms/${contractID}` : `${process.env.REACT_APP_DJANGO_API}/get_chatrooms`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {

                    // generate room list
                    setRooms(result.data.room_membership.map((room, index) => {
                        return (
                            <div key={index}>
                                <RoomListing onClick={setConversation}
                                    profilePicture={room.member_profile_picture}
                                    roomID={room.room_id}
                                    campaignNames={room.campaign_name}
                                    memberUids={room.member_uids}
                                    notifs={notifs}>
                                    {room.member_names}
                                </RoomListing>
                            </div>
                        );
                    }));

                    // generate campaigns dropdown options
                    setCampaigns(result.data.campaigns.map((campaign, index) => {
                        return (
                            {
                                label: campaign.title,
                                value: campaign.id,
                            }
                        );
                    }));

                    // preload chat if requested
                    if (contractID && result.data.preload.room_id) {
                        setCurrConversation({
                            roomID: result.data.preload.room_id,
                            name: result.data.preload.user.name,
                            profilePicture: result.data.preload.user.profile_picture,
                            contractStatus: result.data.preload.user.contract_status,
                            campaignNames: result.data.preload.campaign_name,
                        });
                    }

                    // generate room info list
                    setRoomsInfo(result.data.room_membership.map((room, index) => {
                        return(
                            {
                                profilePicture: room.member_profile_picture,
                                roomID: room.room_id,
                                campaignNames: room.campaign_name,
                                memberNames: room.member_names,
                                memberUids: room.member_uids,
                            }
                        );
                    }));

                    if (pageLoad){
                        setPageLoad(false);
                    }
                }
            });

    }


    // helper function to set the current conversation from RoomListing child
    const setConversation = (data) => {
        setChatToggle(true);
        setCurrConversation((prev) => {
            if (prev.roomID !== data.roomID) {
                deleteChatNotification(data.roomID);
                return data;
            }
            return prev;
        });
    }

    // delete chat notifications on room enter
    const deleteChatNotification = async (roomID) => {
        const url = `${process.env.REACT_APP_DJANGO_API}/delete_room_chat_notification`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ roomID: roomID }),
        };

        await fetch(url, requestMetaData);
    }

    // function to initial search request
    const searchChats = async (query) => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/search_chatrooms/${query}`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (result) {
                    setRooms(result.map((room, index) => {
                        return (
                            <div key={index}>
                                <RoomListing onClick={setConversation}
                                    profilePicture={room.profile_picture}
                                    roomID={room.room_id}
                                    campaignNames={room.campaign_name}
                                    memberUids={[room.uid]}
                                    notifs={notifs}>
                                    {[`${room.first_name} ${room.last_name}`] /*MADE A QUICK FIX BY PUTTING NAME WITH LIST*/}
                                </RoomListing>
                            </div>
                        );
                    }));
                }
            });
    }

    return (
        <>
            {/* <ScreenSize></ScreenSize> */}
            <div className="h-full flex flex-row">

                {/* ROOMLIST SIDEBAR */}
                {!isSmallScreen && (
                    <CollapsibleSidebar open={isBigScreen}>

                        <>
                            <div className="w-full flex h-full flex-col gap-1">
                                <div className="flex flex-row justify-center gap-1">
                                    <div className="w-11/12 flex ml-2">
                                        <TWSelect shadow whitebox value={campaignFilter} onChange={e => setCampaignFilter(e.target.value)}>
                                            <option key={-1} value={-1}>Select a campaign</option>
                                            {
                                                campaigns.map((option, index) => {
                                                    return <option key={index} value={option.value}>{option.label}</option>
                                                })
                                            }
                                        </TWSelect>
                                    </div>

                                </div>
                                <div className="flex relative w-full p-2">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="rounded-full shadow-all-lg p-2 border mx-2 outline-none bg-white focus:text-gray-700 w-full pl-4 placeholder:text-black"
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                if (e.target.value.trim()) {
                                                    searchChats(e.target.value.trim());
                                                } else {
                                                    fetchRooms();
                                                }
                                            } else {
                                                setSearchBarString(e.target.value.trim());
                                            }
                                        }}
                                    />
                                    <div className="absolute right-4 flex">
                                        <button 
                                            onClick={() => {   
                                                if (searchBarString) {
                                                    searchChats(searchBarString);
                                                } else {
                                                    fetchRooms();
                                                }
                                            }}>
                                            <img src={greenSearchIcon} alt="Search" className=" scale-125 mt-1.5" />
                                        </button>
                                    </div>
                                </div>
                                <div className="flex-auto h-60 w-full sm:w-11/12 mt-3 m-auto sm:mb-7 mb-16 bg-white shadow-all-xl rounded-xl overflow-y-auto scrollbar">
                                    {rooms}
                                </div>
                            </div>
                        </>

                    </CollapsibleSidebar>
                )}

                {isSmallScreen && !chatToggle && (

                    <>
                        <div className="w-full flex h-full flex-col gap-1">
                            {<TopBar accountType={props.accountType} />}
                            <div className="flex flex-row justify-center gap-1">
                                <div className="w-11/12 flex ml-2">
                                    {/* <div className=""> */}
                                    <TWSelect shadow whitebox value={campaignFilter} onChange={e => setCampaignFilter(e.target.value)}>
                                        <option key={-1} value={-1}>Select a campaign</option>
                                        {
                                            campaigns.map((option, index) => {
                                                return <option key={index} value={option.value}>{option.label}</option>
                                            })
                                        }
                                    </TWSelect>
                                </div>
                            </div>
                            <div className="flex w-full p-2">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="rounded-full shadow-all-lg p-2 border mx-2 outline-none bg-white focus:text-gray-700 w-full pl-4 placeholder:text-black"
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            if (e.target.value.trim()) {
                                                searchChats(e.target.value.trim());
                                            } else {
                                                fetchRooms();
                                            }
                                        } else {
                                            setSearchBarString(e.target.value.trim());
                                        }
                                    }}
                                />
                                <div className="absolute right-4 flex">
                                    <button 
                                        onClick={() => {   
                                            if (searchBarString) {
                                                searchChats(searchBarString);
                                            } else {
                                                fetchRooms();
                                            }
                                        }}>
                                        <img src={greenSearchIcon} alt="Search" className=" scale-125 mt-1.5" />
                                    </button>
                                </div>
                            </div>

                            <div className={`flex flex-col ${rooms.length === 0 ? "justify-center" : ""} h-full w-full sm:w-11/12 mt-3 m-auto md:mb-7 bg-white shadow-2xl rounded-xl overflow-y-auto scrollbar`}>
                                {rooms.length === 0 && (
                                    <div className="flex flex-col items-center justify-center ">
                                        <img src={chatEmptyState} alt="No chat messages right now" className="w-auto" />
                                        <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">No chats right now</h1>
                                        <h1 className="text-gray-400 sm:text-xl text-sm font-bold font-poppins">Send a message to start the chat</h1>
                                    </div>
                                )}
                                {rooms}
                            </div>
                        </div>
                    </>

                )}


                {!isSmallScreen && (
                    <div className="flex flex-col w-full">
                        {/* MAIN CONTENT */}
                        <div className="flex-auto">
                            <div className="flex flex-col h-full gap-1 w-full">
                                <ChatWindow conversationData={currConversation} rooms={rooms} accountType={props.accountType} searchChats={searchChats} setConversation={setConversation} campaigns={campaigns} />
                            </div>
                        </div>

                    </div>
                )}

                {isSmallScreen && chatToggle && (
                    <div className="flex flex-col w-full">
                        {/* MAIN CONTENT */}
                        <div className="flex-auto">
                            <div className="flex flex-col h-full gap-1 w-full">
                                <ChatWindow conversationData={currConversation} searchChats={searchChats} setChatToggle={setChatToggle} chatToggle={chatToggle} setConversation={setConversation} campaigns={campaigns} />
                            </div>
                        </div>

                    </div>
                )}
            </div>
        </>
    );
}
export default Chat;
