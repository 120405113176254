import actionIcon from "../../assets/images/action_icon.svg";

const CampaignListing = (props) => {
    const isSelected = props.selected;
    const hasNotification = props.data.contract_notif > 0;
    // need to check for the specific chat contract || props.data.chat_notif > 0

    return (
        <div onClick={() => props.onClick(props.data)}>
            <div className={`w-[96%] h-16 relative flex justify-center items-center mb-5  ${isSelected ? "bg-white" : "hover:bg-mi-grey"} bg-white rounded-2xl ${isSelected ? "shadow-all-xl" : ""}`}>
                {hasNotification && (
                    <div className="w-6 h-6 absolute top-[-10px] right-[-10px]">
                        <img src={actionIcon} alt="Action Icon" className="w-full h-full" />
                    </div>
                )}
                {/* <div className="w-44 h-16 left-0 top-0 absolute rounded-2xl"></div> */}
                {/* <div className="left-5 top-5 absolute justify-start items-center gap-2 inline-flex"> */}
                <div className={`w-32 text-md font-poppins ${isSelected ? "font-extrabold text-black" : "font-semibold text-neutral-600 text-opacity-70"}`}>
                    {props.data.title}
                </div>
                {/* </div> */}
            </div>
        </div>
    );
}

export default CampaignListing;
