import React, { useEffect, useState } from 'react';
import { InfoPing, Ping } from '../Pings';
import Modal from '../Modal';
import ProfilePicSettings from "../settings/ProfilePicSettings";

import { auth } from "../../firebase";
import Cookies from "js-cookie"
import Swal from "sweetalert2";

import TWButton from "../TWButton";
import TWTextField from "../TWTextArea";

function DashboardEditProfileModal(props) {
    const [bio, setBio] = useState(props.preFillBio);
    const [priceReels, setPriceReels] = useState(props.profileInfo.price_reels);
    const [priceFeedPosts, setPriceFeedPosts] = useState(props.profileInfo.price_feed_posts);
    const [priceVideos, setPriceVideos] = useState(props.profileInfo.price_videos);
    const [priceStories, setPriceStories] = useState(props.profileInfo.price_stories);
    const [loading, setLoading] = useState(false);
    const [tag, setTag] = useState(props.profileInfo.tags && props.profileInfo.tags.length > 0 ? props.profileInfo.tags : [""]);
    const [profilePicture, setProfilePicture] = useState(props.profileInfo.profile_picture);

    const csrf_token = Cookies.get("csrftoken");

    useEffect(() => {
        if (loading) {
            const saveChanges = async () => {
                const formattedTags = tag.filter(t => t !== '');
                const invalidTags = formattedTags.filter(t => !t.startsWith('#'));
                if (invalidTags.length > 0) {
                    Swal.fire({
                        icon: "error",
                        title: "Invalid Tags",
                        text: "All tags must start with a #. Please correct the tag(s).",
                        confirmButtonColor: "#7BBA83",
                    });
                    setLoading(false);
                    return;
                }
                const saveData = {
                    bio: bio,
                    price_reels: priceReels,
                    price_feed_posts: priceFeedPosts,
                    price_videos: priceVideos,
                    price_stories: priceStories,
                    tags: formattedTags,
                    pfp: profilePicture || null,
                }
                const url = `${process.env.REACT_APP_DJANGO_API}/save_influencer_bio_price_info`;
                const token = await auth.currentUser.getIdToken();
                const requestMetaData = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf_token,
                        "Authorization": `Bearer ${token}`,
                    },
                    body: JSON.stringify(saveData),
                };

                try {
                    await fetch(url, requestMetaData)
                        .then(res => res.json())
                        .then((result) => {
                            if (!result || !result.success) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Something went wrong!",
                                    confirmButtonColor: "#7BBA83",
                                    footer: "Please try again later.",
                                });
                            } else {
                                Swal.fire({
                                    icon: "success",
                                    title: "Done!",
                                    text: "Successfully updated profile settings!",
                                    confirmButtonColor: "#7BBA83",
                                }).then(() => window.location.reload());

                                setBio(result.data.bio || "");
                                setPriceReels(result.data.price_reels || "0.0");
                                setPriceFeedPosts(result.data.price_feed_posts || "0.0");
                                setPriceVideos(result.data.price_videos || "0.0");
                                setPriceStories(result.data.price_stories || "0.0");
                                setTag(result.data.tags || []);
                                setLoading(false);
                                props.setShowModal(false);
                                props.setProfileInfo((prev) => {
                                    const newProfileInfo = { ...prev };
                                    newProfileInfo.price = true;
                                    return newProfileInfo;
                                });
                            }
                        });
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                }
            }

            saveChanges();
        }
    }, [loading]);

    const addTag = () => {
        setTag((prev) => [...prev, ""]);
    };

    const tagName = (index, tagName) => {
        setTag((prevTags) => {
            const newTags = [...prevTags];
            newTags[index] = tagName;
            return newTags;
        });
    };

    const deleteTag = (index) => {
        const list = [...tag];
        list.splice(index, 1);
        setTag(list);
    };

    return (
        <Modal setShowModal={props.setShowModal}>
            <div className="p-4">
                {/* Warning Section */}
                <div className="text-red-500 mb-4 flex items-center">
                    <Ping className="text-white bg-red-500 mr-2">!</Ping>
                    <span>
                        You will not appear in searches and will be ineligible to receive contracts until you set a price or add tags in your
                        <a href="/profile" className="underline ml-1">
                            profile settings
                        </a>.
                    </span>
                </div>

                <ProfilePicSettings
                    data={props.profileInfo}
                    onSaveProfilePicture={setProfilePicture}
                />

                {/* Bio Section */}
                <div className="mb-4">
                    <h4 className="font-semibold text-gray-500 mb-2 flex items-center">
                        Bio:
                        <InfoPing className="ml-2" placement="right">
                            Tell potential clients about yourself!
                            Who are you as a creator or an influencer?
                            What causes motivate you?
                            What kind of audience do you have?
                        </InfoPing>
                    </h4>
                    <TWTextField
                        value={bio}
                        placeholder="Enter a bio"
                        onChange={e => setBio(e)}
                        required
                        className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"
                    />
                </div>
                <h4 className="w-full font-semibold text-gray-500 mb-2">{"Price for Feed Posts ($):"}</h4>
                <div className="w-full flex mb-4">
                    <input type="number" value={priceFeedPosts} onChange={e => { setPriceFeedPosts(e.target.value) }} className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700" min="0.00" max="10000.00" step="0.01" />
                </div>

                <h4 className="w-full font-semibold text-gray-500 mb-2">{"Price for Reels ($):"}</h4>
                <div className="w-full flex mb-4">
                    <input type="number" value={priceReels} onChange={e => { setPriceReels(e.target.value) }} className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700" min="0.00" max="10000.00" step="0.01" />
                </div>

                <h4 className="w-full font-semibold text-gray-500 mb-2">{"Price for Videos ($):"}</h4>
                <div className="w-full flex mb-4">
                    <input type="number" value={priceVideos} onChange={e => { setPriceVideos(e.target.value) }} className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700" min="0.00" max="10000.00" step="0.01" />
                </div>

                <h4 className="w-full font-semibold text-gray-500 mb-2">{"Price for Stories ($):"}</h4>
                <div className="w-full flex mb-4">
                    <input type="number" value={priceStories} onChange={e => { setPriceStories(e.target.value) }} className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700" min="0.00" max="10000.00" step="0.01" />
                </div>
                <div className="flex flex-col md:flex-row gap-10">
                    <div className="flex-auto">
                        <h4 className="w-full font-semibold text-gray-500">Tags:</h4>
                        <div className="w-full flex flex-row gap-x-3">
                            {tag.map((t, index) => (
                                <div key={index} className="flex flex-col gap-2">
                                    <div className="relative flex w-full">
                                        <input type="text" placeholder="#MyInfluency" value={t} onChange={e => tagName(index, e.target.value)} required className="flex-auto w-full rounded-xl p-1 pr-8 bg-gray-200 outline-gray-400 focus:text-gray-700 disabled:cursor-not-allowed"></input>
                                        <button disabled={tag.length <= 1}
                                            className={`h-min w-fit scale-125 absolute right-1 top-[0.135rem] ${tag.length <= 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            onClick={() => tag.length > 1 && deleteTag(index)}>
                                            <Ping className="text-xs text-gray-500 hover:text-neutral-400" >x</Ping>
                                        </button>
                                        {/* </div> */}
                                    </div>

                                </div>
                            ))}
                            <div className="shrink-0">
                                {tag.length < 5 && (
                                    <TWButton sm type="button"
                                        onClick={addTag}
                                        className="add-btn">+ Add Tag</TWButton>
                                )}

                            </div>
                        </div>
                    </div>

                </div>
                <div className="w-full flex flex-row justify-center md:justify-end items-center mt-5 mb">
                    <TWButton
                        md
                        onClick={() => setLoading(true)}
                        disabled={loading || !priceReels || !priceFeedPosts || !priceVideos || !priceStories || !tag}
                        loading={loading}
                    >
                        Save Changes
                    </TWButton>
                </div>
            </div>
        </Modal>
    );
};

export default DashboardEditProfileModal;