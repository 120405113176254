import React from 'react';
import { Avatar } from "@material-ui/core";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';

const VideoFooter = (props) => {
    return (
        <div className='flex flex-col absolute w-full bottom-3 px-3 gap-2'>
            <div className='flex flex-row justify-between items-end w-full text-white '>
                    <div className='flex items-center'>
                        <Avatar src={props.profilePic} />
                        <h3 className='ml-1 text-md'>
                            @{props.igHandle}
                        </h3>
                    </div>
                    <div className='flex flex-col py-2'>
                        <span className='md:mx-1'><FavoriteBorderIcon />{props.likes}</span>
                        <span className='md:mx-1'><PlayArrowRoundedIcon/>{props.plays}</span>
                        <span className='md:mx-1'><NotesRoundedIcon />{props.comments}</span>
                    </div>
            </div>
        </div>
        
    )
}

export default VideoFooter;