import { useState } from "react";
import TWButton from "../TWButton";
import TWDropDown from "../TWDropDown";

const BusinessAddressSignup = (props) => {
    const [line1, setLine1] = useState(null);
    const [line2, setLine2] = useState(null);
    const [line3, setLine3] = useState(null);
    const [city, setCity] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [state, setState] = useState(null);
    const [country, setCountry] = useState(null);

    const nextStep = () => {
        const data = {
            line1: line1,
            line2: line2,
            line3: line3,
            city: city,
            zipCode: zipCode,
            state: state,
            country: country,
        };
        props.nextStep(data)
    }

    return (
        <>
            <h3 className="font-bold text-2xl text-gray-600 mb-10"> Submit your Address</h3>
            <h4 className="w-full px-10 font-semibold text-gray-500">Address Line 1</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="text" onChange={e => setLine1(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Address Line 2</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="text" onChange={e => setLine2(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Address Line 3</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="text" onChange={e => setLine3(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
            </div>

            <div className="w-full px-10 flex flex-row gap-8 justify-start" >
                <div className="flex-auto flex flex-col items-center gap-2">
                    <h4 className="w-full font-semibold text-gray-500">City</h4>
                    <div className="w-full flex mb-10">
                        <input type="text" onChange={e => setCity(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                    </div>
                </div>
                <div className="flex-auto flex flex-col items-center gap-2">
                    <h4 className="w-full font-semibold text-gray-500">Zipcode</h4>
                    <div className="w-full flex mb-10">
                        <input type="text" onChange={e => setZipCode(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                    </div>
                </div>
            </div>
            <div className="w-full px-10 flex flex-row gap-8 justify-start" >
                <div className="flex-auto flex flex-col items-center gap-2">
                    <h4 className="w-full font-semibold text-gray-500">State</h4>
                    <div className="w-full flex mb-10">
                        <TWDropDown
                            grey
                            onChange={setState}
                            data={
                                [
                                    { value: "Alaska", label: "Alaska" },
                                    { value: "Alabama", label: "Alabama" },
                                    { value: "Arkansas", label: "Arizona" },
                                    { value: "California", label: "California" },
                                    { value: "Colorado", label: "Colorado" },
                                    { value: "Connecticut", label: "Connecticut" },
                                    { value: "District of Columbia", label: "District of Columbia" },
                                    { value: "Delaware", label: "Delaware" },
                                    { value: "Florida", label: "Florida" },
                                    { value: "Georgia", label: "Georgia" },
                                    { value: "Hawaii", label: "Hawaii" },
                                    { value: "Iowa", label: "Iowa" },
                                    { value: "Idaho", label: "Idaho" },
                                    { value: "Illinois", label: "Illinois" },
                                    { value: "Indiana", label: "Indiana" },
                                    { value: "Kansas", label: "Kansas" },
                                    { value: "Kentucky", label: "Kentucky" },
                                    { value: "Louisiana", label: "Louisiana" },
                                    { value: "Massachusetts", label: "Massachusetts" },
                                    { value: "Maryland", label: "Maryland" },
                                    { value: "Maine", label: "Maine" },
                                    { value: "Michigan", label: "Michigan" },
                                    { value: "Minnesota", label: "Minnesota" },
                                    { value: "Missouri", label: "Missouri" },
                                    { value: "Mississippi", label: "Mississippi" },
                                    { value: "Montana", label: "Montana" },
                                    { value: "North Carolina", label: "North Carolina" },
                                    { value: "North Dakota", label: "North Dakota" },
                                    { value: "Nebraska", label: "Nebraska" },
                                    { value: "New Hampshire", label: "New Hampshire" },
                                    { value: "New Jersey", label: "New Jersey" },
                                    { value: "New Mexico", label: "New Mexico" },
                                    { value: "Nevada", label: "Nevada" },
                                    { value: "New York", label: "New York" },
                                    { value: "Ohio", label: "Ohio" },
                                    { value: "Oklahoma", label: "Oklahoma" },
                                    { value: "Oregon", label: "Oregon" },
                                    { value: "Pennsylvania", label: "Pennsylvania" },
                                    { value: "Rhode Island", label: "Rhode Island" },
                                    { value: "South Carolina", label: "South Carolina" },
                                    { value: "South Dakota", label: "South Dakota" },
                                    { value: "Tennessee", label: "Tennessee" },
                                    { value: "Texas", label: "Texas" },
                                    { value: "Utah", label: "Utah" },
                                    { value: "Virginia", label: "Virginia" },
                                    { value: "Vermont", label: "Vermont" },
                                    { value: "Washington", label: "Washington" },
                                    { value: "Wisconsin", label: "Wisconsin" },
                                    { value: "West Virginia", label: "West Virginia" },
                                    { value: "Wyoming", label: "Wyoming" },
                                ]   
                        } />
                    </div>    
                </div>
                <div className="flex-auto flex flex-col items-center gap-2">
                    <h4 className="w-full font-semibold text-gray-500">Country</h4>
                    <div className="w-full flex mb-10">
                        <input type="text" onChange={e => setCountry(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                    </div>
                </div>
            </div>

            <div className="flex-auto w-full px-10 flex flex-row flex-wrap-reverse justify-center md:justify-end items-center">
                <div className="flex-auto flex flex-row justify-start">
                    <div className="w-full md:w-[40%]">
                        <TWButton onClick={props.prevStep}>Back</TWButton>
                    </div>
                </div>
                <div className="w-full md:w-[30%]">
                    <TWButton
                        disabled={
                            !(line1 && city && zipCode && state && country)
                        }
                        onClick={nextStep}>
                        Continue
                    </TWButton>
                </div>
            </div>
        </>
    )
}

export default BusinessAddressSignup;