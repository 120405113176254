const TWTextField = (props) => {
    const execute = (e) => {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }
    return (
        <>
            <textarea value={props.value} placeholder={props.placeholder} onChange={e => execute(e)} required className={`${props.className} flex-auto rounded-md bg-white border-mi-grey border-2 text-sm pl-4 p-2 text-gray-700`}></textarea>
        </>
    );
}
export default TWTextField;
