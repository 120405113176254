const MyMessage = (props) => {
    return (
        <>

            {props.displayPastDate 
                ? <div> <p className="flex justify-center text-xs text-gray-500">{props.pastDate}</p> </div>
                : null
            }
            <div className="flex justify-end">
                <div className="max-w-[80%] break-all px-4 py-2 text-white bg-mi-blue rounded-tl-xl rounded-tr-xl rounded-bl-xl shadow-md" >
                    <span className="block">{props.children}</span>
                </div>
            </div>
            <div className="relative flex justify-end">
                <p className="absolute bottom-0 right-0 text-xs text-gray-500">{props.timestamp}</p>
            </div>
            
        </>);
}
export default MyMessage;