import { useEffect, useState } from "react";
import { auth } from "../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import Modal from "./Modal";
import TWButton from "./TWButton";
import TWTextArea from "./TWTextArea";

const CreateSubmissionModal = (props) => {
    const [submissionURL, setSubmissionURL] = useState(null);

    const [loading, setLoading] = useState(false);

    const csrf_token = Cookies.get("csrftoken");

    // function to create PFA on backend
    const submitContract = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/submit_contract`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                id: props.contractID,
                submissionURL: submissionURL,
            }),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: result.data,
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        setLoading(false);
                        props.setShowModal(false);
                        Swal.fire({
                            icon: "success",
                            title: "Done!",
                            text: "Successfully Submitted Post!",
                            confirmButtonColor: "#7BBA83",
                        }).then(() => window.location.reload());
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    useEffect(() => {
        if (loading) {
            submitContract();
        }
    }, [loading]);

    return (
        <>
            <Modal setShowModal={props.setShowModal}>
                <div className="flex flex-col p-4 mb-2 h-fit w-full bg-white rounded-xl gap-4">
                    <p className="w-full md:w-80 text-left font-bold text-mi-blue Dosis text-xl">Submit Instagram Post</p>
                    <h4 className="w-full font-semibold text-gray-500">Enter Post URL:</h4>
                    <TWTextArea onChange={setSubmissionURL} />
                    <div className="flex flex-row gap-2 items-center md:justify-end w-full pt-3 border-t border-solid border-slate-200">
                        <div className="flex-auto md:grow-0">
                            <TWButton md onClick={() => props.setShowModal(false)}>
                                Cancel
                            </TWButton>
                        </div>
                        <div className="flex-auto md:grow-0">
                            <TWButton md loading={loading} disabled={!submissionURL || loading} onClick={() => setLoading(true)}>
                                Submit
                            </TWButton>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default CreateSubmissionModal;