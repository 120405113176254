import { Checkbox } from "@mui/material";

const SidebarFilter = (props) => {
    // function to enable and disable tab in parent
    const setEnabled = () => {
        const key = (props.children === "Ready For Pay") ? "readyForPay" : props.children.toLowerCase();
        props.setEnabled((prev) => {
            const newEnabled = {...prev};
            newEnabled[key] = !newEnabled[key];
            return newEnabled;
        });
    }

    return (
        <>
            <div className="h-14 flex flex-row divide-x">
                <div onClick={() => props.setTab(props.children.toLowerCase())} className="w-2/3 h-full flex flex-row items-center text-lg pl-4 font-semibold text-gray-600 hover:bg-gray-200 font-poppins">
                    {props.children}
                </div>
                <div className="flex-auto text-center">
                    <Checkbox onChange={setEnabled} defaultChecked={props.checked} className="text-gray-400 text-lg" />
                </div>
            </div>
        </>
    )
}
export default SidebarFilter;