import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { auth } from "../../firebase";
import TWButton from "../TWButton";

const Unlink = () => {
    const csrf_token = Cookies.get("csrftoken");

    const deleteFB = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/unlink_fb`;
        const requestMetaData = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: "Deleted!",
                        text: "Your Facebook account was unlinked!",
                        confirmButtonColor: "#7BBA83",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // add FB.logout();
                            window.location.reload();
                        }
                    });
                };
            });
    }

    const unlinkFB = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to use MyInfluency or receive contracts without linking your accounts!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#7BBA83",
            cancelButtonColor: "#ef4444",
            confirmButtonText: "Yes, unlink Facebook!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFB();
            }
        });
    }

    return (
        <>
            <div className="flex flex-col gap-4 md:gap-8 w-full px-10 md:px-40 pt-2 pb-8">
                <h3 className="w-full font-bold text-2xl text-gray-600 my-5 md:mb-0">Unlink Accounts</h3>
                <div className="flex flex-row justify-start">
                    <div className="md:w-1/4">
                        <TWButton onClick={unlinkFB} delete>Unlink Facebook</TWButton>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Unlink;