import React from 'react';
import actionIcon from "../../assets/images/action_icon.svg";
import blackChatIcon from "../../assets/images/black_chat_icon.svg";

const CampaignListingMobile = (props) => {
    const isSelected = props.selected;
    const contractNotif = props.data.contract_notif;
    const messageNotif = props.data.chat_notif;

    return (
        <p onClick={() => props.onClick(props.data)}>
            <div className={`w-full h-24 flex items-center shadow-md mb-5 ${isSelected ? "bg-gray-100" : "bg-transparent hover:bg-white"} rounded-2xl`}>
                <div className='flex w-full flex-row p-5'>
                    <div className={`w-full text-base font-poppins ${isSelected ? "font-black text-black" : "font-semibold text-black text-opacity-70"}`}>
                        {props.data.title}
                    </div>
                    <div className='flex flex-row gap-4'>
                        <div className='flex flex-row items-center gap-3'>
                            <div className="w-5">
                                <img src={actionIcon} className='scale-150' alt="Contract Notification" />
                            </div>
                            <div className="text-gray-950 text-opacity-70 text-base font-bold font-[Roboto]">{contractNotif}</div>
                        </div>
                        <div className='flex flex-row items-center gap-3'>
                            <div className="w-5">
                                <img src={blackChatIcon} className='scale-150' alt="Chat Notification" />
                            </div>
                            <div className="text-gray-950 text-opacity-70 text-base font-bold font-[Roboto]">{messageNotif}</div>
                        </div>
                    </div>
                </div>


                {/* <div className="w-full h-24 left-0 top-0 absolute bg-white rounded-2xl shadow-xl"></div>
                <div className="w-20 h-5 left-[246px] top-[41px] absolute justify-start items-start gap-3 inline-flex">
                    <div className="h-5 justify-evenly items-center flex">
                        <div className='w-full flex flex-row'>
                            <div className="w-5 text-gray-950 text-opacity-70 text-base font-bold font-[Roboto]">{contractNotif}</div>
                            <div className="w-5 h-5 relative">
                                <img src={actionIcon} alt="Contract Notification" />
                            </div>
                        </div>
                    </div>
                    <div className="h-5 justify-between items-center flex">
                        <div className="w-5 h-5 relative">
                            <img src={blackChatIcon} alt="Chat Notification" />
                        </div>
                        <div className="w-3 text-gray-950 text-opacity-70 text-base font-bold font-[Roboto]">{messageNotif}</div>
                    </div>
                </div>
                <div className="left-[35px] top-[39px] absolute justify-start items-center gap-2 inline-flex">
                    <div className={`w-32 text-base font-['Poppins'] ${isSelected ? "font-extrabold text-mi-action-blue" : "font-bold text-slate-400 text-opacity-70"}`}>
                        {props.data.title}
                    </div>
                </div> */}
            </div>
        </p>
    );
}

export default CampaignListingMobile;
