import { useState, useEffect, useRef } from "react";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Chart from 'chart.js/auto';
import { isoToMonthDate } from "../../dateUtils";
// import TestUpdateInfluencerInsights from "../TestUpdateInfluencerInsights";
// import TestUpdateInfluencerPosts from "../TestUpdateInfluencerPosts";
// import TestUpdateFacebookTokens from "../TestUpdateFacebookTokens";

const Dashboard = () => {
    const csrf_token = Cookies.get("csrftoken");

    const [followerData, setFollowerData] = useState(null);
    const [viewData, setViewData] = useState(null);
    const [engagementData, setEngagementData] = useState(null);

    //const [impressions, setImpressions] = useState(0);
    //const [reach, setReach] = useState(0);
    //const [engagement, setEngagement] = useState(0);

    //const [upcomingDates, setUpcomingDates] = useState([]);

    const viewChart = useRef(null);
    const followerChart = useRef(null);
    const engagementChart = useRef(null);
    const follower_line = useRef(null);
    const view_bar = useRef(null);
    const engagement_line = useRef(null);

    //const [showModal, setShowModal] = useState(false);
    //const [gradientColor, setGradientColor] = useState(null);

    const createGradient = (ctx, chartArea) => {
        if (!chartArea) {
            console.warn("chartArea is not available!");
            return "rgba(114, 168, 218, 0.2)";
        }

        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, "rgba(114, 168, 218, 0.2)");
        gradient.addColorStop(0, "rgba(255, 255, 255, 1)");

        return gradient;
    };


    const followerOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
            },
            axis: {
                x: {
                    type: 'timeseries',
                    label: 'TimeStamps',

                    show: false
                },
                y: {
                    label: 'Followers',
                    display: false,
                },
            },
            beforeUpdate: chart => {
                const gradient = createGradient(chart.ctx, chart.chartArea);
                chart.data.datasets[0].backgroundColor = gradient;
            },
        },
        scales: {
            x: {
                grid: { 
                    display: false,
                    drawBorder: false,
                    drawOnChartArea: false, 
                    drawTicks: false, 
                },
                ticks: {
                    display: true,
                    padding: 20,
                }
            },
            y: {
                grid: { 
                    display: true,
                    drawBorder: false,
                    drawTicks: false,
                },
                ticks: {
                    display: true
                }
            }
        }
    };

    const viewOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Daily Views',
            },
        },
        scales: {
            x: {
                grid: { 
                    display: false,
                    drawBorder: false,
                    drawOnChartArea: false, 
                    drawTicks: false, 
                },
                ticks: {
                    display: true,
                    padding: 20,
                }
            },
            y: {
                grid: { 
                    display: true,
                    drawBorder: false,
                    drawTicks: false,
                },
                ticks: {
                    display: true
                }
            }
        }
    };

    const engagementOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
            },
            beforeUpdate: chart => {
                const gradient = createGradient(chart.ctx, chart.chartArea);
                chart.data.datasets[0].backgroundColor = gradient;
            },
        },
        scales: {
            x: {
                grid: { 
                    display: false,
                    drawBorder: false,
                    drawOnChartArea: false, 
                    drawTicks: false, 
                },
                ticks: {
                    display: true,
                    padding: 20,
                }
            },
            y: {
                grid: { 
                    display: true,
                    drawBorder: false,
                    drawTicks: false,
                },
                ticks: {
                    display: true
                }
            }

        }
    };

    const getInfluencerPostsEngagementOverTime = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_influencer_posts_engagement_over_time`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    setEngagementData({
                        labels: Object.keys(result.data.timestamps).map(key =>
                            isoToMonthDate(result.data.timestamps[key].slice(result.data.timestamps[key].indexOf('-') + 1))),
                        datasets: [
                            {
                                label: 'Engagement',
                                data: Object.keys(result.data.engagement).map(key => result.data.engagement[key]),
                                fill: true,
                                backgroundColor: (context) => createGradient(context.chart.ctx, context.chart.chartArea),
                                borderColor: '#72A8DA',
                                borderWidth: 2,
                                tension: 0.4,
                                pointBackgroundColor: 'blue',
                                pointBorderColor: 'black',
                            },
                        ],
                    });
                };
                //setEngagement(result.data.engagement.reduce((previousValue, currentValue) => previousValue + currentValue, 0));
            });
    }

    const getInstagramInsights = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_influencer_insights`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    // generate data
                    setViewData({
                        labels: Object.keys(result.data.timestamps).map(key => result.data.timestamps[key].slice(result.data.timestamps[key].indexOf('-') + 1)),
                        datasets: [
                            {
                                label: 'Impressions',
                                data: Object.keys(result.data.impressions).map(key => result.data.impressions[key]),
                                backgroundColor: '#57659E',
                            },
                            {
                                label: 'Reach',
                                data: Object.keys(result.data.reach).map(key => result.data.reach[key]),
                                backgroundColor: '#579E66',
                            },
                        ],
                    });
                    setFollowerData({
                        labels: Object.keys(result.data.timestamps).map(key => isoToMonthDate(result.data.timestamps[key].slice(result.data.timestamps[key].indexOf('-') + 1))),
                        datasets: [
                            {
                                label: 'Followers',
                                data: Object.keys(result.data.follower_count).map(key => result.data.follower_count[key]),
                                fill: true,
                                backgroundColor: (context) => createGradient(context.chart.ctx, context.chart.chartArea),
                                borderColor: '#72A8DA',
                                borderWidth: 2,
                                tension: 0.4,
                                pointBackgroundColor: 'blue',
                                pointBorderColor: 'black',
                            },
                        ],
                    });
                };
                //setImpressions(result.data.impressions.reduce((previousValue, currentValue) => previousValue + currentValue, 0));
                //setReach(result.data.reach.reduce((previousValue, currentValue) => previousValue + currentValue, 0));
            });
    }

    // const getUpcomingDates = async () => {
    //     const token = await auth.currentUser.getIdToken();
    //     const url = `${process.env.REACT_APP_DJANGO_API}/get_upcoming_dates`;
    //     const requestMetaData = {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "X-CSRFToken": csrf_token,
    //             "Authorization": `Bearer ${token}`,
    //         },
    //     };
    //     await fetch(url, requestMetaData)
    //         .then(res => res.json())
    //         .then((result) => {
    //             if (!result || !result.success) {
    //                 Swal.fire({
    //                     icon: "error",
    //                     title: "Oops...",
    //                     text: "Something went wrong!",
    //                     confirmButtonColor: "#7BBA83",
    //                     footer: "Please try again later.",
    //                 });
    //             } else {
    //                 setUpcomingDates(result.data.map((contract, index) => {
    //                     return <UpcomingDateCollapsible key={index} data={contract} />;
    //                 }));
    //             };
    //         });
    // }

    useEffect(() => {
        getInstagramInsights();
        getInfluencerPostsEngagementOverTime();
        //getUpcomingDates();
    }, []);

    useEffect(() => {
        const renderViewChart = () => {
            if (viewData) {
                if (viewChart.current) viewChart.current.destroy(); // destroy existing chart
                viewChart.current = new Chart(view_bar.current, {
                    type: "bar",
                    options: viewOptions,
                    data: viewData,
                    height: "600px",
                });
            }
        };

        const renderFollowerChart = () => {
            if (followerData) {
                if (followerChart.current) followerChart.current.destroy(); // destroy existing chart
                followerChart.current = new Chart(follower_line.current, {
                    type: "line",
                    options: followerOptions,
                    data: followerData,
                    height: "600px",
                });
                const gradient = createGradient(followerChart.current.ctx, followerChart.current.chartArea);
                followerChart.current.data.datasets[0].backgroundColor = gradient;
                followerChart.current.update();
            }
        };

        const renderEngagementChart = () => {
            if (engagementData) {
                if (engagementChart.current) engagementChart.current.destroy(); // destroy existing chart
                engagementChart.current = new Chart(engagement_line.current, {
                    type: "line",
                    options: engagementOptions,
                    data: engagementData,
                    height: "600px",
                });
                const gradient = createGradient(engagementChart.current.ctx, engagementChart.current.chartArea);
                engagementChart.current.data.datasets[0].backgroundColor = gradient;
                engagementChart.current.update();
            }
        };

        renderViewChart();
        renderFollowerChart();
        renderEngagementChart();

        return () => {
            if (viewChart.current) viewChart.current.destroy();
            if (followerChart.current) followerChart.current.destroy();
            if (engagementChart.current) engagementChart.current.destroy();
        };
    }, [viewData, followerData, engagementData]);

    return (
        <>
            <div className="flex flex-col md:flex-row justify-around gap-4 pb-1">
                <div className="w-full md:grid md:grid-cols-1 md:grow gap-4">
                    {/* <TestUpdateInfluencerPosts></TestUpdateInfluencerPosts>
                    <TestUpdateInfluencerInsights></TestUpdateInfluencerInsights>
                    <TestUpdateFacebookTokens></TestUpdateFacebookTokens> */}
                    <h2 className="mt-5 px-4 md:px-0">Followers</h2>
                    <div className="w-full  h-auto md:h-[450px] flex flex-col items-center mx-auto p-8 mb-4 rounded-3xl gap-2 shadow-lg bg-white border drop-shadow-lg">
                        {/* Follower Count Graph */}
                        <canvas ref={follower_line}></canvas>
                    </div>

                    <div className="flex flex-col md:flex-row justify-between px-4 md:px-0 mt-5">
                        <div className="flex flex-col w-full md:w-1/2 md:pr-4 gap-4">
                            <h2>Daily Views</h2>
                            <div className="w-full  h-auto md:h-[450px] flex flex-col items-center mx-auto p-8 mb-4 rounded-3xl gap-2 shadow-lg bg-white border drop-shadow-lg">
                                {/* Impressions Vs Reach Graph */}
                                <canvas ref={view_bar}></canvas>
                            </div>
                        </div>
                        <div className="flex flex-col w-full md:w-1/2 md:pl-4 gap-4">
                            <h2>Engagement</h2>
                            <div className="w-full  h-auto md:h-[450px] flex flex-col items-center mx-auto p-8 mb-4 rounded-3xl gap-2 shadow-lg bg-white border drop-shadow-lg">
                                {/* Price Range and Count Graph */}
                                <canvas ref={engagement_line}></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default Dashboard;