import { useLayoutEffect, useRef, useState } from "react";
import { isoToDate, formatDate } from "../../dateUtils";

import TWButton from "../TWButton";

const AmendmentCard = ({ amendment }) => {
    const [commentsOverflow, setCommentsOverflow] = useState(false);
    const [fullComments, setFullComments] = useState(false);
    const commentsRef = useRef(null);

    useLayoutEffect(() => {
        if (commentsRef.current && !fullComments) {
            setCommentsOverflow(commentsRef.current.offsetHeight < commentsRef.current.scrollHeight);
        }
    });

    return (
        <div className="px-4 py-2 rounded-xl bg-white">
            <div className="grid grid-cols-2 gap-4 divide-x-2">
                <div>
                    <p className="font-semibold text-gray-500">{isoToDate(amendment.proposal_date)}</p>
                    <p className="text-gray-600">Visit Date: {isoToDate(amendment.new_visit_date)}</p>
                    <p className="text-gray-600">Final Post Date: {formatDate(amendment.new_submission_date)}</p>
                </div>
                <div>
                    <p className="w-full text-center font-semibold text-gray-500">Provided Comments:</p>
                    <p ref={commentsRef} className={`w-11/12 mx-auto text-center text-gray-600 ${!fullComments ? "line-clamp-4" : ""}`}>{amendment.comments}</p>
                    {commentsOverflow &&
                        <div className="flex flex-row justify-end">
                            <TWButton md secondary onClick={() => setFullComments(!fullComments)}>{fullComments ? "Hide" : "Read more..."}</TWButton>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default AmendmentCard;