import ProfilePic from "../ProfilePic";
import { useState } from "react";

import { Ping } from "../Pings";

const RoomListing = (props) => {

    const [isSelected, setIsSelected] = useState(false);

    const setConversation = () => {
        setIsSelected(true);
        const data = {
            roomID: props.roomID,
            name: props.children,
            profilePicture: props.profilePicture,
            contractStatus: props.contractStatus,
            campaignNames: props.campaignNames,
        }
        props.onClick(data);
    }

    let notif_num = 0;
    for (let i = 0; i < props.memberUids.length; i++) {
        if (props.notifs[props.memberUids[i]]) {
            notif_num = props.notifs[props.memberUids[i]];
            break;
        }
    }
   
    return (
        <>
            <div onClick={setConversation}>
                <div className={`w-full group`} role="group">
                    <button className={`flex items-center gap-4 w-full p-3 border-b bg-white focus:bg-slate-100 
                        group-hover:bg-slate-100`}>
                        <div className="w-12">
                            <ProfilePic border={true} image={props.profilePicture ? props.profilePicture : null} />
                        </div>
                        <div className="flex-auto mb-1 text-left">
                            <h3 className="font-bold text-md text-gray-700 ">{props.children}</h3>
                            <p className="text-xs text-gray-400">sent a message</p>
                        </div>
                        <div className="flex-auto justify-end items-center pr-4">
                            { notif_num ?
                               <Ping className="bg-red-500 text-white">{notif_num}</Ping> : null
                            }
                            
                        </div>
                    </button>
                </div>
            </div>
        </>
    );
}
export default RoomListing;