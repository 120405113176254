import { useNavigate } from "react-router-dom";
import { isoToDate, formatDate } from "../../dateUtils";

import ProfilePic from "../ProfilePic";

const ContractListing = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <div onClick={() => navigate(`/contract/${props.data.contract_id}`)} className="flex-auto md:hidden px-4 py-5 flex flex-row gap-4 hover:bg-gray-200">
                <div className="w-14">
                    <ProfilePic border={true} image={props.data.partner_profile_picture} />
                </div>
                <div className="flex-auto flex flex-col">
                    <p className="text-gray-600 font-semibold text-lg">{props.data.campaign.title}</p>
                    <p className="text-gray-600">
                        {
                            (props.type === "current")
                                ? `Submission Date: ${props.data.submission_date}`
                                : (props.type === "readyForPay")
                                    ? `Expires: ${isoToDate(props.data.expiration_date)}`
                                    : (props.type === "pending")
                                        ? `Expires: ${isoToDate(props.data.expiration_date)}`
                                        : (props.type === "declined")
                                            ? `Declined: ${isoToDate(props.data.decline_date)}`
                                            : (props.type === "fulfilled")
                                                ? `Submitted: ${isoToDate(props.data.submission_date)}`
                                                : "" // should never reach here
                        }
                    </p>
                </div>
            </div>

            <div onClick={() => navigate(`/contract/${props.data.contract_id}`)} className="hidden md:grid py-5 grid-cols-5 items-center hover:bg-gray-200">
                <div className="font-semibold pl-8 text-gray-600 font-poppins">
                    {props.data.campaign.title}
                </div>

                <div className="w-full flex flex-row items-center">
                    <div className="w-14">
                        <ProfilePic border={true} image={props.data.partner_profile_picture} />
                    </div>
                    <p className="pl-4 font-semibold text-gray-600 font-poppins">{props.data.partner_name}</p>
                </div>

                <div className="pl-8 font-semibold text-gray-600 font-poppins">
                    Deal #{props.data.contract_id}
                </div>

                <div className="pl-8 font-semibold text-gray-600 font-poppins">
                    {
                        (props.type === "current")
                            ? formatDate(props.data.submission_date)
                            : (props.type === "readyForPay")
                                ? isoToDate(props.data.expiration_date)
                                : (props.type === "pending")
                                    ? isoToDate(props.data.expiration_date)
                                    : (props.type === "declined")
                                        ? isoToDate(props.data.decline_date)
                                        : (props.type === "fulfilled")
                                            ? isoToDate(props.data.submission_date)
                                            : "" // should never reach here
                    }
                </div>

                <div className="pl-8 font-semibold text-gray-600 font-poppins">
                    {props.data.price}
                </div>

            </div>
        </>
    )
}
export default ContractListing;
