// Function to convert UTC to client timezone
function convertTZ(date) {
    const options = { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
    return new Date(date.toLocaleString("en-US", options));
}

// Function to convert ISO format dates to date strings in local time
const isoToDate = (datestring, type) => {
    if (!datestring) return null;

    const date = convertTZ(new Date(datestring));
    if (type === "date") {
        // Return only the date part mm/dd/yyyy
        // return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        return date.toLocaleDateString();
    } else {
        // Return the full date-time string in local time mm/dd/yyyy + 00:00 am/pm
        // return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${(date.getHours() > 12 || date.getHours() === 0) ? Math.abs(date.getHours() - 12) : date.getHours()}:${(date.getMinutes() < 10) ? "0" : ""}${date.getMinutes()}${(date.getHours() >= 12) ? "pm" : "am"}`;
        return date.toLocaleString("en-US", { hour12: true });
    }
}

// Function to convert to time 00:00 am/pm
const isoTime = (datestring) => {
    if (datestring) {
        const date = convertTZ(new Date(datestring));
        // return `${(date.getHours() > 12 || date.getHours() === 0) ? Math.abs(date.getHours() - 12) : date.getHours()}:${(date.getMinutes() < 10) ? "0" : ""}${date.getMinutes()}${(date.getHours() >= 12) ? "pm" : "am"}`;
        return date.toLocaleTimeString("en-US", { hour: 'numeric', minute: 'numeric', hour12: true });
    }
}

// Function to convert yyyy-mm-dd string to mm/dd/yyyy string
const formatDate = (datestring) => {
    if (datestring) {
        const date = convertTZ(new Date(datestring));
        return date.toLocaleDateString();
    }
}

// Function to convert ISO string to "Month Day" format
const isoToMonthDate = (value) => {
    if (value) {
        const date = convertTZ(new Date(value));
        // const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // const day = date.getDate();
        return date.toLocaleString("en-US", { month: 'long', day: 'numeric' });
    }
}

// Function to get the current date in ISO format
const getCurrentISO = () => {
    return new Date().toISOString();
}

// check if value is before check_date
const isPast = (check_date, value) => {
    const date = convertTZ(value);

    return (check_date.getFullYear() > date.getFullYear() || 
            (check_date.getMonth()+1) > (date.getMonth()+1) || 
            check_date.getDate() > date.getDate());
}

// used for overarching timestamp
const isoToDayAndMonthDate = (value) => {
    if (value) {
        const date = convertTZ(value);
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const day = date.getDate();
        let day_name = date.toString().match("Sun|Mon|Tue|Wed|Thu|Fri|Sat")[0];
        switch (day_name) {
            case 'Tue':
                day_name = day_name + "sday";
                break;
            case 'Wed':
                day_name = day_name = day_name + "nesday";
                break;
            case 'Thu':
                day_name = day_name + "rsday";
                break;
            case 'Sat':
                day_name = day_name + "urday";
                break;
            default:
                day_name = day_name + "day";
                break;
        }

        var cur_date = new Date();
        if (cur_date.getFullYear() == date.getFullYear()){
            return `${day_name}, ${monthNames[date.getMonth()]} ${day}`;
        } else {
            return `${day_name}, ${monthNames[date.getMonth()]} ${day}, ${date.getFullYear()}`;   
        }
    }

}

export {
    isoToDate,
    isoTime,
    formatDate,
    isoToMonthDate,
    getCurrentISO,
    isPast,
    isoToDayAndMonthDate,
};