import React, { useState } from 'react';
import Calendar from 'react-calendar';
import './custom-calendar.css';

const CalendarComponentBusiness = ({ dateRange, onDateChange, isSameDay, isUnavailableDate, setUnavailableDates, currentStep, minEndDate }) => {

    const onClickDay = (day) => {
        if (currentStep > 2) {
            setUnavailableDates(currentUnavailableDates => {
                // Create a new date object for comparison to avoid issues with different date objects
                const clickedDate = new Date(day.getFullYear(), day.getMonth(), day.getDate());

                // Find if the date is already marked as unavailable
                const dateIndex = currentUnavailableDates.findIndex(unavailableDate =>
                    unavailableDate.getFullYear() === clickedDate.getFullYear() &&
                    unavailableDate.getMonth() === clickedDate.getMonth() &&
                    unavailableDate.getDate() === clickedDate.getDate()
                );

                if (dateIndex === -1) {
                    // If the date is not in the array, add it
                    return [...currentUnavailableDates, clickedDate];
                } else {
                    // If the date is in the array, remove it
                    const newUnavailableDates = [...currentUnavailableDates];
                    newUnavailableDates.splice(dateIndex, 1);
                    return newUnavailableDates;
                }
            }); 
        }
    };

    const isDateInRange = (date, range) => {
        const start = range[0] ? new Date(range[0].getFullYear(), range[0].getMonth(), range[0].getDate()) : null;
        const end = range[1] ? new Date(range[1].getFullYear(), range[1].getMonth(), range[1].getDate()) : null;
    
        const checkDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    
        const isInRange = (start && end) ? (checkDate >= start && checkDate <= end) : false;
        
        return isInRange;
    };
    

    return (
        <div className="bg-white rounded-xl shadow-custom">
            <Calendar
                onChange={onDateChange}
                minDate={currentStep === 2 ? new Date() : (dateRange[0] ? new Date(dateRange[0]) : new Date())}
                maxDate={currentStep === -1 ? undefined : (currentStep === 2 ? undefined : (dateRange[1] ? new Date(dateRange[1]) : undefined))}
                value={dateRange && dateRange.length ? dateRange : null}
                selectRange={currentStep === 2}
                allowPartialRange={true}
                returnValue={currentStep > 2 ? "range" : "start"}
                onClickDay={onClickDay}
                formatShortWeekday={(locale, date) => date.toLocaleString(locale, { weekday: 'narrow' }).toUpperCase()}
                tileContent={({ date, view }) =>
                    view === 'month' && isSameDay(date, new Date()) ? (
                        <div style={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <div style={{
                                width: '5px',
                                height: '5px',
                                background: '#7096D1',
                                borderRadius: '9999px',
                                position: 'absolute',
                                left: '-9px',
                                bottom: '-15px'
                            }}></div>
                        </div>
                    ) : null
                }
                tileClassName={({ date, view }) => {
                    if (view !== 'month') {
                        return null;
                    }
                    const isSpecial = isUnavailableDate(date) && isDateInRange(date, dateRange);
                    return isSpecial ? 'special-date' : null;
                }}
                tileDisabled={({ date, view }) => {
                    if (currentStep === -1 && minEndDate && date <= minEndDate && view === 'month') {
                        return true;
                    }
                    return false;
                }}
            />

            <div className="m-8 flex justify-center">
                <div className="flex items-center mr-4">
                    <div className="w-6 h-6 bg-mi-blue rounded-full border mr-2"></div>
                    <div className="text-sm font-semibold text-gray-600">Start/End Date</div>
                </div>
                {(currentStep > 2 || currentStep === -1) && (
                    <div className="flex items-center mr-4">
                        <div className="w-6 h-6 bg-mi-red rounded-full border mr-2"></div>
                        <div className="text-sm font-semibold text-gray-600">Unavailable Dates</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CalendarComponentBusiness;
