import { useState } from "react";

const ContractHeader = (props) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className="sticky top-0 z-10">
                <p onClick={() => setOpen(!open)}>
                    <div className="px-4 py-2 font-semibold text-white bg-mi-blue text-lg Dosis">{props.children}</div>
                </p>

                <div className={`${open ? "h-auto" : "h-0"} duration-300 bg-white rounded-xl shadow-xl divide-y divide-solid`}>
                    {open &&
                        <>
                            {props.enabledTabs.current && <p onClick={() => { setOpen(false); props.setTab("current"); }} className="block font-semibold text-gray-600 px-4 py-2 hover:bg-gray-200">Current</p>}
                            {props.enabledTabs.readyForPay && <p onClick={() => { setOpen(false); props.setTab("ready for pay"); }} className="block font-semibold text-gray-600 px-4 py-2 hover:bg-gray-200">Ready For Pay</p>}
                            {props.enabledTabs.pending && <p onClick={() => { setOpen(false); props.setTab("pending"); }} className="block font-semibold text-gray-600 px-4 py-2 hover:bg-gray-200">Pending</p>}
                            {props.enabledTabs.declined && <p onClick={() => { setOpen(false); props.setTab("declined"); }} className="block font-semibold text-gray-600 px-4 py-2 hover:bg-gray-200">Declined</p>}
                            {props.enabledTabs.fulfilled && <p onClick={() => { setOpen(false); props.setTab("fulfilled"); }} className="block font-semibold text-gray-600 px-4 py-2 hover:bg-gray-200">Fulfilled</p>}
                            {props.enabledTabs.amendments && <p onClick={() => { setOpen(false); props.setTab("amendments"); }} className="block font-semibold text-gray-600 px-4 py-2 hover:bg-gray-200">Amendments</p>}
                        </>
                    }
                </div>

                <div className="hidden md:grid h-13 grid-cols-5 items-center place-content-start bg-gray-200">
                    <div className="font-semibold pl-8 text-gray-600 font-poppins">
                        Title
                    </div>
                    <div className="font-semibold text-gray-600 font-poppins">
                        Partner
                    </div>
                    <div className="font-semibold pl-4 text-gray-600 font-poppins">
                        Contract ID
                    </div>
                    <div className="font-semibold pl-8 text-gray-600 font-poppins">
                        {props.col}
                    </div>
                    <div className="font-semibold pl-8 text-gray-600 font-poppins">
                        {props.priceColumnName}
                    </div>

                </div>

            </div>
        </>
    )
}
export default ContractHeader;